import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ITMWorkspace from "@cw/cherrywork-workspace";
import { sidebarWidth } from "../../Data/cssConstant";

function Workspace() {
  const userReducerState = useSelector((state) => state.userReducer);
  const [load, setLoad] = useState(false);
  const [itmAccessData, setItmAccessData] = useState({});

  //   useEffect(() => {
  //     setLoad(true);
  //     fetch(
  //       `${process.env.REACT_APP_IWAServices}/api/v1/applications/entitiesAndActivities?applicationId=${process.env.REACT_APP_ITMId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${userReducerState?.token}`,
  //           "Content-Type": "applicatin/json",
  //           applicaionId: process.env.REACT_APP_IWAId,
  //         },
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setItmAccessData(data?.data || {});
  //       })
  //       .catch((err) => {
  //         setLoad(false);
  //       });
  //   }, []);

  return (
    <div style={{ height: "100%", width: `calc(100vw - ${sidebarWidth})` }}>
      <ITMWorkspace
        selectedActivity={"ASSIGNED_TO_ME_TASKS"}
        bPerformAction={true}
        userToken={{ token: userReducerState?.token }}
        userPermissions={{
          APPLICATION_PERMISSIONS: [
            "INBOX",
            "HOME",
            "SYSTEM_CONFIG",
            "DASHBOARD",
          ],
          PERMISSIONS: {
            MY_TASKS: ["ASSIGNED_TO_ME_TASKS", "SUBSTITUTED_FOR_ME_TASKS"],
            SYSTEM_CONFIG: [
              "CONNECTOR_CONFIG",
              "BUILD_BY_CONFIGURATION",
              "MANAGE_WORKFLOW",
              "SAP_BTP_WORKFLOW_TRIGGER",
            ],
            OTHER_SERVICES: ["CREATE_TASK", "SUBSTITUTION"],
            INBOX: [
              "MY_TASKS",
              "SUBSTITUTION_TASKS",
              "MY_FILTERS",
              "CREATED_TASKS",
            ],
          },
        }}
        userData={{
          user_id: userReducerState?.usr?.pid,
          firstName: userReducerState?.user?.given_name,
          lastName: userReducerState?.user?.family_name,
          emailId: userReducerState?.user?.email,
          displayName: userReducerState?.user?.name,
        }}
      />
    </div>
  );
}

export default Workspace;
