import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  IconButton,
  Paper,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Add,
  FilterList,
  CheckBoxOutlineBlank,
  CheckBox,
  Refresh,
} from "@material-ui/icons";
import { ImUserPlus } from "react-icons/im";
import { BsPersonLinesFill } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { authVerify } from "../../Utility/auth";
import { findEntityById, showFeatureNumber } from "../../Utility/basic";
import { getAllLicenses } from "../../Action/action";
import {
  setLienses,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import NotFound from "../NotFound";
import { HtmlTooltip } from "./Applications";
import moment from "moment";
import { features } from "../../Data/data";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  licensePageHeaderHeight,
  sidebarWidth,
} from "../../Data/cssConstant";

const useStyle = makeStyles((theme) => ({
  newLicenseDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newLicenseDialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  newLicenseDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  licenseContainer: {
    position: "relative",
    border: "1px solid rgba(48, 38, 185, 0.31)",
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: 10,
    margin: 4,
    paddingLeft: 20,
  },
  licenseBand: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: 12,
    borderRadius: "10px 0px 0px 10px",
    margin: "-1px",
  },
  licenseExpiryBand: {
    backgroundColor: "rgba(192, 67, 67, 1)",
  },
  licenseCompletionBand: {
    backgroundColor: "rgba(191, 116, 0, 1)",
  },
  licenseSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  licenseNameValue: {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: 5,
    fontWeight: "bold",
  },
  licenseLabel: {
    fontSize: 12,
  },
  licenseAssignedUsersContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  licensesHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
    marginTop: 8,
    padding: "0px 10px",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
    height: licensePageHeaderHeight,
  },
  licenseHeaderTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
    marginLeft: 6,
  },
  licensesHeaderDropdown: {
    margin: "2px 0px",
    width: 150,
    marginRight: 8,
  },
  licensesHeaderFilterButton: {
    marginRight: 8,
    borderRadius: 50,
  },
  licensesHeaderAddButton: {
    marginLeft: 10,
    textTransform: "capitalize",
  },
  licensesCardContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${licensePageHeaderHeight})`,
    // overflow: "overlay",
    padding: 10,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: 10,
    height: 14,
    width: 14,
    minWidth: 6,
  },
}))(Badge);

const NewLicense = ({ open, onClose }) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();

  const initialNewLicense = {
    name: "",
    applicationId: "",
    quantity: 0,
    licenseType: "",
    duration: "",
    expiryDate: moment(new Date()).format("yyyy-MM-DD"),
    activities: [],
    maxSession: 1,
    maxUserAssign: 1,
  };
  const [newLicense, setNewLicense] = useState(initialNewLicense);
  const [load, setLoad] = useState(false);

  const handleCreateLicense = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const createLicensesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/license`;
      const payload = {
        licenseName: newLicense?.name,
        applicationId: newLicense?.applicationId,
        licenseType: newLicense?.licenseType,
        quantity: Number(newLicense?.quantity),
        duration: newLicense?.duration,
        expiryDate: newLicense?.expiryDate,
        featuresId:
          newLicense?.licenseType === "Limited Feature"
            ? newLicense?.activities?.map((activity) => activity?.id).join(",")
            : "",
        maxSession: newLicense?.maxSession,
        activeSession: 0,
        maxUsers: newLicense?.maxUserAssign,
      };
      const createLicensesRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
        },
        body: JSON.stringify(payload),
      };
      fetch(createLicensesUrl, createLicensesRequestParam)
        .then((res) => res.json())
        .then((newLicenseData) => {
          setLoad(false);
          onClose();
          setNewLicense(initialNewLicense);
          // resetFilter();
          getAllLicenses(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setLienses(data?.data || []));
              setLoad(false);
              dispatch(
                setResponseMessage({
                  open: true,
                  status: newLicenseData?.status ? "success" : "error",
                  message: newLicenseData?.status
                    ? "License created successfully"
                    : "Something went wrong",
                })
              );
            },
            (err) => {
              setLoad(false);
              dispatch(
                setResponseMessage({
                  open: true,
                  status: "error",
                  msg: "Something went wrong",
                })
              );
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const isApplicationActivity = (activity, appName) => {
    const application = basicReducerState?.applications?.find(
      (application) => application?.name === appName
    );
    const entitiesId = basicReducerState?.entities
      ?.filter(
        (entity) => Number(entity?.applicationId) === Number(application?.id)
      )
      ?.map((entity) => Number(entity?.id));
    return entitiesId?.includes(Number(activity?.entityId));
  };
  const getEntityNameById = (entityId) => {
    const entity = findEntityById(
      Number(entityId),
      basicReducerState?.entities
    );
    return entity?.name || "";
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newLicenseDialogTitle}>
        New License
      </DialogTitle>

      <DialogContent className={classes.newLicenseDialogContent}>
        <TextField
          fullWidth
          required
          variant="standard"
          label="Name"
          value={newLicense?.name}
          onChange={(e) =>
            setNewLicense({
              ...newLicense,
              name: e.target.value,
            })
          }
        />

        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel id="applicationName">Application Name</InputLabel>
          <Select
            labelId="applicationName"
            size="small"
            label="Application Name"
            style={{ fontSize: 12 }}
            value={newLicense?.applicationId}
            onChange={(e) =>
              setNewLicense({ ...newLicense, applicationId: e.target.value })
            }
          >
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={index}
                value={application?.id}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel id="licenseType">Type</InputLabel>
          <Select
            labelId="licenseType"
            size="small"
            label="Type"
            style={{ fontSize: 12 }}
            value={newLicense?.licenseType}
            onChange={(e) =>
              setNewLicense({
                ...newLicense,
                licenseType: e.target.value,
                activities: [],
              })
            }
          >
            <MenuItem value="Full Application" style={{ fontSize: 12 }}>
              Full Application
            </MenuItem>
            <MenuItem value="Limited Feature" style={{ fontSize: 12 }}>
              Limited Feature
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          required
          min={0}
          variant="standard"
          label="Quantity"
          type="number"
          value={newLicense?.quantity}
          onChange={(e) =>
            setNewLicense({
              ...newLicense,
              quantity: Math.max(0, e.target.value),
            })
          }
        />

        <TextField
          fullWidth
          required
          variant="standard"
          label="Expiry Date"
          type="date"
          defaultValue={moment(new Date()).format("yyyy-MM-DD")}
          value={newLicense?.expiryDate}
          onChange={(e) =>
            setNewLicense({
              ...newLicense,
              expiryDate: e.target.value,
            })
          }
        />

        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel id="duartion">Duration</InputLabel>
          <Select
            labelId="duartion"
            size="small"
            label="Duartion"
            style={{ fontSize: 12 }}
            value={newLicense?.duration}
            onChange={(e) =>
              setNewLicense({ ...newLicense, duration: e.target.value })
            }
          >
            <MenuItem value={"1 year"} style={{ fontSize: 12 }}>
              {"1 year"}
            </MenuItem>
            <MenuItem value={"6 months"} style={{ fontSize: 12 }}>
              {"6 months"}
            </MenuItem>
          </Select>
        </FormControl>

        {newLicense?.licenseType === "Limited Feature" && (
          <Autocomplete
            multiple
            size="small"
            limitTags={2}
            filterSelectedOptions
            style={{ fontSize: 12 }}
            value={newLicense?.activities}
            onChange={(e, activities) => {
              setNewLicense({
                ...newLicense,
                activities: activities,
              });
            }}
            options={basicReducerState?.activities?.filter((activity) =>
              isApplicationActivity(activity, newLicense?.applicationName)
            )}
            getOptionLabel={(option) => {
              return `${option.name} (${getEntityNameById(option?.entityId)})`;
            }}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />
                <Typography style={{ fontSize: 12 }}>{`${
                  option.name
                } (${getEntityNameById(option?.entityId)})`}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Associate Features"
                style={{ fontSize: 12 }}
              />
            )}
          />
        )}

        <TextField
          fullWidth
          min={1}
          variant="standard"
          label="Max Active session"
          type="number"
          value={newLicense?.maxSession}
          onChange={(e) =>
            setNewLicense({
              ...newLicense,
              maxSession: Math.max(1, e.target.value),
            })
          }
        />

        <TextField
          fullWidth
          min={1}
          variant="standard"
          label="Maximum User Assigned"
          type="number"
          value={newLicense?.maxUserAssign}
          onChange={(e) =>
            setNewLicense({
              ...newLicense,
              maxUserAssign: Math.max(1, e.target.value),
            })
          }
        />
      </DialogContent>

      <DialogActions className={classes.newLicenseDialogActions}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            onClose();
            setNewLicense(initialNewLicense);
          }}
          style={{ textTransform: "capitalize" }}
          disabled={load}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleCreateLicense}
          style={{ textTransform: "capitalize" }}
          disabled={
            newLicense?.name?.length === 0 ||
            newLicense?.applicationName?.length === 0 ||
            newLicense?.licenseType?.length === 0 ||
            newLicense?.quantity <= 0 ||
            newLicense?.duration?.length === 0 ||
            load
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateLicense = ({
  open,
  onClose,
  selectedLicense,
  setSelectedLicense,
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();

  const [updateLicenseUser, setUpdateLicenseUser] = useState([]);
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState({ open: false, msg: "" });

  const handleUpdateLicense = () => {
    setMsg({ open: false, msg: "" });
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const updateLicenseUrl = `${process.env.REACT_APP_IWAServices}/api/v1/license/mapLicenseUsers`;
      const updateLicensePayload = updateLicenseUser?.map((user) => ({
        userEmail: user?.emailId,
        applicationName: selectedLicense?.applicationName,
        licenseNumber: selectedLicense?.licenseNumber,
      }));
      const updateLicenseRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState?.token}`,
        },
        body: JSON.stringify(updateLicensePayload),
      };
      // console.log(updateLicensePayload);
      fetch(updateLicenseUrl, updateLicenseRequestParam)
        .then((res) => res.json())
        .then((data) => {
          dispatch(
            setLienses(
              basicReducerState?.licenses?.map((license) =>
                license?.licenseNumber === selectedLicense?.licenseNumber
                  ? {
                      ...license,
                      status: "Assigned",
                      usersEmail: [
                        ...license?.usersEmail,
                        ...updateLicenseUser?.map((user) => user?.emailId),
                      ],
                    }
                  : { ...license }
              )
            )
          );
          setUpdateLicenseUser([]);
          onClose();
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const isAssignedUser = (user) => {
    const licenses = basicReducerState?.licenses?.filter(
      (license) => license?.applicationId === selectedLicense?.applicationId
    );
    for (let i = 0; i < licenses?.length; i++) {
      if (licenses?.[i]?.usersEmail?.includes(user?.emailId)) {
        return true;
      }
    }
    if (user?.applicationName?.includes(selectedLicense?.applicationName)) {
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newLicenseDialogTitle}>
        Assign Users
      </DialogTitle>

      <DialogContent className={classes.newLicenseDialogContent}>
        <Loading load={load} />

        {msg?.open && (
          <Typography
            style={{ fontSize: 12, color: "red", textAlign: "center" }}
          >
            {msg?.msg}
          </Typography>
        )}

        <Autocomplete
          multiple
          size="small"
          // limitTags={2}
          disableCloseOnSelect
          filterSelectedOptions
          style={{ fontSize: 12 }}
          value={updateLicenseUser}
          onChange={(e, users) => {
            if (
              selectedLicense?.usersEmail?.length + users?.length <=
              selectedLicense?.maxUsers
            ) {
              setUpdateLicenseUser(users);
            } else {
              setMsg({
                open: true,
                msg: "You have reached the limit. Please assign other license to remaining users.",
              });
            }
          }}
          options={basicReducerState?.users?.filter(
            (user) => !isAssignedUser(user)
          )}
          getOptionLabel={(option) => {
            return `${option.displayName}`;
          }}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />

              {/* <CustomAvatar
                name={option?.displayName}
                style={{
                  marginRight: 6,
                  marginLeft: 8,
                }}
              /> */}

              <Typography
                style={{ fontSize: 12 }}
              >{`${option.displayName}`}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Users"
              style={{ fontSize: 12 }}
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.newLicenseDialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onClose();
            setMsg({ open: false, msg: "" });
            setUpdateLicenseUser([]);
          }}
          style={{ textTransform: "capitalize" }}
          disabled={load}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          onClick={handleUpdateLicense}
          style={{ textTransform: "capitalize" }}
          disabled={updateLicenseUser?.length === 0 || load}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const License = ({
  license,
  index,
  setUpdateLicense,
  selectedLicense,
  setSelectedLicense,
  setOpenAssignedUserLicense,
}) => {
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const classes = useStyle();

  return (
    <Paper
      key={index}
      elevation={0}
      className={`${classes.licenseContainer} ${
        selectedLicense?.licenseNumber === license?.licenseNumber &&
        classes.licenseSelected
      }`}
    >
      {/* <div className={`${classes.licenseBand} ${classes.licenseExpiryBand}`} /> */}

      {license?.usersEmail?.length >= license?.maxUsers * 0.9 && (
        <div
          className={`${classes.licenseBand} ${classes.licenseCompletionBand}`}
        />
      )}

      <Typography className={classes.licenseNameValue}>
        <span>License Name: </span>
        {license?.licenseName}
      </Typography>

      <Typography className={classes.licenseLabel}>
        <span>License Number: </span>
        {license?.licenseNumber}
      </Typography>

      <Typography className={classes.licenseLabel}>
        <span>Type: </span>
        {license?.licenseType} ({license?.expiryDate || license?.duration})
      </Typography>

      <Typography className={classes.licenseLabel}>
        <span>Maximum Active Session: </span>
        {license?.maxSession}
      </Typography>

      <Typography className={classes.licenseLabel}>
        <span>Maximum Users: </span>
        {license?.maxUsers}
      </Typography>

      {userFeatures?.includes(features?.MAP_LICENSE) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 12,
          }}
        >
          <StyledBadge
            badgeContent={license?.usersEmail?.length || 0}
            color="primary"
            showZero
            // style={{ fontSize: 6 }}
            classes={{
              badge: {
                fontSize: "10px !important",
                height: "14px !important",
                width: "14px !important",
                minWidth: "6px !important",
              },
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setOpenAssignedUserLicense(true);
                setSelectedLicense(license);
              }}
            >
              <BsPersonLinesFill style={{ fontSize: 14 }} />
            </IconButton>
          </StyledBadge>

          <IconButton
            size="small"
            onClick={() => {
              setUpdateLicense(true);
              setSelectedLicense(license);
            }}
          >
            <ImUserPlus style={{ fontSize: 14 }} />
          </IconButton>
        </div>
      )}
    </Paper>
  );
};

const FilterLicenseDialog = ({ open, onClose, setFilterLicenses }) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const initialLicenseFilterState = {
    application: "All",
    licenseStatus: "All",
    licenseType: "All",
  };
  const [filterLicense, setFilterLicense] = useState(initialLicenseFilterState);
  const status = ["Close to Completion", "Close to Expiry"];

  // const handleFilterLicense = () => {
  //   setFilterLicenses(basicReducerState.licenses || []);
  //   var filterLicenseList = basicReducerState.licenses || [];
  //   if (
  //     filterLicense?.application !== "All" &&
  //     filterLicense?.licenseStatus !== "All" &&
  //     filterLicense?.licenseType !== "All"
  //   ) {
  //     filterLicenseList = basicReducerState?.licenses?.filter(
  //       (license) =>
  //         license?.applicationName === filterLicense?.application &&
  //         license?.status === filterLicense?.licenseStatus &&
  //         license?.licenseType === filterLicense?.licenseType
  //     );
  //   }
  //   if (
  //     filterLicense?.application !== "All" &&
  //     filterLicense?.licenseStatus !== "All" &&
  //     filterLicense?.licenseType === "All"
  //   ) {
  //     filterLicenseList = basicReducerState?.licenses?.filter(
  //       (license) =>
  //         license?.applicationName === filterLicense?.application &&
  //         license?.status === filterLicense?.licenseStatus
  //     );
  //   }
  //   if (
  //     filterLicense?.application !== "All" &&
  //     filterLicense?.licenseStatus === "All" &&
  //     filterLicense?.licenseType !== "All"
  //   ) {
  //     filterLicenseList = basicReducerState?.licenses?.filter(
  //       (license) =>
  //         license?.applicationName === filterLicense?.application &&
  //         license?.licenseType === filterLicense?.licenseType
  //     );
  //   }
  //   if (
  //     filterLicense?.application === "All" &&
  //     filterLicense?.licenseStatus !== "All" &&
  //     filterLicense?.licenseType !== "All"
  //   ) {
  //     filterLicenseList = basicReducerState?.licenses?.filter(
  //       (license) =>
  //         license?.status === filterLicense?.licenseStatus &&
  //         license?.licenseType === filterLicense?.licenseType
  //     );
  //   } else if (
  //     filterLicense?.application !== "All" &&
  //     filterLicense?.licenseStatus === "All" &&
  //     filterLicense?.licenseType === "All"
  //   ) {
  //     filterLicenseList = basicReducerState?.licenses?.filter(
  //       (license) => license?.applicationName === filterLicense?.application
  //     );
  //   } else if (
  //     filterLicense?.application === "All" &&
  //     filterLicense?.licenseStatus !== "All" &&
  //     filterLicense?.licenseType === "All"
  //   ) {
  //     filterLicenseList = basicReducerState?.licenses?.filter(
  //       (license) => license?.status === filterLicense?.licenseStatus
  //     );
  //   } else if (
  //     filterLicense?.application === "All" &&
  //     filterLicense?.licenseStatus === "All" &&
  //     filterLicense?.licenseType !== "All"
  //   ) {
  //     filterLicenseList = basicReducerState?.licenses?.filter(
  //       (license) => license?.licenseType === filterLicense?.licenseType
  //     );
  //   }
  //   // console.log(filterLicense, filterLicenseList);
  //   setFilterLicenses(filterLicenseList);
  //   onClose();
  // };
  const resetFilter = () => {
    setFilterLicense(initialLicenseFilterState);
    setFilterLicenses(basicReducerState?.licenses || []);
  };
  const handleFilterLicense = () => {
    setFilterLicenses(basicReducerState?.licenses || []);
    var filterLicenseList = basicReducerState?.licenses || [];
    if (filterLicense?.application !== "All") {
      filterLicenseList = filterLicenseList?.filter(
        (license) => license?.applicationName === filterLicense?.application
      );
    }
    if (filterLicense?.licenseType !== "All") {
      filterLicenseList = filterLicenseList?.filter(
        (license) => license?.licenseType === filterLicense?.licenseType
      );
    }
    if (filterLicense?.licenseStatus === "Close to Completion") {
      filterLicenseList = filterLicenseList?.filter(
        (license) => license?.usersEmail?.length >= license?.maxUsers * 0.9
      );
    } else if (filterLicense?.licenseStatus === "Close to Expiry") {
    }
    setFilterLicenses(filterLicenseList);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newLicenseDialogTitle}>
        Filter License
      </DialogTitle>

      <DialogContent className={classes.newLicenseDialogContent}>
        <FormControl
          variant="standard"
          size="small"
          required
          fullWidth
          // className={classes.licensesHeaderDropdown}
        >
          <InputLabel>Application name</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={filterLicense?.application}
            onChange={(e) => {
              setFilterLicense({
                ...filterLicense,
                application: e.target.value,
              });
            }}
          >
            <MenuItem value={"All"} style={{ fontSize: 12 }}>
              All
            </MenuItem>
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={`${application?.id}-${index}`}
                value={application?.name}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <FormControl
          variant="standard"
          size="small"
          required
          fullWidth
          // className={classes.licensesHeaderDropdown}
        >
          <InputLabel>License Status</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={filterLicense?.licenseStatus}
            onChange={(e) => {
              setFilterLicense({
                ...filterLicense,
                licenseStatus: e.target.value,
              });
            }}
          >
            <MenuItem value={"All"} style={{ fontSize: 12 }}>
              All
            </MenuItem>
            <MenuItem value={"Available"} style={{ fontSize: 12 }}>
              Available
            </MenuItem>
            <MenuItem value={"Assigned"} style={{ fontSize: 12 }}>
              Assigned
            </MenuItem>
          </Select>
        </FormControl> */}

        <FormControl
          variant="standard"
          size="small"
          required
          fullWidth
          // className={classes.licensesHeaderDropdown}
        >
          <InputLabel>License Status</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={filterLicense?.licenseStatus}
            onChange={(e) => {
              setFilterLicense({
                ...filterLicense,
                licenseStatus: e.target.value,
              });
            }}
          >
            <MenuItem value={"All"} style={{ fontSize: 12 }}>
              All
            </MenuItem>
            {status?.map((data, index) => (
              <MenuItem key={index} value={data} style={{ fontSize: 12 }}>
                {data}
              </MenuItem>
            ))}
            {/* <MenuItem value={"Limited Feature"} style={{ fontSize: 12 }}>
              Close to Expiry
            </MenuItem> */}
          </Select>
        </FormControl>

        <FormControl
          variant="standard"
          size="small"
          required
          fullWidth
          // className={classes.licensesHeaderDropdown}
        >
          <InputLabel>License Type</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={filterLicense?.licenseType}
            onChange={(e) => {
              setFilterLicense({
                ...filterLicense,
                licenseType: e.target.value,
              });
            }}
          >
            <MenuItem value={"All"} style={{ fontSize: 12 }}>
              All
            </MenuItem>
            <MenuItem value={"Full Application"} style={{ fontSize: 12 }}>
              Full Application
            </MenuItem>
            <MenuItem value={"Limited Feature"} style={{ fontSize: 12 }}>
              Limited Feature
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions className={classes.newLicenseDialogActions}>
        <Button
          color="default"
          variant="outlined"
          onClick={() => {
            onClose();
          }}
          style={{ textTransform: "capitalize" }}
        >
          Close
        </Button>

        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            resetFilter();
            // onClose();
          }}
          style={{ textTransform: "capitalize" }}
        >
          Reset
        </Button>

        <Button
          color="primary"
          variant="outlined"
          onClick={handleFilterLicense}
          style={{ textTransform: "capitalize" }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AssignedUserDialog = ({ open, onClose, selectedLicense }) => {
  const classes = useStyle();
  const [load, setLoad] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.newLicenseDialogTitle}>
        Assigned Users
      </DialogTitle>

      <DialogContent className={classes.newLicenseDialogContent}>
        <Loading load={load} />

        {selectedLicense?.usersEmail?.map((email, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontSize: 12 }}>{email}</Typography>

            {/* <Tooltip title="Remove">
              <IconButton size="small" color="secondary">
                <BiTrash />
              </IconButton>
            </Tooltip> */}
          </div>
        ))}
      </DialogContent>

      <DialogActions className={classes.newLicenseDialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onClose();
          }}
          style={{ textTransform: "capitalize" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function Licenses() {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [filteredLicences, setFilterLicenses] = useState([]);
  const [formattedLicences, setFormattedLicenses] = useState({});
  const [openLicenseDialog, setOpenLicenseDialog] = useState(false);
  const [openLicenseFilterDialog, setOpenLicenseFilterDialog] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  const [updateLicense, setUpdateLicense] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [openAssignedUserLicense, setOpenAssignedUserLicense] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const getLicenses = () => {
    if (authVerify(userReducerState?.token)) {
      getAllLicenses(
        userReducerState.token,
        () => {
          setLoad(true);
        },
        (data) => {
          dispatch(setLienses(data?.data || []));
          setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  // console.log(selectedLicense);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });

  useEffect(() => {
    console.log('basicreducer',basicReducerState);
    setFilterLicenses(basicReducerState.licenses);
  }, [basicReducerState.licenses]);

  useEffect(() => {
    formatLicenses();
  }, [filteredLicences, basicReducerState.applications]);

  const formatLicenses = () => {
    var fLicenses = {};
    basicReducerState?.applications?.map((application) => {
      fLicenses[application?.name] = [];
      return null;
    });
    filteredLicences?.map((license) => {
      fLicenses[license?.applicationName]?.push(license);
      return null;
    });
    setFormattedLicenses(fLicenses);
  };
  // console.log(formattedLicences);

  return (
    <div
      style={{
        width: `calc(100vw - ${sidebarWidth})`,
      }}
    >
      <Loading load={load} />

      <NewLicense
        open={openLicenseDialog}
        onClose={() => {
          setLoad(false);
          setOpenLicenseDialog(false);
        }}
      />

      <FilterLicenseDialog
        open={openLicenseFilterDialog}
        onClose={() => {
          setOpenLicenseFilterDialog(false);
        }}
        setFilterLicenses={setFilterLicenses}
      />

      <UpdateLicense
        open={updateLicense}
        onClose={() => {
          setUpdateLicense(false);
          setSelectedLicense(null);
        }}
        selectedLicense={selectedLicense}
        setSelectedLicense={setSelectedLicense}
      />

      <AssignedUserDialog
        open={openAssignedUserLicense}
        onClose={() => {
          setOpenAssignedUserLicense(false);
          setSelectedLicense(null);
        }}
        selectedLicense={selectedLicense}
      />

      <div className={classes.licensesHeaderContainer}>
        <Typography className={classes.licenseHeaderTitle}>
          Subscription Management
        </Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            onClick={() => {
              setOpenLicenseFilterDialog(true);
            }}
            disabled={load}
            color="primary"
          >
            <FilterList />
          </IconButton>

          <IconButton
            size="small"
            style={{ marginLeft: 4 }}
            disabled={load}
            onClick={getLicenses}
          >
            <Refresh style={{ fontSize: 20 }} />
          </IconButton>

          {userFeatures?.includes(features?.ADD_LICENSES) && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              className={classes.licensesHeaderAddButton}
              onClick={() => setOpenLicenseDialog(true)}
              startIcon={<Add />}
              disabled={load}
            >
              Add New License
            </Button>
          )}
        </div>
      </div>

      <div className={`${classes.licensesCardContainer} iagScroll`}>
        {filteredLicences?.length <= 0 ? (
          <NotFound />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {Object?.keys(formattedLicences)
              ?.filter((appName) => formattedLicences[appName]?.length > 0)
              ?.map((appName, index) => (
                <div key={index}>
                  <Typography
                    style={{ fontSize: 24, fontWeight: 500, marginTop: 12 }}
                  >
                    {appName}
                  </Typography>

                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={false}
                    autoPlay={false}
                    keyBoardControl={true}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {formattedLicences?.[appName]?.map((license, index) => (
                      <License
                        key={index}
                        license={license}
                        index={index}
                        updateLicense={updateLicense}
                        setUpdateLicense={setUpdateLicense}
                        selectedLicense={selectedLicense}
                        setSelectedLicense={setSelectedLicense}
                        setOpenAssignedUserLicense={setOpenAssignedUserLicense}
                      />
                    ))}
                  </Carousel>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Licenses;
