import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import moment from "moment";
import { getAllApplications } from "../../Action/action";
import {
  setApplications,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { APPLICATION_NAME_EXISTS, toastMessage } from "../../Utility/config";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import { roles } from "../../Data/data";

const useStyle = makeStyles((theme) => ({
  newApplicationDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newApplicationDialogActionsContainer: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
}));

const NewApplication = ({
  open,
  onClose,
  title,
  update,
  updatingApplication,
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);
  const initialState = {
    name: "",
    label: "",
    description: "",
    applicationType: "",
    applications: [],
    applicationOwnerEmails: [],
    uniqueIds: [],
  };
  const [newApplication, setNewApplication] = useState(initialState);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getApplicationIdByName = (appName) => {
    const application = basicReducerState?.applications?.find(
      (application) =>
        application?.name?.toLowerCase() === appName?.toLowerCase()
    );
    return application?.id || "-1";
  };

  const handleCreateApplication = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const insertApplicationUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications`;
      // const insertApplicationUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications`;
      const insertApplicationPayload = {
        createdBy: userReducerState?.user?.preferred_username,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        description: newApplication?.description,
        isActive: 1,
        isDeleted: 0,
        label: newApplication?.label,
        name: newApplication?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        applications: newApplication?.applications
          ?.map((applicationName) => getApplicationIdByName(applicationName))
          ?.filter((application) => application !== "-1"),
        applicationType: newApplication?.applicationType,
        applicationOwnerEmails: newApplication?.applicationOwnerEmails,
        uniqueIds: newApplication?.uniqueIds,
      };
      const insertApplicationRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(insertApplicationPayload),
      };
      fetch(insertApplicationUrl, insertApplicationRequestParam)
        .then((res) => res.json())
        .then((app_data) => {
          setNewApplication(initialState);
          setLoad(false);
          getAllApplications(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setApplications(data?.data || []));
              setLoad(false);
              onClose();
              setError(false);
              setErrorMessage("");

              dispatch(
                setResponseMessage({
                  open: true,
                  status: app_data?.status ? "success" : "error",
                  message: app_data?.status
                    ? toastMessage?.APPLICATION_CREATED
                    : toastMessage?.SOMETHING_WENT_WRONG,
                })
              );
            },
            (err) => {
              setLoad(false);
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleUpdateApplication = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateApplicationUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/modify`;
      // const updateApplicationUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/modify`;
      const updateApplicationPayload = {
        id: newApplication?.id,
        createdBy: newApplication?.createdBy,
        createdOn: moment(new Date(newApplication?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        description: newApplication?.description,
        isActive: 1,
        isDeleted: 0,
        label: newApplication?.label,
        name: newApplication?.name,
        status: "Active",
        updatedBy: userReducerState?.user?.preferred_username,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        applications: newApplication?.applications
          ?.map((applicationName) => getApplicationIdByName(applicationName))
          ?.filter((application) => application !== "-1"),
        applicationType: newApplication?.applicationType,
        applicationOwnerEmails: newApplication?.applicationOwnerEmails,
        uniqueIds: newApplication?.uniqueIds,
      };
      const updateApplicationRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateApplicationPayload),
      };
      fetch(updateApplicationUrl, updateApplicationRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setNewApplication(initialState);
          setLoad(false);
          dispatch(
            setApplications(
              basicReducerState?.applications?.map((application) =>
                Number(application?.id) === Number(newApplication?.id)
                  ? {
                      ...application,
                      name: newApplication?.name,
                      label: newApplication.label,
                      description: newApplication.description,
                      applications: updateApplicationPayload?.applications,
                      applicationType: newApplication?.applicationType,
                      applicationOwnerEmails:
                        newApplication?.applicationOwnerEmails,
                      uniqueIds: newApplication?.uniqueIds,
                    }
                  : { ...application }
              ) || []
            )
          );
          onClose();
          setError(false);
          setErrorMessage("");

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? toastMessage?.APPLICATION_UPDATED
                : toastMessage?.SOMETHING_WENT_WRONG,
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  useEffect(() => {
    if (updatingApplication) {
      // console.log(updatingApplication);
      setNewApplication(updatingApplication);
    } else {
      setNewApplication(initialState);
    }
  }, [updatingApplication]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setNewApplication(initialState);
        setError(false);
        setErrorMessage("");
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.newApplicationDialogTitle}>
        {title}
      </DialogTitle>

      <DialogContent>
        <Loading load={load} />

        <TextField
          variant="standard"
          fullWidth
          required
          label="Name"
          value={newApplication?.name}
          onChange={(e) => {
            setNewApplication({ ...newApplication, name: e.target.value });
            if (update) {
              const applications = basicReducerState?.applications?.filter(
                (app) =>
                  app?.name?.toLowerCase() === e.target.value?.toLowerCase() &&
                  app?.id !== newApplication?.id
              );
              if (applications?.length > 0) {
                setError(true);
                setErrorMessage(APPLICATION_NAME_EXISTS);
              } else {
                setError(false);
                setErrorMessage("");
              }
            } else {
              const applications = basicReducerState?.applications?.filter(
                (app) =>
                  app?.name?.toLowerCase() === e.target.value?.toLowerCase()
              );
              if (applications?.length > 0) {
                setError(true);
                setErrorMessage(APPLICATION_NAME_EXISTS);
              } else {
                setError(false);
                setErrorMessage("");
              }
            }
          }}
          disabled={
            userReducerState?.entitiesAndActivities?.roles?.includes(
              roles?.SUPER_ADMIN
            )
              ? false
              : true
          }
          error={error}
          helperText={error && errorMessage}
        />

        <TextField
          variant="standard"
          fullWidth
          required
          label="Label"
          value={newApplication?.label}
          onChange={(e) =>
            setNewApplication({ ...newApplication, label: e.target.value })
          }
        />

        <TextField
          variant="standard"
          fullWidth
          required
          label="Description"
          value={newApplication?.description}
          onChange={(e) =>
            setNewApplication({
              ...newApplication,
              description: e.target.value,
            })
          }
        />

        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel id="applicationType">Application Type</InputLabel>
          <Select
            labelId="applicationType"
            size="small"
            label="Type"
            style={{ fontSize: 12 }}
            value={newApplication?.applicationType}
            onChange={(e) =>
              setNewApplication({
                ...newApplication,
                applicationType: e.target.value,
              })
            }
            disabled={
              userReducerState?.entitiesAndActivities?.roles?.includes(
                roles?.SUPER_ADMIN
              )
                ? false
                : true
            }
          >
            <MenuItem value={"CW"} style={{ fontSize: 12 }}>
              Cherrywork System
            </MenuItem>
            <MenuItem value={"PROD"} style={{ fontSize: 12 }}>
              Production System
            </MenuItem>
            <MenuItem value={"DEV"} style={{ fontSize: 12 }}>
              Non-Production System
            </MenuItem>
          </Select>
        </FormControl>

        <Autocomplete
          disableCloseOnSelect
          filterSelectedOptions
          multiple
          size="small"
          style={{ fontSize: 12 }}
          value={newApplication?.applications}
          onChange={(e, applications) => {
            setNewApplication({
              ...newApplication,
              applications: applications,
            });
          }}
          options={
            update
              ? basicReducerState?.applications
                  ?.filter(
                    (application) => application?.id !== newApplication?.id
                  )
                  ?.map((application) => application?.name)
              : basicReducerState?.applications?.map(
                  (application) => application?.name
                )
          }
          getOptionLabel={(option) => option}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Allow Applications"
              style={{ fontSize: 12 }}
            />
          )}
        />

        <Autocomplete
          filterSelectedOptions
          disableCloseOnSelect
          multiple
          size="small"
          style={{ fontSize: 12 }}
          value={newApplication?.applicationOwnerEmails}
          onChange={(e, applicationOwnerEmails) => {
            setNewApplication({
              ...newApplication,
              applicationOwnerEmails: applicationOwnerEmails,
            });
          }}
          options={basicReducerState?.users?.map((user) => user?.emailId)}
          getOptionLabel={(option) => option}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Owners Email Id"
              style={{ fontSize: 12 }}
            />
          )}
          disabled={
            userReducerState?.entitiesAndActivities?.roles?.includes(
              roles?.SUPER_ADMIN
            )
              ? false
              : true
          }
        />

        <Autocomplete
          filterSelectedOptions
          disableCloseOnSelect
          multiple
          size="small"
          style={{ fontSize: 12 }}
          value={newApplication?.uniqueIds}
          onChange={(e, uniqueIds) => {
            setNewApplication({
              ...newApplication,
              uniqueIds: uniqueIds,
            });
          }}
          options={["Pid", "EmailId", "Username"]}
          getOptionLabel={(option) => option}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Unique Ids"
              style={{ fontSize: 12 }}
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.newApplicationDialogActionsContainer}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            setNewApplication(initialState);
            setError(false);
            setErrorMessage("");
            onClose();
          }}
          style={{ textTransform: "capitalize" }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            if (update) {
              handleUpdateApplication();
            } else {
              handleCreateApplication();
            }
          }}
          disabled={
            newApplication?.name?.length === 0 ||
            newApplication?.label?.length === 0 ||
            newApplication?.description?.length === 0 ||
            newApplication?.applicationType?.length === 0 ||
            load ||
            error
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewApplication;
