import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Sidebar from "./Components/Sidebar";

import SignOut from "./Components/SignOut";
import Header from "./Components/Header";
import Mappings from "./Components/Mappings/Mappings";
import RequestIndex from "./Components/Requests/Index";
import Index from "./Components/Systems/index";
import DashboardIndex from "./Components/Dashboard/Index";
import Profile from "./Components/Profile/Profile";
// import OtherAppIndex from "./Components/Others/Index";
import Toast from "./Components/Toast";
// import {Authoring} from "@cw/rule-generator";
import { useDispatch, useSelector } from "react-redux";
import Boarding from "./Components/Onboarding/Boarding";
import { modules, roles } from "./Data/data";

import SessionExpired from "./Components/SessionExpired";
import { setIsSessionExpired } from "./Redux/Reducers/userReducer";
import Workspace from "./Components/ITM/Workspace";

const useStyle = makeStyles((theme) => ({
  appContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  appBody: {
    width: "100%",
    height: "calc(100vh - 48px)",
    display: "flex",
    overflow: "hidden",
    position: "relative",
  },
}));

function Iag({ keycloak }) {
  const dispatch = useDispatch();
  const userReducerState = useSelector((state) => state.userReducer);
  const userModules =
    Object?.keys(
      userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0] || {}
    ) || [];
  const userRoles = userReducerState?.entitiesAndActivities?.roles || [];
  const classes = useStyle();
  const [openSignOut, setOpenSignOut] = useState(false);
  const [selectedSubItem, setSelectedSubItem] = useState("Provisioning");

  return (
    <div
      className={classes.appContainer}
      onClick={(e) => {
        setOpenSignOut(false);
      }}
    >
      {/* <Router> */}
      <Header openSignOut={openSignOut} setOpenSignOut={setOpenSignOut} />

      <div className={classes.appBody}>
        <Sidebar
          selectedSubItem={selectedSubItem}
          setSelectedSubItem={setSelectedSubItem}
        />
        {openSignOut && <SignOut keycloak={keycloak} />}

        <Switch>
          <Route exact path="/">
            <Redirect to="/requests" />
          </Route>

          {userModules?.includes(modules?.REQUESTS) && (
            <Route exact path="/requests">
              <RequestIndex selectedSubItem={selectedSubItem} />
            </Route>
          )}

          {userModules?.includes(modules?.CONFIGURATIONS) &&
            (userRoles?.includes(roles?.SYSTEM_OWNER) ||
              userRoles?.includes(roles?.SUPER_ADMIN)) && (
              <Route exact path="/masterData">
                <Index selectedSubItem={selectedSubItem} />
              </Route>
            )}

          {userModules?.includes(modules?.BOARDING) && (
            <Route exact path="/boarding">
              <Boarding selectedSubItem={selectedSubItem} />
            </Route>
          )}

          <Route exact path="/dashboard">
            <DashboardIndex selectedSubItem={selectedSubItem} />
          </Route>

          {userRoles?.includes(roles?.BUSINESS_USER) && (
            <Route exact path="/profile">
              <Profile />
            </Route>
          )}

          <Route path="/workspace/*">
            <Workspace />
          </Route>
        </Switch>
      </div>
      {/* </Router> */}

      <Toast />

      <SessionExpired
        open={userReducerState?.isSessionExpired}
        onClick={() => {
          dispatch(setIsSessionExpired(false));
          keycloak.logout();
        }}
      />
    </div>
  );
}

export default Iag;
