import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add,
  Delete,
  Remove,
  Close,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import Loading from "../Loading";
import CustomAvatar from "../CustomAvatar";
import {
  findApplicationById,
  findRoleById,
  findUserById,
} from "../../Utility/basic";
import {
  setGroups,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import DeletionMessageBox from "../DeletionMessageBox";
import { features } from "../../Data/data";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  buttonHeight,
  crossIconContainerHeight,
  groupDetailPageCss,
  groupPageHeaderHeight,
} from "../../Data/cssConstant";

const useStyle = makeStyles((theme) => ({
  groupInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    height: `calc(100vh - ${appHeaderHeight} - ${groupPageHeaderHeight} - ${crossIconContainerHeight} - ${groupDetailPageCss?.tabsContainerHeight} - ${groupDetailPageCss?.footerHeight} - 18px)`,
  },
  groupInfoItemContainer: {
    margin: 4,
    alignItems: "center",
  },
  groupInfoContainerLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  groupInfoContainerText: {
    fontSize: 12,
  },

  newGroupAssociateUsersDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newGroupAssociateUsersDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  groupAssociateUsersTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${groupPageHeaderHeight} - ${crossIconContainerHeight} - ${groupDetailPageCss?.tabsContainerHeight} - ${buttonHeight} - ${groupDetailPageCss?.footerHeight} - 26px)`,
    // overflow: "overlay",
    width: "100%",
  },
  groupAssociateUsersTableHead: {
    // backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: "#F1F5FE",
  },
  groupAssociateUsersTableHeadCell: {
    // fontWeight: 700,
    whiteSpace: "nowrap",
    // color: theme.palette.background.paper,
    // fontSize: 14,
    fontSize: 9,
    // lineHeight: 1.5,
    fontWeight: "bold",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  groupAssociateUsersTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  groupAssociateUsersTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  groupAssociateUsersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  groupAssociateUsersBottomAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },

  newGroupAssignedRolesDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newGroupAssignedRolesDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  groupAssignedRolesTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${groupPageHeaderHeight} - ${crossIconContainerHeight} - ${groupDetailPageCss?.tabsContainerHeight} - ${buttonHeight} - ${groupDetailPageCss?.footerHeight} - 26px)`,
    // overflow: "overlay",
    width: "100%",
  },
  groupAssignedRolesTableHead: {
    // backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: "#F1F5FE",
  },
  groupAssignedRolesTableHeadCell: {
    // fontWeight: 700,
    whiteSpace: "nowrap",
    // color: theme.palette.background.paper,
    // fontSize: 14,
    fontSize: 9,
    // lineHeight: 1.5,
    fontWeight: "bold",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  groupAssignedRolesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  groupAssignedRolesTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  groupAssignedRolesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  groupAssignedRolesBottomAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },

  groupDetailContainer: {
    flexDirection: "column",
    height: "100%",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.palette.background.paper,
    margin: 0,
    padding: 0,
    // overflow: "overlay",
    position: "relative",
  },
  groupDetailCrossContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 10,
    paddingTop: 10,
    height: crossIconContainerHeight,
  },
  groupDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    height: groupDetailPageCss?.tabsContainerHeight,
  },
  groupDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    width: 150,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  groupDetailHeaderItemSelected: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  groupDetailFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "0px 0px 9px #D8D8D8",
    padding: "8px 16px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 999,
    height: groupDetailPageCss?.footerHeight,
  },
  groupDetailFooterButton: {
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const GroupInfo = ({ groupDetail, setGroupDetail, params, load }) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const basicReducerState = useSelector((state) => state.basicReducer);
  // const params = useParams();

  const getApplicationNameById = (id) => {
    const application = findApplicationById(
      Number(id),
      basicReducerState?.applications
    );
    return application?.name || "-";
  };

  return (
    <div className={classes.groupInfoContainer}>
      <Grid container className={classes.groupInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.groupInfoContainerLabel}>
            Name
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <div
            className={`inputContainer ${
              basicReducerState?.groups?.find(
                (group) =>
                  group?.name === groupDetail?.name &&
                  // group?.applicationId === groupDetail?.applicationId &&
                  Number(group?.id) !== Number(params?.groupId)
              ) &&
              !load &&
              "inputError"
            }`}
          >
            <input
              className={`${classes.groupInfoContainerText} ${
                userReducerState.darkMode && "inputDarkMode"
              }`}
              value={groupDetail?.name}
              disabled={!userFeatures?.includes(features?.ADD_GROUPS)}
              onChange={(e) =>
                setGroupDetail({ ...groupDetail, name: e.target.value })
              }
            />
          </div>

          {basicReducerState?.groups?.find(
            (group) =>
              group?.name === groupDetail?.name &&
              //group?.applicationId === groupDetail?.applicationId &&
              Number(group?.id) !== Number(params?.groupId)
          ) &&
            !load && (
              <p style={{ color: "red", fontSize: 10, marginTop: 3 }}>
                Group name already exists
              </p>
            )}

          {groupDetail?.name?.length === 0 && !load && (
            <p style={{ color: "red", fontSize: 10, marginTop: 3 }}>
              Please fill it
            </p>
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.groupInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.groupInfoContainerLabel}>
            Application
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.groupInfoContainerText}>
            {groupDetail?.applicationId
              ?.map((id) => getApplicationNameById(id))
              ?.filter((app) => app)
              ?.join(", ")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const NewGroupAssociateUsers = ({
  open,
  onClose,
  groupDetail,
  setGroupDetail,
  params,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [newAssociateUsers, setNewAssociateUsers] = useState([]);

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name || "-";
  };
  // const presentUser = (p_user) => {
  //   return groupDetail?.associateUsers?.filter(
  //     (user) => Number(user?.id) === Number(p_user?.id)
  //   )?.length > 0
  //     ? true
  //     : false;
  // };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newGroupAssociateUsersDialogTitle}>
        New Associate Users
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          size="small"
          disableCloseOnSelect
          filterSelectedOptions
          style={{ fontSize: 12 }}
          value={newAssociateUsers}
          onChange={(e, users) => {
            setNewAssociateUsers(users || []);
          }}
          options={
            basicReducerState?.users?.filter(
              (user) =>
                !groupDetail?.associateUsers?.find(
                  (u) => user?.emailId === u?.emailId
                )
            )
            // ?.filter(
            //   (user) =>
            //     user?.applicationName?.includes(
            //       getApplicationNameById(groupDetail?.applicationId)
            //     ) && !presentUser(user)
            // )
          }
          getOptionLabel={(option) => option.displayName}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />

              {/* <CustomAvatar
                name={option?.displayName}
                style={{
                  marginRight: 6,
                  marginLeft: 8,
                }}
              /> */}

              <Typography style={{ fontSize: 12 }}>
                {option?.displayName}
              </Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Associate Users"
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.newGroupAssociateUsersDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            setNewAssociateUsers([]);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setGroupDetail({
              ...groupDetail,
              associateUsers: [
                ...newAssociateUsers?.map((user) => ({
                  ...user,
                  status: "Draft",
                })),
                ...groupDetail?.associateUsers,
              ],
            });
            onClose();
            setNewAssociateUsers([]);
          }}
          style={{ textTransform: "capitalize" }}
          disabled={newAssociateUsers?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GroupAssociateUsers = ({
  groupDetail,
  setGroupDetail,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [openAssociateUserDialog, setOpenAssociateUserDialog] = useState(false);
  const [deletingAssociateUser, setDeletingAssociateUser] = useState(null);
  // const params = useParams();
  const dispatch = useDispatch();

  const deleteAssociateUser = (associateUser) => {
    if (associateUser?.status === "Draft") {
      setGroupDetail({
        ...groupDetail,
        associateUsers: groupDetail?.associateUsers?.filter(
          (user) => user?.emailId !== associateUser?.emailId
        ),
      });
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const deleteAssociateUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/modify`;
        // const deleteAssociateUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/modify`;
        const deleteAssociateUserPayload = {
          id: Number(params?.groupId),
          name: groupDetail?.name,
          applicationId: groupDetail?.applicationId?.join(","),
          userIdList:
            groupDetail?.associateUsers
              ?.filter((user) => user?.emailId !== associateUser?.emailId)
              ?.map((user) => user?.emailId)
              .join(",") || "",
          roleIdList:
            groupDetail?.assignedRoles
              ?.map((role) => Number(role?.id))
              .join(",") || "",
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        const deleteAssociateUserRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(deleteAssociateUserPayload),
        };
        fetch(deleteAssociateUserUrl, deleteAssociateUserRequestParam)
          .then((res) => res.json())
          .then((data) => {
            setLoad(false);
            setGroupDetail({
              ...groupDetail,
              associateUsers:
                groupDetail?.associateUsers?.filter(
                  (user) => user?.emailId !== associateUser?.emailId
                ) || [],
            });
            setDeletingAssociateUser(null);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.status
                  ? "Associated user to group deleted successfully"
                  : "Something went wrong",
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };

  return (
    <>
      <NewGroupAssociateUsers
        open={openAssociateUserDialog}
        onClose={() => setOpenAssociateUserDialog(false)}
        groupDetail={groupDetail}
        setGroupDetail={setGroupDetail}
        params={params}
      />

      <DeletionMessageBox
        open={deletingAssociateUser ? true : false}
        onClose={() => setDeletingAssociateUser(null)}
        onDelete={() => {
          deleteAssociateUser(deletingAssociateUser);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.groupAssociateUsersTableContainer} iagScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.groupAssociateUsersTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.groupAssociateUsersTableHeadCell}
              >
                Name
              </TableCell>

              {userFeatures?.includes(features?.ADD_GROUPS) && (
                <TableCell
                  align="center"
                  className={classes.groupAssociateUsersTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.groupAssociateUsersTableBody} iagScroll`}
          >
            {groupDetail?.associateUsers?.map((associateUser, index) => {
              return (
                <TableRow
                  key={`${associateUser?.id}-${index}`}
                  className={classes.groupAssociateUsersTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.groupAssociateUsersTableBodyCell}
                  >
                    {associateUser?.displayName}
                  </TableCell>

                  {userFeatures?.includes(features?.ADD_GROUPS) && (
                    <TableCell
                      align="center"
                      className={classes.groupAssociateUsersTableBodyCell}
                    >
                      <Tooltip
                        title={
                          associateUser?.status === "Draft"
                            ? "Remove"
                            : "Delete"
                        }
                      >
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteAssociateUser(associateUser);
                            setDeletingAssociateUser(associateUser);
                          }}
                          disabled={load}
                        >
                          {associateUser?.status === "Draft" ? (
                            <Remove style={{ fontSize: 16 }} />
                          ) : (
                            <Delete style={{ fontSize: 16 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {userFeatures?.includes(features?.ADD_GROUPS) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.groupAssociateUsersBottomAddButton}
          onClick={() => setOpenAssociateUserDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NewGroupAssignedRoles = ({
  open,
  onClose,
  groupDetail,
  setGroupDetail,
  params,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [newAssignedRoles, setNewAssignedRoles] = useState([]);

  const presentRole = (p_role) => {
    return groupDetail?.assignedRoles?.filter(
      (role) => Number(role?.id) === Number(p_role.id)
    )?.length > 0
      ? true
      : false;
  };
  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState?.applications
    );
    // console.log(application);
    return application?.name || "-";
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newGroupAssignedRolesDialogTitle}>
        New Assigned Roles
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          size="small"
          disableCloseOnSelect
          filterSelectedOptions
          style={{ fontSize: 12 }}
          value={newAssignedRoles}
          onChange={(e, roles) => {
            setNewAssignedRoles(roles || []);
          }}
          options={basicReducerState?.roles?.filter(
            (role) =>
              // groupDetail?.applicationId?.find(
              //   (id) => Number(id) === Number(role?.applicationId)
              // ) &&
              // Number(role?.applicationId) ===
              //   Number(groupDetail?.applicationId) &&
              !presentRole(role)
          )}
          groupBy={(option) => getApplicationNameById(option.applicationId)}
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option.name}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Assigned Roles"
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.newGroupAssignedRolesDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            setNewAssignedRoles([]);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setGroupDetail({
              ...groupDetail,
              assignedRoles: [
                ...newAssignedRoles?.map((role) => ({
                  ...role,
                  status: "Draft",
                })),
                ...groupDetail?.assignedRoles,
              ],
            });
            onClose();
            setNewAssignedRoles([]);
          }}
          style={{ textTransform: "capitalize" }}
          disabled={newAssignedRoles?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GroupAssignedRoles = ({
  groupDetail,
  setGroupDetail,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [openAssignRoleDialog, setOpenAssignRoleDialog] = useState(false);
  const [deletingAssignedRole, setDeletingAssignedRole] = useState(null);
  // const params = useParams();

  const deleteAssignedRole = (assignedRole) => {
    if (assignedRole?.status === "Draft") {
      setGroupDetail({
        ...groupDetail,
        assignedRoles: groupDetail?.assignedRoles?.filter(
          (user) => user?.emailId !== assignedRole?.emailId
        ),
      });
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        var applicationIds = {};
        groupDetail?.assignedRoles
          ?.filter((role) => Number(role?.id) !== Number(assignedRole?.id))
          ?.map((role) => {
            applicationIds[role?.applicationId] = 1;
            return null;
          });
        const deleteAssignedRoleUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/modify`;
        // const deleteAssignedRoleUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/modify`;
        const deleteAssignedRolePayload = {
          id: Number(params?.groupId),
          name: groupDetail?.name,
          applicationId: Object?.keys(applicationIds)?.join(","),
          userIdList:
            groupDetail?.associateUsers
              ?.map((user) => user?.emailId)
              .join(",") || "",
          roleIdList:
            groupDetail?.assignedRoles
              ?.filter((role) => Number(role?.id) !== Number(assignedRole?.id))
              ?.map((role) => Number(role?.id))
              .join(",") || "",
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        const deleteAssignedRoleRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(deleteAssignedRolePayload),
        };
        fetch(deleteAssignedRoleUrl, deleteAssignedRoleRequestParam)
          .then((res) => res.json())
          .then((data) => {
            setGroupDetail({
              ...groupDetail,
              assignedRoles:
                groupDetail?.assignedRoles?.filter(
                  (role) => Number(role?.id) !== Number(assignedRole?.id)
                ) || [],
            });
            setDeletingAssignedRole(null);
            setLoad(false);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.status
                  ? "Assigned role of group deleted successfully"
                  : "Something went wrong",
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };

  return (
    <>
      <NewGroupAssignedRoles
        open={openAssignRoleDialog}
        onClose={() => setOpenAssignRoleDialog(false)}
        groupDetail={groupDetail}
        setGroupDetail={setGroupDetail}
        params={params}
      />

      <DeletionMessageBox
        open={deletingAssignedRole ? true : false}
        onClose={() => setDeletingAssignedRole(null)}
        onDelete={() => {
          deleteAssignedRole(deletingAssignedRole);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.groupAssignedRolesTableContainer} iagScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.groupAssignedRolesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.groupAssignedRolesTableHeadCell}
              >
                Name
              </TableCell>

              {userFeatures?.includes(features?.ADD_GROUPS) && (
                <TableCell
                  align="center"
                  className={classes.groupAssignedRolesTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.groupAssignedRolesTableBody} iagScroll`}
          >
            {groupDetail?.assignedRoles?.map((assignedRole, index) => {
              return (
                <TableRow
                  key={`${assignedRole?.id}-${index}`}
                  className={classes.groupAssignedRolesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.groupAssignedRolesTableBodyCell}
                  >
                    {assignedRole?.name}
                  </TableCell>

                  {userFeatures?.includes(features?.ADD_GROUPS) && (
                    <TableCell
                      align="center"
                      className={classes.groupAssignedRolesTableBodyCell}
                    >
                      <Tooltip
                        title={
                          assignedRole?.status === "Draft" ? "Remove" : "Delete"
                        }
                      >
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteAssignedRole(assignedRole);
                            setDeletingAssignedRole(assignedRole);
                          }}
                          disabled={
                            load ||
                            !userFeatures?.includes(features?.ADD_GROUPS)
                          }
                        >
                          {assignedRole?.status === "Draft" ? (
                            <Remove style={{ fontSize: 16 }} />
                          ) : (
                            <Delete style={{ fontSize: 16 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {userFeatures?.includes(features?.ADD_GROUPS) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.groupAssignedRolesBottomAddButton}
          onClick={() => setOpenAssignRoleDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

function GroupDetail({ params, setParams }) {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [selectedGroupDetailContentType, setSelectedGroupDetailContentType] =
    useState("Basic Info");
  const [load, setLoad] = useState(false);
  const [groupDetail, setGroupDetail] = useState({});
  const history = useHistory();
  // const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.groupId) {
      getGroupInfoById();
    }
  }, [params?.groupId]);

  const getGroupInfoById = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const getGroupByIdUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/byGroupId?id=${params?.groupId}`;
      // const getGroupByIdUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/byGroupId?id=${params?.groupId}`;
      const getGroupByIdRequestParams = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(getGroupByIdUrl, getGroupByIdRequestParams)
        .then((res) => res.json())
        .then((groupData) => {
          setGroupDetail({
            ...groupData?.data,
            applicationId: groupData?.data?.applicationId?.split(","),
            associateUsers:
              groupData?.data?.userIdList?.length === 0
                ? []
                : groupData?.data?.userIdList
                    ?.split(",")
                    ?.map((emailId) =>
                      findUserById(emailId, basicReducerState?.users)
                    )
                    ?.filter((user) => user && user !== "null") || [],
            assignedRoles:
              groupData?.data?.roleIdList?.length === 0
                ? []
                : groupData?.data?.roleIdList
                    ?.split(",")
                    ?.map((roleId) =>
                      findRoleById(Number(roleId), basicReducerState?.roles)
                    )
                    .filter((role) => role && role !== "null") || [],
          });
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const updateGroupInfo = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      var applicationIds = {};
      groupDetail?.assignedRoles?.map((role) => {
        applicationIds[role?.applicationId] = 1;
        return null;
      });
      const updateGroupInfoUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/modify`;
      // const updateGroupInfoUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/modify`;
      const updateGroupInfoPayload = {
        id: Number(params?.groupId),
        name: groupDetail?.name,
        applicationId: Object?.keys(applicationIds)?.join(","),
        userIdList:
          groupDetail?.associateUsers?.map((user) => user?.emailId).join(",") ||
          "",
        roleIdList:
          groupDetail?.assignedRoles
            ?.map((role) => Number(role?.id))
            .join(",") || "",
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const updateGroupInfoRequestParams = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateGroupInfoPayload),
      };
      fetch(updateGroupInfoUrl, updateGroupInfoRequestParams)
        .then((res) => res.json())
        .then((data) => {
          setGroupDetail({
            ...groupDetail,
            associateUsers:
              groupDetail?.associateUsers?.map((user) => ({
                ...user,
                status: "Active",
              })) || [],
            assignedRoles:
              groupDetail?.assignedRoles?.map((role) => ({
                ...role,
                status: "Active",
              })) || [],
          });
          dispatch(
            setGroups(
              basicReducerState?.groups?.map((group) =>
                Number(group?.id) === Number(params?.groupId)
                  ? { ...group, name: groupDetail?.name }
                  : { ...group }
              ) || []
            )
          );
          setLoad(false);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Group details updated successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <Paper className={`${classes.groupDetailContainer} iagScroll`}>
      <Loading load={load} />

      <>
        <div className={classes.groupDetailCrossContainer}>
          <Close
            style={{ fontSize: 16, cursor: "pointer" }}
            onClick={() => {
              // history.push(`/groups`);
              setParams({});
            }}
          />
        </div>

        <div className={classes.groupDetailHeaderContainer}>
          <Typography
            className={`${classes.groupDetailHeaderItem} ${
              selectedGroupDetailContentType === "Basic Info" &&
              classes.groupDetailHeaderItemSelected
            }`}
            onClick={() => setSelectedGroupDetailContentType("Basic Info")}
          >
            Basic Info
          </Typography>

          <Typography
            className={`${classes.groupDetailHeaderItem} ${
              selectedGroupDetailContentType === "Associated Users" &&
              classes.groupDetailHeaderItemSelected
            }`}
            onClick={() =>
              setSelectedGroupDetailContentType("Associated Users")
            }
          >
            Associated Users
          </Typography>

          <Typography
            className={`${classes.groupDetailHeaderItem} ${
              selectedGroupDetailContentType === "Assigned Roles" &&
              classes.groupDetailHeaderItemSelected
            }`}
            onClick={() => setSelectedGroupDetailContentType("Assigned Roles")}
          >
            Assigned Roles
          </Typography>
        </div>

        {selectedGroupDetailContentType === "Basic Info" && (
          <GroupInfo
            groupDetail={groupDetail}
            setGroupDetail={setGroupDetail}
            params={params}
            load={load}
          />
        )}

        {selectedGroupDetailContentType === "Associated Users" && (
          <GroupAssociateUsers
            groupDetail={groupDetail}
            setGroupDetail={setGroupDetail}
            load={load}
            setLoad={setLoad}
            params={params}
          />
        )}

        {selectedGroupDetailContentType === "Assigned Roles" && (
          <GroupAssignedRoles
            groupDetail={groupDetail}
            setGroupDetail={setGroupDetail}
            load={load}
            setLoad={setLoad}
            params={params}
          />
        )}

        {userFeatures?.includes(features?.ADD_GROUPS) && (
          <div className={classes.groupDetailFooter}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.groupDetailFooterButton}
              onClick={updateGroupInfo}
              disabled={
                load ||
                basicReducerState?.groups?.find(
                  (group) =>
                    group?.name === groupDetail?.name &&
                    group?.applicationId === groupDetail?.applicationId &&
                    Number(group?.id) !== Number(params?.groupId)
                ) ||
                groupDetail?.name?.length === 0
              }
            >
              Submit
            </Button>
          </div>
        )}
      </>
    </Paper>
  );
}

export default GroupDetail;
