import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  //  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
// import {
//   allActivities,
//   allApplications,
//   allRoles,
//   allUsers,
//   selectedRoleDetail,
// } from "../../Data/data";
import {
  Add,
  Close,
  Delete,
  Remove,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@material-ui/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import { findEntityById, findRoleById, findApiByid } from "../../Utility/basic";
import Loading from "../Loading";
import { findApplicationById, findUserById } from "../../Utility/basic";
import { Autocomplete } from "@material-ui/lab";
import {
  setRoles,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import DeletionMessageBox from "../DeletionMessageBox";
import { applicationIds } from "../../Utility/config";
import { features } from "../../Data/data";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  buttonHeight,
  crossIconContainerHeight,
  roleDetailPageCss,
  rolePageHeaderHeight,
} from "../../Data/cssConstant";

const useStyle = makeStyles((theme) => ({
  roleInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    height: `calc(100vh - ${appHeaderHeight} - ${rolePageHeaderHeight} - ${crossIconContainerHeight} - ${roleDetailPageCss?.tabsContainerHeight} - ${roleDetailPageCss?.footerHeight} - 18px)`,
  },
  roleInfoItemContainer: {
    margin: 4,
    alignItems: "center",
  },
  roleInfoContainerLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  roleInfoContainerText: {
    fontSize: 12,
  },

  newRoleAssignedUserDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newRoleAssignedUserDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  roleAssignedUsersTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${rolePageHeaderHeight} - ${crossIconContainerHeight} - ${roleDetailPageCss?.tabsContainerHeight} - ${buttonHeight} - ${roleDetailPageCss?.footerHeight} - 26px)`,
    // overflow: "overlay",
    width: "100%",
  },
  roleAssignedUsersTableHead: {
    // backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: "#F1F5FE",
  },
  roleAssignedUsersTableHeadCell: {
    // fontWeight: 700,
    whiteSpace: "nowrap",
    // color: theme.palette.background.paper,
    // fontSize: 14,
    fontSize: 9,
    // lineHeight: 1.5,
    fontWeight: "bold",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  roleAssignedUsersTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  roleAssignedUsersTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  roleAssignedUsersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  roleAssignedUsersBottonAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },

  newRoleAssignedActivityDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newRoleAssignedActivityDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  roleAssignedActivitiesTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${rolePageHeaderHeight} - ${crossIconContainerHeight} - ${roleDetailPageCss?.tabsContainerHeight} - ${buttonHeight} - ${roleDetailPageCss?.footerHeight} - 26px)`,
    // overflow: "overlay",
    width: "100%",
  },
  roleAssignedActivitiesTableHead: {
    // backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: "#F1F5FE",
  },
  roleAssignedActivitiesTableHeadCell: {
    // fontWeight: 700,
    whiteSpace: "nowrap",
    // color: theme.palette.background.paper,
    // fontSize: 14,
    fontSize: 9,
    // lineHeight: 1.5,
    fontWeight: "bold",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  roleAssignedActivitiesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  roleAssignedActivitiesTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  roleAssignedActivitiesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  roleAssignedActivitiesBottonAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },

  roleDetailContainer: {
    flexDirection: "column",
    height: "100%",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.palette.background.paper,
    margin: 0,
    padding: 0,
    // overflow: "overlay",
    position: "relative",
  },
  roleDetailCrossButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 10,
    paddingTop: 10,
    height: crossIconContainerHeight,
  },
  roleDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    height: roleDetailPageCss?.tabsContainerHeight,
  },
  roleDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    width: 150,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  roleDetailHeaderItemSelected: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  roleDetailFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "0px 0px 9px #D8D8D8",
    padding: "8px 16px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 999,
    height: roleDetailPageCss?.footerHeight,
  },
  roleDetailFooterButton: {
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const RoleInfo = ({ roleDetail, setRoleDetail, params, load }) => {
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const classes = useStyle();
  // const params = useParams();

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name || "-";
  };

  return (
    <div className={classes.roleInfoContainer}>
      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Name
          </Typography>
        </Grid>

        <Grid item xs={6}>
          {roleDetail?.applicationId !== applicationIds?.SAP_BTP ? (
            <>
              <div
                className={`inputContainer ${
                  (roleDetail?.name?.length === 0 ||
                    basicReducerState?.roles?.find(
                      (role) =>
                        role?.name?.toLowerCase() ===
                          roleDetail?.name?.toLowerCase() &&
                        role?.applicationId === roleDetail?.applicationId &&
                        Number(role?.id) !== Number(params?.roleId)
                    )) &&
                  !load &&
                  "inputError"
                }`}
              >
                <input
                  className={`${classes.roleInfoContainerText} ${
                    userReducerState.darkMode && "inputDarkMode"
                  }`}
                  value={roleDetail?.name}
                  disabled={!userFeatures?.includes(features?.ADD_ROLE_DETAILS)}
                  onChange={(e) =>
                    setRoleDetail({ ...roleDetail, name: e.target.value })
                  }
                />
              </div>

              {basicReducerState?.roles?.find(
                (role) =>
                  role?.name?.toLowerCase() ===
                    roleDetail?.name?.toLowerCase() &&
                  role?.applicationId === roleDetail?.applicationId &&
                  Number(role?.id) !== Number(params?.roleId)
              ) &&
                !load && (
                  <p style={{ color: "red", fontSize: 10, marginTop: 3 }}>
                    Role name already exists
                  </p>
                )}

              {roleDetail?.name?.length === 0 && !load && (
                <p style={{ color: "red", fontSize: 10, marginTop: 3 }}>
                  Please fill it
                </p>
              )}
            </>
          ) : (
            <Typography className={classes.roleInfoContainerText}>
              {roleDetail?.name}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            {roleDetail?.applicationId === applicationIds?.SAP_BTP
              ? "Role Template"
              : "Label"}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          {roleDetail?.applicationId !== applicationIds?.SAP_BTP ? (
            <>
              <div className={`inputContainer`}>
                <input
                  className={`${classes.roleInfoContainerText} ${
                    userReducerState.darkMode && "inputDarkMode"
                  }`}
                  value={roleDetail?.label}
                  disabled={!userFeatures?.includes(features?.ADD_ROLE_DETAILS)}
                  onChange={(e) => {
                    if (
                      roleDetail?.applicationId === applicationIds.SAP_BTP &&
                      !roleDetail?.isComposite
                    ) {
                      return;
                    }
                    setRoleDetail({ ...roleDetail, label: e.target.value });
                  }}
                />
              </div>

              {roleDetail?.label?.length === 0 && !load && (
                <p style={{ color: "red", fontSize: 10, marginTop: 3 }}>
                  Please fill it
                </p>
              )}
            </>
          ) : (
            <Typography className={classes.roleInfoContainerText}>
              {roleDetail?.label}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Description
          </Typography>
        </Grid>

        <Grid item xs={6}>
          {roleDetail?.applicationId !== applicationIds?.SAP_BTP ? (
            <>
              <div className={`inputContainer`}>
                <input
                  className={`${classes.roleInfoContainerText} ${
                    userReducerState.darkMode && "inputDarkMode"
                  }`}
                  value={roleDetail?.description}
                  disabled={!userFeatures?.includes(features?.ADD_ROLE_DETAILS)}
                  onChange={(e) => {
                    if (
                      roleDetail?.applicationId === applicationIds.SAP_BTP &&
                      !roleDetail?.isComposite
                    ) {
                      return;
                    }
                    setRoleDetail({
                      ...roleDetail,
                      description: e.target.value,
                    });
                  }}
                />
              </div>

              {roleDetail?.description?.length === 0 && !load && (
                <p style={{ color: "red", fontSize: 10, marginTop: 3 }}>
                  Please fill it
                </p>
              )}
            </>
          ) : (
            <Typography className={classes.roleInfoContainerText}>
              {roleDetail?.description}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Application
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {getApplicationNameById(roleDetail?.applicationId)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Type
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.isComposite === 1 ? "Composite" : "Single"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Status
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.status}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            No of Expiry Days
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.noOfExpiryDays}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.roleInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.roleInfoContainerLabel}>
            Expiry Mail Trigger Days
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.roleInfoContainerText}>
            {roleDetail?.expiryMailTriggerDays}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const NewRoleAssociateRole = ({
  open,
  onClose,
  roleDetail,
  setRoleDetail,
  params: { roleId },
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [newAssociateRoles, setNewAssociateRoles] = useState([]);

  const presentRole = (roleId) => {
    return roleDetail?.associateRoles?.filter(
      (role) => Number(role?.id) === Number(roleId)
    ).length > 0
      ? true
      : false;
  };
  // console.log(roleDetail.associateRoles);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleAssignedActivityDialogTitle}>
        New Associate Role
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          disableCloseOnSelect
          filterSelectedOptions
          value={newAssociateRoles}
          onChange={(e, roles) => {
            setNewAssociateRoles(roles || []);
          }}
          options={basicReducerState?.roles?.filter(
            (role) =>
              role?.isComposite !== 1 &&
              role?.applicationId === roleDetail?.applicationId &&
              !presentRole(role?.id)
          )}
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option.name}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Associate Role"
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.newRoleAssignedActivityDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            setNewAssociateRoles([]);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setRoleDetail({
              ...roleDetail,
              associateRoles: [
                ...roleDetail?.associateRoles,
                ...newAssociateRoles?.map((role) => ({
                  ...role,
                  status: "Draft",
                })),
              ],
            });
            onClose();
            setNewAssociateRoles([]);
          }}
          style={{ textTransform: "capitalize" }}
          disabled={newAssociateRoles?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoleAssociateRoles = ({
  roleDetail,
  setRoleDetail,
  getRoleInfoById,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const userReducerState = useSelector((state) => state.userReducer);
  // const params = useParams();
  // const [load, setLoad] = useState(false);
  const [openAssociateRoleDialog, setOpenAssociateRoleDialog] = useState(false);
  const [deletingAssociateRole, setDeletingAssociateRole] = useState(null);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });

  const deleteAssociateRole = (associateRole) => {
    if (associateRole?.status === "Draft") {
      setRoleDetail({
        ...roleDetail,
        associateRoles: roleDetail?.associateRoles?.filter(
          (role) => Number(role?.id) !== Number(associateRole?.id)
        ),
      });
    } else {
      if (authVerify(userReducerState.token)) {
        const updateRoleAssociateRoleUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles/modify`;
        const updateRoleAssociateRolePayload = {
          applicationId: roleDetail?.applicationId,
          associateRoles:
            roleDetail?.associateRoles
              ?.filter((role) => Number(role?.id) !== Number(associateRole?.id))
              .map((role) => role?.id)
              ?.join(",") || "",
          createdBy: roleDetail?.createdBy,
          createdOn: moment(new Date(roleDetail?.createdOn)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          description: roleDetail?.description,
          expiryMailTriggerDays: roleDetail?.expiryMailTriggerDays,
          hasExpiry: roleDetail?.hasExpiry,
          id: Number(params?.roleId),
          isActive: 1,
          isComposite: roleDetail?.isComposite,
          isDeleted: 0,
          label: roleDetail?.label,
          name: roleDetail?.name,
          noOfExpiryDays: roleDetail?.noOfExpiryDays,
          status: "Active",
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        const updateRoleAssociateRoleRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(updateRoleAssociateRolePayload),
        };
        setLoad(true);
        fetch(updateRoleAssociateRoleUrl, updateRoleAssociateRoleRequestParam)
          .then((res) => res.json())
          .then((data) => {
            getRoleInfoById();
            // setRoleDetail({
            //   ...roleDetail,
            //   associateRoles:
            //     roleDetail?.associateRoles
            //       ?.filter(
            //         (role) => Number(role?.id) !== Number(associateRole?.id)
            //       )
            //       .map((role) => role?.id)
            //       ?.join(",") || "",
            // });
            setLoad(false);
            setDeletingAssociateRole(null);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.status
                  ? "Asociated role deleted successfully"
                  : "Something went wrong",
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };

  return (
    <>
      <Loading load={load} />

      <NewRoleAssociateRole
        open={openAssociateRoleDialog}
        onClose={() => setOpenAssociateRoleDialog(false)}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        params={params}
      />

      <DeletionMessageBox
        open={deletingAssociateRole ? true : false}
        onClose={() => setDeletingAssociateRole(null)}
        onDelete={() => {
          deleteAssociateRole(deletingAssociateRole);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.roleAssignedActivitiesTableContainer} iagScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.roleAssignedActivitiesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Role Name
              </TableCell>

              {userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
                <TableCell
                  align="center"
                  className={classes.roleAssignedActivitiesTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.roleAssignedActivitiesTableBody} iagScroll`}
          >
            {roleDetail?.associateRoles
              ?.filter(
                (associateRole) => associateRole && associateRole !== "null"
              )
              ?.map((associateRole, index) => {
                return (
                  <TableRow
                    key={`${associateRole?.id}-${index}`}
                    className={classes.roleAssignedActivitiesTableBodyRow}
                  >
                    <TableCell
                      // align="center"
                      className={classes.roleAssignedActivitiesTableBodyCell}
                    >
                      {associateRole?.name}
                    </TableCell>

                    {userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
                      <TableCell
                        align="center"
                        className={classes.roleAssignedActivitiesTableBodyCell}
                      >
                        <Tooltip
                          title={
                            associateRole?.status === "Draft"
                              ? "Remove"
                              : "Delete"
                          }
                        >
                          <IconButton
                            color="secondary"
                            onClick={(e) => {
                              e.stopPropagation();
                              // deleteAssociateRole(associateRole);
                              setDeletingAssociateRole(associateRole);
                            }}
                            disabled={load}
                          >
                            {associateRole?.status === "Draft" ? (
                              <Remove style={{ fontSize: 16 }} />
                            ) : (
                              <Delete style={{ fontSize: 16 }} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.roleAssignedActivitiesBottonAddButton}
          onClick={() => setOpenAssociateRoleDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NewRoleAssignedUser = ({
  open,
  onClose,
  roleDetail,
  setRoleDetail,
  params: { roleId },
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const initialNewAssignedUser = {
    userEmail: "",
    permissionType: "",
    isActive: 1,
    isEdited: 0,
    roleId: Number(roleId),
    associationType: "ROLE",
    createdBy: userReducerState?.user?.email,
    createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isDeleted: 0,
    status: "Draft",
    updatedBy: userReducerState?.user?.email,
    updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isGroupRole: 0,
    groupRole: "",
  };
  const assignedUsersId = roleDetail?.mappedUsers?.map(
    (assignedUser) => assignedUser?.userEmail
  );
  const [newAssignedUser, setNewAssignedUser] = useState([]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleAssignedUserDialogTitle}>
        New Assigned User
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          disableCloseOnSelect
          filterSelectedOptions
          value={newAssignedUser}
          onChange={(e, users) => {
            setNewAssignedUser(users || []);
          }}
          options={basicReducerState?.users?.filter(
            (user) => !assignedUsersId?.includes(user?.emailId)
          )}
          getOptionLabel={(option) => option.displayName}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>
                {option.displayName}
              </Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Users"
            />
          )}
        />

        {/* <FormControl
          variant="standard"
          size="small"
          fullWidth
          required
          // style={{ margin: "2px 0px" }}
        >
          <InputLabel>User</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAssignedUser?.userId}
            onChange={(e) =>
              setNewAssignedUser({
                ...newAssignedUser,
                userId: e.target.value,
              })
            }
          >
            {basicReducerState?.users?.map(
              (user, index) =>
                !assignedUsersId?.includes(user?.id) && (
                  <MenuItem
                    key={`${user?.id}-${index}`}
                    value={user?.id}
                    style={{ fontSize: 12 }}
                  >
                    {user?.displayName}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl> */}
      </DialogContent>

      <DialogActions className={classes.newRoleAssignedUserDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            // setNewAssignedUser(initialNewAssignedUser);
            setNewAssignedUser([]);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          // onClick={() => {
          //   setRoleDetail({
          //     ...roleDetail,
          //     mappedUsers: [newAssignedUser, ...roleDetail?.mappedUsers],
          //   });
          //   onClose();
          //   setNewAssignedUser(initialNewAssignedUser);
          // }}
          onClick={() => {
            const newMappedUsers = newAssignedUser?.map((user) => ({
              ...initialNewAssignedUser,
              userEmail: user?.emailId,
            }));
            setRoleDetail({
              ...roleDetail,
              mappedUsers: [...newMappedUsers, ...roleDetail?.mappedUsers],
            });
            onClose();
            setNewAssignedUser([]);
          }}
          style={{ textTransform: "capitalize" }}
          // disabled={
          //   newAssignedUser?.userId?.length === 0 ||
          //   roleDetail?.mappedUsers?.filter(
          //     (assignedUser) => assignedUser?.userId === newAssignedUser?.userId
          //   )?.length > 0
          // }
          disabled={newAssignedUser?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoleAssignedUsers = ({
  roleDetail,
  setRoleDetail,
  load,
  setLoad,
  params: { roleId },
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [openAssignUserDialog, setOpenAssignUserDialog] = useState(false);
  const [deletingAssignedUser, setDeletingAssignedUser] = useState(null);

  const deleteAssignedUser = (assignedUser) => {
    if (assignedUser?.status === "Draft") {
      setRoleDetail({
        ...roleDetail,
        mappedUsers:
          roleDetail?.mappedUsers?.filter(
            (user) => !(user?.emailId === assignedUser?.userEmail)
          ) || [],
      });
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableRoleUserMappingUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userRoleMapping`;
        const disableRoleUserMappingPayload = {
          roleId: assignedUser?.roleId,
          createdBy: assignedUser?.createdBy,
          createdOn: moment(new Date(assignedUser?.createdOn)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          isActive: 0,
          isDeleted: 1,
          status: "Inactive",
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          userEmail: assignedUser?.userEmail,
          isGroupRole: assignedUser?.isGroupRole,
          groupRole: assignedUser?.groupRole,
        };
        const disableRoleUserMappingRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(disableRoleUserMappingPayload),
        };
        fetch(disableRoleUserMappingUrl, disableRoleUserMappingRequestParam)
          .then((res) => res.json())
          .then((data) => {
            setRoleDetail({
              ...roleDetail,
              mappedUsers:
                roleDetail?.mappedUsers?.filter(
                  (user) => !(user?.userEmail === assignedUser?.userEmail)
                ) || [],
            });
            setLoad(false);
            setDeletingAssignedUser(null);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.status
                  ? "Assigned user to role deleted successfully"
                  : "Something went wrong",
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };
  const getUserNameById = (userEmail) => {
    const user = findUserById(userEmail, basicReducerState.users);
    return user?.displayName || "-";
  };

  return (
    <>
      <NewRoleAssignedUser
        open={openAssignUserDialog}
        onClose={() => setOpenAssignUserDialog(false)}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        params={{ roleId: roleId }}
      />

      <DeletionMessageBox
        open={deletingAssignedUser ? true : false}
        onClose={() => setDeletingAssignedUser(null)}
        onDelete={() => {
          deleteAssignedUser(deletingAssignedUser);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.roleAssignedUsersTableContainer} iagScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.roleAssignedUsersTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.roleAssignedUsersTableHeadCell}
              >
                User
              </TableCell>

              <TableCell
                // align="center"
                className={classes.roleAssignedUsersTableHeadCell}
              >
                Status
              </TableCell>

              {userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
                <TableCell
                  align="center"
                  className={classes.roleAssignedUsersTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.roleAssignedUsersTableBody} iagScroll`}
          >
            {roleDetail?.mappedUsers?.map((assignedUser, index) => {
              return (
                <TableRow
                  key={`${assignedUser?.id}-${index}`}
                  className={classes.roleAssignedUsersTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.roleAssignedUsersTableBodyCell}
                  >
                    {/* <Select
                        size="small"
                        style={{ fontSize: 12 }}
                        value={assignedUser?.userEmail}
                        readOnly
                      >
                        {basicReducerState?.users?.map((user, index) => (
                          <MenuItem
                            key={`${user?.id}-${index}`}
                            value={user?.id}
                            style={{ fontSize: 12 }}
                          >
                            {user?.displayName}
                          </MenuItem>
                        ))}
                      </Select> */}
                    {assignedUser?.status === "Draft"
                      ? getUserNameById(assignedUser?.userEmail)
                      : getUserNameById(assignedUser?.userEmail)}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.roleAssignedUsersTableBodyCell}
                  >
                    {assignedUser?.status}
                  </TableCell>

                  {userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
                    <TableCell
                      align="center"
                      className={classes.roleAssignedUsersTableBodyCell}
                    >
                      <Tooltip
                        title={
                          assignedUser?.status === "Draft" ? "Remove" : "Delete"
                        }
                      >
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteAssignedUser(assignedUser);
                            setDeletingAssignedUser(assignedUser);
                          }}
                          disabled={
                            load ||
                            !userFeatures?.includes(features?.ADD_ROLE_DETAILS)
                          }
                        >
                          {assignedUser?.status === "Draft" ? (
                            <Remove style={{ fontSize: 16 }} />
                          ) : (
                            <Delete style={{ fontSize: 16 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.roleAssignedUsersBottonAddButton}
          onClick={() => setOpenAssignUserDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NewRoleAssignedActivity = ({
  open,
  onClose,
  roleDetail,
  setRoleDetail,
  params: { roleId },
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const initialNewAssignedActivity = {
    isActive: 1,
    activityId: "",
    createdBy: userReducerState?.user?.email,
    createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isDeleted: 0,
    roleId: Number(roleId),
    status: "Draft",
    updatedBy: userReducerState?.user?.email,
    updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  };
  // const [newAssignedActivity, setNewAssignedActivity] = useState(
  //   initialNewAssignedActivity
  // );
  const [newAssignedActivity, setNewAssignedActivity] = useState([]);
  const [entitiesId, setEntitiesId] = useState([]);

  useEffect(() => {
    setEntitiesId(getEntitiesIdByApplicationId());
  }, [roleDetail?.mappedActivities]);
  const presentAssignedActivities = (activityName) => {
    return roleDetail?.mappedActivities?.filter(
      (assignedActivity) => assignedActivity?.name === activityName
    ).length > 0
      ? true
      : false;
  };
  const getEntityNameById = (entityId) => {
    const entity = findEntityById(Number(entityId), basicReducerState.entities);
    return entity?.name || "-";
  };
  const getEntitiesIdByApplicationId = () => {
    const entitiesList =
      basicReducerState?.entities?.filter(
        (entity) =>
          entity?.applicationId === roleDetail?.applicationId && entity?.id
      ) || [];
    const entitiesIdList = entitiesList.map((entity) => entity.id);
    return entitiesIdList;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleAssignedActivityDialogTitle}>
        New Assigned Feature
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          disableCloseOnSelect
          filterSelectedOptions
          disableListWrap
          value={newAssignedActivity}
          onChange={(e, activities) => {
            setNewAssignedActivity(activities || []);
          }}
          options={basicReducerState?.activities?.filter(
            (activity) =>
              entitiesId.includes(activity?.entityId) &&
              !presentAssignedActivities(activity?.name)
          )}
          groupBy={(option) => getEntityNameById(option?.entityId)}
          getOptionLabel={(option) =>
            `${option?.name} (${getEntityNameById(option?.entityId)})`
          }
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>
                {`${option?.name} (${getEntityNameById(option?.entityId)})`}
              </Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Features"
            />
          )}
        />

        {/* <FormControl
          variant="standard"
          size="small"
          fullWidth
          required
          // style={{ margin: "2px 0px" }}
        >
          <InputLabel>Features</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAssignedActivity?.activityId}
            onChange={(e) =>
              setNewAssignedActivity({
                ...newAssignedActivity,
                activityId: e.target.value,
              })
            }
          >
            {basicReducerState?.activities?.map(
              (activity, index) =>
                entitiesId.includes(activity?.entityId) &&
                !presentAssignedActivities(activity?.name) && (
                  <MenuItem
                    key={`${activity?.id}-${index}`}
                    value={activity?.id}
                    style={{ fontSize: 12 }}
                  >
                    {`${activity?.name} (${getEntityNameById(
                      activity?.entityId
                    )})`}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl> */}
      </DialogContent>

      <DialogActions className={classes.newRoleAssignedActivityDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            // setNewAssignedActivity(initialNewAssignedActivity);
            setNewAssignedActivity([]);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          // onClick={() => {
          //   setRoleDetail({
          //     ...roleDetail,
          //     mappedActivities: [
          //       newAssignedActivity,
          //       ...roleDetail.mappedActivities,
          //     ],
          //   });
          //   onClose();
          //   setNewAssignedActivity(initialNewAssignedActivity);
          // }}
          onClick={() => {
            const newmappedActivity = newAssignedActivity?.map((activity) => ({
              ...initialNewAssignedActivity,
              activityId: activity?.id,
            }));
            setRoleDetail({
              ...roleDetail,
              mappedActivities: [
                ...newmappedActivity,
                ...roleDetail.mappedActivities,
              ],
            });
            onClose();
            setNewAssignedActivity([]);
          }}
          style={{ textTransform: "capitalize" }}
          // disabled={
          //   newAssignedActivity?.activityId?.length === 0 ||
          //   roleDetail?.mappedActivities?.filter(
          //     (assignedActivity) =>
          //       assignedActivity?.activityId === newAssignedActivity?.activityId
          //   )?.length > 0
          // }
          disabled={newAssignedActivity?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoleAssignedActivities = ({
  roleDetail,
  setRoleDetail,
  load,
  setLoad,
  params: { roleId },
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [openAssignActivityDialog, setOpenAssignActivityDialog] =
    useState(false);
  const [deletingAssignedActivity, setDeletingAssignedActivity] =
    useState(null);
  // const location = useLocation();
  // const pathname = location.pathname.split("/");
  // const { roleId } = useParams();

  const deleteAssignedActivity = (assignedActivity) => {
    if (assignedActivity.status === "Draft") {
      setRoleDetail({
        ...roleDetail,
        mappedActivities:
          roleDetail?.mappedActivities?.filter(
            (activity) =>
              !(activity?.activityId === assignedActivity?.activityId)
          ) || [],
      });
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableRoleMappedctivityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles/activityRoleMapping/modify`;
        // console.log(assignedActivity);
        const disableRoleMappedctivityPayload = {
          activityId: assignedActivity?.id,
          createdBy: assignedActivity?.createdBy,
          createdOn: moment(new Date(assignedActivity?.createdOn)).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          isActive: 0,
          isDeleted: 1,
          status: "Inactive",
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          roleId: Number(roleId),
        };
        const disableRoleMappedctivityRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(disableRoleMappedctivityPayload),
        };
        fetch(disableRoleMappedctivityUrl, disableRoleMappedctivityRequestParam)
          .then((res) => res.json())
          .then((data) => {
            setRoleDetail({
              ...roleDetail,
              mappedActivities:
                roleDetail?.mappedActivities?.filter(
                  (activity) => activity?.id !== assignedActivity?.id
                ) || [],
            });
            setLoad(false);
            setDeletingAssignedActivity(null);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.status
                  ? "Assigned activity to role deleted successfully"
                  : "Something went wrong",
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };
  const getEntityNameById = (entityId) => {
    const entity = findEntityById(Number(entityId), basicReducerState.entities);
    return entity?.name || "-";
  };

  return (
    <>
      <NewRoleAssignedActivity
        open={openAssignActivityDialog}
        onClose={() => setOpenAssignActivityDialog(false)}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        params={{ roleId: roleId }}
      />

      <DeletionMessageBox
        open={deletingAssignedActivity ? true : false}
        onClose={() => setDeletingAssignedActivity(null)}
        onDelete={() => {
          deleteAssignedActivity(deletingAssignedActivity);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.roleAssignedActivitiesTableContainer} iagScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.roleAssignedActivitiesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Feature
              </TableCell>

              <TableCell
                // align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Status
              </TableCell>

              {roleDetail?.isComposite !== 1 &&
                userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
                  <TableCell
                    align="center"
                    className={classes.roleAssignedActivitiesTableHeadCell}
                  >
                    Action
                  </TableCell>
                )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.roleAssignedActivitiesTableBody} iagScroll`}
          >
            {roleDetail?.mappedActivities?.map((assignedActivity, index) => {
              return (
                <TableRow
                  key={`${assignedActivity?.id}-${index}`}
                  className={classes.roleAssignedActivitiesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    {assignedActivity?.status === "Draft" ? (
                      <Select
                        size="small"
                        style={{ fontSize: 12 }}
                        value={assignedActivity?.activityId}
                        readOnly
                      >
                        {basicReducerState?.activities.map(
                          (activity, index) => (
                            <MenuItem
                              key={`${activity?.id}-${index}`}
                              value={activity?.id}
                              style={{ fontSize: 12 }}
                            >
                              {`${activity?.name} (${getEntityNameById(
                                activity?.entityId
                              )})`}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    ) : (
                      `${assignedActivity?.name} (${getEntityNameById(
                        assignedActivity?.entityId
                      )})`
                    )}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    {assignedActivity?.status}
                  </TableCell>

                  {roleDetail?.isComposite !== 1 &&
                    userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
                      <TableCell
                        align="center"
                        className={classes.roleAssignedActivitiesTableBodyCell}
                      >
                        <Tooltip
                          title={
                            assignedActivity?.status === "Draft"
                              ? "Remove"
                              : "Delete"
                          }
                        >
                          <IconButton
                            color="secondary"
                            onClick={(e) => {
                              e.stopPropagation();
                              // deleteAssignedActivity(assignedActivity);
                              setDeletingAssignedActivity(assignedActivity);
                            }}
                            disabled={
                              load ||
                              !userFeatures?.includes(
                                features?.ADD_ROLE_DETAILS
                              )
                            }
                          >
                            {assignedActivity?.status === "Draft" ? (
                              <Remove style={{ fontSize: 16 }} />
                            ) : (
                              <Delete style={{ fontSize: 16 }} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {roleDetail?.isComposite !== 1 &&
        userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.roleAssignedActivitiesBottonAddButton}
            onClick={() => setOpenAssignActivityDialog(true)}
            startIcon={<Add />}
            disabled={load}
          >
            Add
          </Button>
        )}
    </>
  );
};

const NewRoleAssignedApi = ({
  open,
  onClose,
  roleDetail,
  setRoleDetail,
  params: { roleId },
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  // const { roleId } = useParams();
  const initialNewAssignedApi = {
    isActive: 1,
    apiId: "",
    createdBy: userReducerState?.user?.email,
    createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isDeleted: 0,
    roleId: Number(roleId),
    status: "Draft",
    updatedBy: userReducerState?.user?.email,
    updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  };
  // const [newAssignedApi, setNewAssignedApi] = useState(initialNewAssignedApi);
  const [newAssignedApi, setNewAssignedApi] = useState([]);
  const assignedApisId =
    roleDetail?.mappedApis?.map((assignedApi) => assignedApi?.apiId) || [];

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newRoleAssignedActivityDialogTitle}>
        New Assign Api
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          disableCloseOnSelect
          filterSelectedOptions
          value={newAssignedApi}
          onChange={(e, apis) => {
            setNewAssignedApi(apis || []);
          }}
          options={basicReducerState?.apis?.filter(
            (api) =>
              !assignedApisId?.includes(api?.id) &&
              Number(api?.applicationId) === Number(roleDetail?.applicationId)
          )}
          getOptionLabel={(option) => option?.name}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option?.name}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Features"
            />
          )}
        />
        {/* <FormControl
          variant="standard"
          size="small"
          fullWidth
          required
          // style={{ margin: "2px 0px" }}
        >
          <InputLabel>Api</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAssignedApi?.apiId}
            onChange={(e) =>
              setNewAssignedApi({
                ...newAssignedApi,
                apiId: e.target.value,
              })
            }
          >
            {basicReducerState?.apis?.map(
              (api, index) =>
                !assignedApisId?.includes(api?.id) &&
                Number(api?.applicationId) ===
                  Number(roleDetail?.applicationId) && (
                  <MenuItem
                    key={`${api?.id}-${index}`}
                    value={api?.id}
                    style={{ fontSize: 12 }}
                  >
                    {api?.name}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl> */}
      </DialogContent>

      <DialogActions className={classes.newRoleAssignedActivityDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            // setNewAssignedApi(initialNewAssignedApi);
            setNewAssignedApi([]);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          // onClick={() => {
          //   setRoleDetail({
          //     ...roleDetail,
          //     mappedApis: [newAssignedApi, ...roleDetail?.mappedApis],
          //   });
          //   onClose();
          //   setNewAssignedApi(initialNewAssignedApi);
          // }}
          onClick={() => {
            const newMappedApis = newAssignedApi?.map((api) => ({
              ...initialNewAssignedApi,
              apiId: api?.id,
            }));
            setRoleDetail({
              ...roleDetail,
              mappedApis: [...newMappedApis, ...roleDetail?.mappedApis],
            });
            onClose();
            setNewAssignedApi([]);
          }}
          style={{ textTransform: "capitalize" }}
          // disabled={newAssignedApi?.apiId?.length === 0}
          disabled={newAssignedApi?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoleAssignedApis = ({
  roleDetail,
  setRoleDetail,
  load,
  setLoad,
  params: { roleId },
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [openAssignApiDialog, setOpenAssignApiDialog] = useState(false);
  const [deletingAssignedApi, setDeletingAssignedApi] = useState(null);
  // const { roleId } = useParams();
  // console.log(roleDetail?.mappedApis);

  const deleteAssignedApi = (assignedApi) => {
    if (assignedApi?.status === "Draft") {
      setRoleDetail({
        ...roleDetail,
        mappedApis:
          roleDetail?.mappedApis?.filter(
            (api) => !(api?.apiId === assignedApi?.apiId)
          ) || [],
      });
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableRoleMappedctivityUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/roleApiMapping/deactivate?roleId=${assignedApi?.roleId}&apiId=${assignedApi.apiId}`;
        const disableRoleMappedctivityRequestParam = {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
        };
        fetch(disableRoleMappedctivityUrl, disableRoleMappedctivityRequestParam)
          .then((res) => res.json())
          .then((data) => {
            setRoleDetail({
              ...roleDetail,
              mappedApis:
                roleDetail?.mappedApis?.filter(
                  (api) => api?.apiId !== assignedApi?.apiId
                ) || [],
            });
            setLoad(false);
            setDeletingAssignedApi(null);

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.status
                  ? "Assigned api to role deleted successfully"
                  : "Something went wrong",
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };
  const getApiNameByid = (apiId) => {
    const api = findApiByid(Number(apiId), basicReducerState?.apis);
    return api?.name || "-";
  };

  return (
    <>
      <NewRoleAssignedApi
        open={openAssignApiDialog}
        onClose={() => setOpenAssignApiDialog(false)}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        params={{ roleId: roleId }}
      />

      <DeletionMessageBox
        open={deletingAssignedApi ? true : false}
        onClose={() => setDeletingAssignedApi(null)}
        onDelete={() => {
          deleteAssignedApi(deletingAssignedApi);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.roleAssignedActivitiesTableContainer} iagScroll`}
      >
        <Table
          size="small"
          //   className="table-sort table-arrows"
        >
          <TableHead className={classes.roleAssignedActivitiesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.roleAssignedActivitiesTableHeadCell}
              >
                Api
              </TableCell>

              {roleDetail?.isComposite !== 1 &&
                userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
                  <TableCell
                    align="center"
                    className={classes.roleAssignedActivitiesTableHeadCell}
                  >
                    Action
                  </TableCell>
                )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.roleAssignedActivitiesTableBody} iagScroll`}
          >
            {roleDetail?.mappedApis?.map((assignedApi, index) => {
              return (
                <TableRow
                  key={`${assignedApi?.id}-${index}`}
                  className={classes.roleAssignedActivitiesTableBodyRow}
                >
                  <TableCell
                    className={classes.roleAssignedActivitiesTableBodyCell}
                  >
                    {getApiNameByid(assignedApi?.apiId)}
                  </TableCell>

                  {userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
                    <TableCell
                      align="center"
                      className={classes.roleAssignedActivitiesTableBodyCell}
                    >
                      <Tooltip
                        title={
                          assignedApi?.status === "Draft" ? "Remove" : "Delete"
                        }
                      >
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteAssignedApi(assignedApi);
                            setDeletingAssignedApi(assignedApi);
                          }}
                          disabled={load}
                        >
                          {assignedApi?.status === "Draft" ? (
                            <Remove style={{ fontSize: 16 }} />
                          ) : (
                            <Delete style={{ fontSize: 16 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {roleDetail?.isComposite !== 1 &&
        userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.roleAssignedActivitiesBottonAddButton}
            onClick={() => setOpenAssignApiDialog(true)}
            startIcon={<Add />}
            disabled={
              load || !userFeatures?.includes(features?.ADD_ROLE_DETAILS)
            }
          >
            Add
          </Button>
        )}
    </>
  );
};

function RolesDetail({ params: { roleId }, setParams }) {
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const classes = useStyle();
  const [selectedRoleDetailContentType, setSelectedRoleDetailContentType] =
    useState("Basic Info");
  const [load, setLoad] = useState(false);
  const [roleDetail, setRoleDetail] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (roleId) {
      getRoleInfoById();
    }
  }, [roleId]);

  const getRoleInfoById = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const getRoleByIdUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles/byRoleId?id=${roleId}`;
      const getRoleByIdRequestParam = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      const getRoleMappedUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles/userRoleMapping/byRoleId?roleId=${roleId}`;
      const getRoleMappedUsersRequestParam = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      const getRoleMappedApisUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/roleApiMapping/byRoleId?roleId=${roleId}`;
      const getRoleMappedApisRequestParam = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      Promise.all([
        fetch(getRoleByIdUrl, getRoleByIdRequestParam).then((res) =>
          res.json()
        ),
        fetch(getRoleMappedUsersUrl, getRoleMappedUsersRequestParam).then(
          (res) => res.json()
        ),
        // fetch(
        //   getRoleMappedActivitiesUrl,
        //   getRoleMappedActivitiesRequestParam
        // ).then((res) => res.json()),
        fetch(getRoleMappedApisUrl, getRoleMappedApisRequestParam).then((res) =>
          res.json()
        ),
      ])
        .then(([roleDetail, roleMappedUsersDetail, roleMappedApisDetail]) => {
          const getRoleMappedActivitiesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/activities/role`;
          var roleIdList = [];
          // const roleDet = findRoleById(
          //   Number(roleId),
          //   basicReducerState?.roles
          // );
          if (roleDetail?.data?.isComposite === 1) {
            const rolesIdList =
              roleDetail?.data?.associateRoles?.split(",") || [];
            rolesIdList
              ?.filter((id) => id?.length > 0)
              ?.map((id) => {
                if (id !== null && id !== "null") {
                  roleIdList.push(id);
                }
                return null;
              });
          } else {
            roleIdList.push(roleId);
          }
          const getRoleMappedActivitiesPayload = {
            roleId: roleIdList,
          };
          const getRoleMappedActivitiesRequestParam = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userReducerState.token}`,
              applicationId: process.env.REACT_APP_IWAId,
            },
            body: JSON.stringify(getRoleMappedActivitiesPayload),
          };
          fetch(getRoleMappedActivitiesUrl, getRoleMappedActivitiesRequestParam)
            .then((res) => res.json())
            .then((roleMappedActivitiesDetail) => {
              setLoad(false);
              const associateRolesId =
                roleDetail?.data?.associateRoles?.length === 0
                  ? []
                  : roleDetail?.data?.associateRoles
                      ?.split(",")
                      .map((id) => Number(id));
              setRoleDetail({
                ...roleDetail?.data,
                associateRoles:
                  associateRolesId
                    ?.map((roleId) =>
                      findRoleById(roleId, basicReducerState?.roles)
                    )
                    ?.filter((role) => role && role !== "null") || [],
                mappedUsers: roleMappedUsersDetail?.data || [],
                mappedActivities: roleMappedActivitiesDetail?.data || [],
                mappedApis: roleMappedApisDetail?.data || [],
              });
              dispatch(
                setRoles(
                  basicReducerState?.roles?.map((role) =>
                    Number(role?.id) === Number(roleId)
                      ? {
                          ...role,
                          name: roleDetail?.data?.name,
                          label: roleDetail?.data?.label,
                          description: roleDetail?.data?.description,
                        }
                      : role
                  )
                )
              );
              setSelectedRoleDetailContentType("Basic Info");
            })
            .catch((err) => {
              setLoad(false);
            });
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const updateRoleInfo = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateRoleUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles/modify`;
      const updateRolePayload = {
        updatedBy: userReducerState?.user?.email,
        description: roleDetail?.description,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        label: roleDetail?.label,
        isActive: 1,
        createdOn: moment(new Date(roleDetail?.createdOn)).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        noOfExpiryDays: roleDetail?.noOfExpiryDays,
        isDeleted: 0,
        hasExpiry: roleDetail?.hasExpiry ? 1 : 0,
        expiryMailTriggerDays: roleDetail?.expiryMailTriggerDays,
        createdBy: roleDetail?.createdBy,
        name: roleDetail?.name,
        id: Number(roleId),
        applicationId: roleDetail?.applicationId,
        status: "Active",
        isComposite: roleDetail?.isComposite,
        associateRoles: roleDetail?.associateRoles
          ?.map((role) => role?.id)
          ?.join(","),
      };
      const updateRoleRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateRolePayload),
      };
      const updatedMappedUsersAndActivitiesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles/mapActivitiesAndUsers`;
      const updateMappedUsersAndActivitiesPayload = {
        userRoleMappings:
          roleDetail?.mappedUsers?.filter(
            (mappedUser) => mappedUser?.status === "Draft"
          ) || [],
        activityRoleMappings:
          roleDetail?.mappedActivities?.filter(
            (mappedActivity) => mappedActivity?.status === "Draft"
          ) || [],
      };
      const updateMappedUsersAndActivitiesRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateMappedUsersAndActivitiesPayload),
      };
      const updateMappedApisUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/roleApiMapping`;
      const updateMappedApisPayload =
        roleDetail?.mappedApis?.filter((api) => api?.status === "Draft") || [];
      const updateMappedApisRequestParams = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateMappedApisPayload),
      };
      // console.log(updateMappedApisPayload);
      Promise.all([
        fetch(updateRoleUrl, updateRoleRequestParam).then((res) => res.json()),
        fetch(
          updatedMappedUsersAndActivitiesUrl,
          updateMappedUsersAndActivitiesRequestParam
        ).then((res) => res.json()),
        fetch(updateMappedApisUrl, updateMappedApisRequestParams).then((res) =>
          res.json()
        ),
      ])
        .then(
          ([
            updateRoleData,
            updateMappedUserAndActivityData,
            updateMappedApisData,
          ]) => {
            setLoad(false);
            getRoleInfoById();

            dispatch(
              setResponseMessage({
                open: true,
                status:
                  updateRoleData?.status &&
                  updateMappedUserAndActivityData?.status &&
                  updateMappedApisData?.status
                    ? "success"
                    : "error",
                message:
                  updateRoleData?.status &&
                  updateMappedUserAndActivityData?.status &&
                  updateMappedApisData?.status
                    ? "Role details updated successfully"
                    : "Something went wrong",
              })
            );
          }
        )
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <Paper className={`${classes.roleDetailContainer} iagScroll`}>
      <Loading load={load} />

      <div className={classes.roleDetailCrossButtonContainer}>
        <Close
          style={{ fontSize: 16, cursor: "pointer" }}
          onClick={() => {
            // history.push(`/roles`);
            setParams({});
          }}
        />
      </div>

      <div className={classes.roleDetailHeaderContainer}>
        <Typography
          className={`${classes.roleDetailHeaderItem} ${
            selectedRoleDetailContentType === "Basic Info" &&
            classes.roleDetailHeaderItemSelected
          }`}
          onClick={() => setSelectedRoleDetailContentType("Basic Info")}
        >
          Basic Info
        </Typography>

        {roleDetail?.isComposite === 1 && (
          <Typography
            className={`${classes.roleDetailHeaderItem} ${
              selectedRoleDetailContentType === "Associate Roles" &&
              classes.roleDetailHeaderItemSelected
            }`}
            onClick={() => setSelectedRoleDetailContentType("Associate Roles")}
          >
            Associate Roles
          </Typography>
        )}

        {!(
          roleDetail?.applicationId === applicationIds?.SAP_BTP &&
          roleDetail?.isComposite === 0
        ) && (
          <Typography
            className={`${classes.roleDetailHeaderItem} ${
              selectedRoleDetailContentType === "Assigned Users" &&
              classes.roleDetailHeaderItemSelected
            }`}
            onClick={() => setSelectedRoleDetailContentType("Assigned Users")}
          >
            Assigned Users
          </Typography>
        )}

        {roleDetail?.applicationId !== applicationIds?.SAP_BTP && (
          <Typography
            className={`${classes.roleDetailHeaderItem} ${
              selectedRoleDetailContentType === "Assigned Features" &&
              classes.roleDetailHeaderItemSelected
            }`}
            onClick={() =>
              setSelectedRoleDetailContentType("Assigned Features")
            }
          >
            Assigned Features
          </Typography>
        )}

        {roleDetail?.applicationId !== applicationIds?.SAP_BTP && (
          <Typography
            className={`${classes.roleDetailHeaderItem} ${
              selectedRoleDetailContentType === "Assigned Apis" &&
              classes.roleDetailHeaderItemSelected
            }`}
            onClick={() => setSelectedRoleDetailContentType("Assigned Apis")}
          >
            Assigned Apis
          </Typography>
        )}
      </div>

      {selectedRoleDetailContentType === "Basic Info" && (
        <RoleInfo
          roleDetail={roleDetail}
          setRoleDetail={setRoleDetail}
          params={{ roleId: roleId }}
          load={load}
        />
      )}

      {roleDetail?.isComposite === 1 &&
        selectedRoleDetailContentType === "Associate Roles" && (
          <RoleAssociateRoles
            roleDetail={roleDetail}
            setRoleDetail={setRoleDetail}
            getRoleInfoById={getRoleInfoById}
            load={load}
            setLoad={setLoad}
            params={{ roleId: roleId }}
          />
        )}

      {!(
        roleDetail?.applicationId === applicationIds?.SAP_BTP &&
        roleDetail?.isComposite === 0
      ) &&
        selectedRoleDetailContentType === "Assigned Users" && (
          <RoleAssignedUsers
            roleDetail={roleDetail}
            setRoleDetail={setRoleDetail}
            load={load}
            setLoad={setLoad}
            params={{ roleId: roleId }}
          />
        )}

      {selectedRoleDetailContentType === "Assigned Features" &&
        roleDetail?.applicationId !== applicationIds?.SAP_BTP && (
          <RoleAssignedActivities
            roleDetail={roleDetail}
            setRoleDetail={setRoleDetail}
            load={load}
            setLoad={setLoad}
            params={{ roleId: roleId }}
          />
        )}

      {selectedRoleDetailContentType === "Assigned Apis" &&
        roleDetail?.applicationId !== applicationIds?.SAP_BTP && (
          <RoleAssignedApis
            roleDetail={roleDetail}
            setRoleDetail={setRoleDetail}
            load={load}
            setLoad={setLoad}
            params={{ roleId: roleId }}
          />
        )}

      {userFeatures?.includes(features?.ADD_ROLE_DETAILS) && (
        <div className={classes.roleDetailFooter}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.roleDetailFooterButton}
            onClick={updateRoleInfo}
            disabled={
              load ||
              roleDetail?.name?.length === 0 ||
              roleDetail?.label?.length === 0 ||
              roleDetail?.description?.length === 0 ||
              !userFeatures?.includes(features?.ADD_ROLE_DETAILS) ||
              basicReducerState?.roles?.find(
                (role) =>
                  role?.name === roleDetail?.name &&
                  role?.applicationId === roleDetail?.applicationId &&
                  Number(role?.id) !== Number(roleId) &&
                  role?.isComposite === roleDetail?.isComposite
              ) ||
              (roleDetail?.isComposite === 0 &&
                roleDetail?.applicationId === applicationIds?.SAP_BTP)
            }
          >
            Submit
          </Button>
        </div>
      )}
    </Paper>
  );
}

export default RolesDetail;
