import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Tabs,
  Tab,
  TextField,
  Checkbox,
  Typography,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  Delete,
  Add,
  Search,
  Create,
  Check,
  Close,
  Refresh,
  GetApp,
  Publish,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@material-ui/icons";
import { MdResetTv } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../NotFound";
import UserDetail from "./UserDetail";
import { authVerify } from "../../Utility/auth";
import { getAllUsers, getAllInactiveUsers } from "../../Action/action";
import {
  setUsers,
  setResponseMessage,
  setIdpUsers,
} from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import CustomAvatar from "../CustomAvatar";
import { downloadFile, userFileHeading } from "../../Utility/file";
import UploadFile from "../UploadFile";
import { Autocomplete } from "@material-ui/lab";
import WorkTip from "@cw/cherrywork-worktip";
import DeletionMessageBox from "../DeletionMessageBox";
import { features } from "../../Data/data";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  sidebarWidth,
  userPageHeaderHeight,
} from "../../Data/cssConstant";
import { TabPanel, a11yProps } from "../TabPanel";

const useStyle = makeStyles((theme) => ({
  idpUsersDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  idpUsersDialogContentSearchContainer: {
    marginBottom: 8,
    width: 200,
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  idpUsersTableContainer: {
    height: "51vh",
    // overflow: "overlay",
    width: "100%",
  },
  idpUsersTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  idpUsersTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  idpUsersTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  idpUsersTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  idpUsersTableBodyRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  idpUsersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  idpUsersTableBodyTextHide: {
    maxWidth: 180,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  idpUsersDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  usersContainer: {
    padding: 10,
    width: `calc(100vw - ${sidebarWidth})`,
    height: `calc(100vh - ${appHeaderHeight})`,
    // width: "94%",
    backgroundColor: theme.palette.background.paper,
  },
  usersHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    height: userPageHeaderHeight,
    backgroundColor: theme.palette.background.paper,
  },
  userHeadeTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  usersHeaderAddButton: {
    marginLeft: 10,
    // borderRadius: 50,
    textTransform: "capitalize",
  },
  usersTableContainer: {
    height: "100%",
    // overflow: "overlay",
    width: "100%",
  },
  usersTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  usersTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  usersTableBody: {
    height: "100%",
  },
  usersTableBodyRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  usersTableBodyRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  usersTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  usersTableBodyTextHide: {
    overflow: "hidden",
    maxWidth: 180,
    textOverflow: "ellipsis",
  },
}));

const IdpUsers = ({ open, onClose }) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [load, setLoad] = useState(false);
  const [idpUsers, setidpUsers] = useState([]);
  const [filteredIdpUsers, setFilteredIdpUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [tabName, setTabName] = useState(0);
  const initialUserState = {
    firstName: "",
    lastName: "",
    emailId: "",
  };
  const [newValue, setNewValue] = useState(initialUserState);
  // const [selectApplication, setSelectApplication] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [formError, setFormError] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    firstNameValidation: "",
    lastNameValidation: "",
    emailIdValidation: "",
  });

  const searchIdpUsers = (e) => {
    setFilteredIdpUsers(
      idpUsers?.filter(
        (user) =>
          user?.pid?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
          user?.userName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          user?.name?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
          user?.firstName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          user?.lastName
            ?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          user?.userEmail?.toLowerCase()?.includes(e.target.value.toLowerCase())
      ) || []
    );
  };
  const insertNewUser = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const insertUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/insertMultipleIdpUsers`;
      // const insertUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/insertMultipleIdpUsers`;
      const payload =
        selectedUser?.map((user) => ({
          userId: user.pid,
          userName: user?.userName || user?.userEmail,
          name: user?.name,
          firstName: user?.firstName,
          lastName: user?.lastName,
          emailId: user?.userEmail,
        })) || [];
      const insertUserRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(payload),
      };
      fetch(insertUserUrl, insertUserRequestParam)
        .then((res) => res.json())
        .then((user_data) => {
          setLoad(false);
          setSelectedUser([]);
          setFilteredIdpUsers(basicReducerState.idpUsers);
          setNewValue(initialUserState);

          getAllUsers(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setUsers(data?.data || []));
              setLoad(false);
              onClose();
              setSelectedUser([]);
              // setSelectApplication("");
              setError(false);

              dispatch(
                setResponseMessage({
                  open: true,
                  status: user_data?.status ? "success" : "error",
                  message: user_data?.status
                    ? "User added successfully"
                    : "Something went wrong",
                })
              );
            },
            (err) => {
              setLoad(false);
              onClose();
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const exportUser = () => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const exportUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/SAP-IAS/UserCreation`;
      // const exportUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/SAP-IAS/UserCreation`;
      const payload = {
        userName: newValue?.firstName,
        displayName: `${newValue?.firstName} ${newValue?.lastName}`,
        firstName: newValue?.firstName,
        lastName: newValue?.lastName,
        emailId: newValue?.emailId,
        // createdBy: userReducerState?.user?.email,
        // createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        // updatedBy: userReducerState?.user?.email,
        // updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const exportUserRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState?.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(payload),
      };
      fetch(exportUserUrl, exportUserRequestParam)
        .then((res) => res.json())
        .then((user_data) => {
          setLoad(false);
          setSelectedUser([]);
          setFilteredIdpUsers(basicReducerState.idpUsers);
          setNewValue(initialUserState);

          getAllUsers(
            userReducerState.token,
            () => {
              setLoad(true);
            },
            (data) => {
              dispatch(setUsers(data?.data || []));
              setLoad(false);
              onClose();
              setSelectedUser([]);
              // setSelectApplication("");
              setError(false);

              dispatch(
                setResponseMessage({
                  open: true,
                  status: user_data?.status ? "success" : "error",
                  message: user_data?.status
                    ? "User added successfully"
                    : "Something went wrong",
                })
              );
            },
            (err) => {
              setLoad(false);
              onClose();
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const isPresent = (i_user) => {
    return basicReducerState?.users?.filter(
      (user) => user?.emailId === i_user?.userEmail
    )?.length > 0
      ? true
      : false;
  };
  const alreadySelectedUser = (i_user) => {
    return selectedUser?.find((user) => user?.userEmail === i_user?.userEmail)
      ? true
      : false;
  };
  // const getApplicationIdByName = (applicationName) => {
  //   const application = basicReducerState?.applications?.find(
  //     (application) => application?.name === applicationName
  //   );
  //   return application?.id || -1;
  // };
  // const defaultRolePresent = (applicationId) => {
  //   basicReducerState?.roles?.find(
  //     (role) =>
  //       Number(role?.applicationId) === Number(applicationId) &&
  //       Number(role?.isDefault) === 1
  //   )
  //     ? setError(false)
  //     : setError(true);
  // };

  useEffect(() => {
    setidpUsers(basicReducerState.idpUsers);
    setFilteredIdpUsers(basicReducerState.idpUsers);
  }, [basicReducerState.idpUsers]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        onClose();
        setFilteredIdpUsers(basicReducerState.idpUsers);
      }}
    >
      <DialogTitle className={classes.idpUsersDialogTitle}>
        Add Users
      </DialogTitle>

      <DialogContent>
        <Loading load={load} />

        <div>
          <Tabs
            value={tabName}
            onChange={(e, newValue) => setTabName(newValue)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Existing Users" {...a11yProps(0)} />
            <Tab label="New Users" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={tabName} index={0}>
            <div
              className={`inputContainer ${classes.idpUsersDialogContentSearchContainer}`}
              style={{ marginTop: 16 }}
            >
              <input
                className={userReducerState.darkMode && "inputDarkMode"}
                placeholder="Search"
                onChange={searchIdpUsers}
              />

              <Search />
            </div>

            {filteredIdpUsers?.length === 0 ? (
              <NotFound />
            ) : (
              <>
                <TableContainer
                  component={Paper}
                  className={`${classes.idpUsersTableContainer} iagScroll`}
                >
                  <Table
                    size="small"
                    aria-label="simple table"
                    // className="table-sort table-arrows"
                  >
                    <TableHead className={classes.idpUsersTableHead}>
                      <TableRow>
                        <TableCell
                          // align="center"
                          className={classes.idpUsersTableHeadCell}
                        >
                          Pid
                        </TableCell>
                        <TableCell
                          // align="center"
                          className={classes.idpUsersTableHeadCell}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          // align="center"
                          className={classes.idpUsersTableHeadCell}
                        >
                          Email
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody
                      className={`${classes.idpUsersTableBody} iagScroll`}
                    >
                      {filteredIdpUsers?.map(
                        (idpUser) =>
                          !isPresent(idpUser) && (
                            <TableRow
                              key={idpUser?.userEmail}
                              // selected={idpUser?.pid === selectedUser?.userId}
                              className={`${classes.idpUsersTableBodyRow} ${
                                alreadySelectedUser(idpUser) &&
                                classes.idpUsersTableBodyRowSelected
                              }`}
                              onClick={() => {
                                if (alreadySelectedUser(idpUser)) {
                                  setSelectedUser(
                                    selectedUser?.filter(
                                      (user) =>
                                        user?.userEmail !== idpUser?.userEmail
                                    )
                                  );
                                } else {
                                  setSelectedUser([...selectedUser, idpUser]);
                                }
                              }}
                            >
                              <TableCell
                                // align="center"
                                className={classes.idpUsersTableBodyCell}
                              >
                                {idpUser?.pid}
                              </TableCell>

                              <TableCell
                                // align="center"
                                className={classes.idpUsersTableBodyCell}
                              >{`${idpUser?.firstName} ${idpUser?.lastName}`}</TableCell>

                              <TableCell
                                // align="center"
                                className={`${classes.idpUsersTableBodyCell} ${classes.idpUsersTableBodyTextHide}`}
                              >
                                {idpUser?.userEmail}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </TabPanel>

          <TabPanel value={tabName} index={1}>
            <div>
              <TextField
                error={formError?.firstNameError}
                variant="standard"
                fullWidth
                required
                label="First Name"
                helperText={errorMessage?.firstNameValidation}
                value={newValue?.firstName}
                onChange={(e) => {
                  setNewValue({ ...newValue, firstName: e.target.value });
                  if (e.target.value.length <= 0) {
                    setFormError({ ...formError, firstNameError: true });
                    setErrorMessage({
                      ...errorMessage,
                      firstNameValidation: "First Name is Required",
                    });
                  } else {
                    setFormError({ ...formError, firstNameError: false });
                    setErrorMessage({
                      ...errorMessage,
                      firstNameValidation: "",
                    });
                  }
                }}
              />

              <TextField
                error={formError?.lastNameError}
                variant="standard"
                fullWidth
                required
                label="Last Name"
                helperText={errorMessage?.lastNameValidation}
                value={newValue?.lastName}
                onChange={(e) => {
                  setNewValue({ ...newValue, lastName: e.target.value });
                  if (e.target.value.length <= 0) {
                    setFormError({ ...formError, lastNameError: true });
                    setErrorMessage({
                      ...errorMessage,
                      lastNameValidation: "Last Name is Required",
                    });
                  } else {
                    setFormError({ ...formError, lastNameError: false });
                    setErrorMessage({
                      ...errorMessage,
                      lastNameValidation: "",
                    });
                  }
                }}
              />

              <TextField
                error={formError?.emailError}
                variant="standard"
                fullWidth
                required
                label="User Email"
                helperText={errorMessage?.emailIdValidation}
                value={newValue?.emailId}
                onChange={(e) => {
                  setNewValue({ ...newValue, emailId: e.target.value });
                  if (e?.target?.value?.length <= 0) {
                    setFormError({ ...formError, emailError: true });
                    setErrorMessage({
                      ...errorMessage,
                      emailIdValidation: "EmailId is required",
                    });
                  }
                  if (
                    !(
                      e.target.value.includes("@") &&
                      e.target.value.includes(".com")
                    )
                  ) {
                    setFormError({ ...formError, emailError: true });
                    setErrorMessage({
                      ...errorMessage,
                      emailIdValidation: "Enter a valid email address",
                    });
                  } else {
                    setFormError({ ...formError, emailError: false });
                    setErrorMessage({ ...errorMessage, emailIdValidation: "" });
                  }
                }}
              />
            </div>
          </TabPanel>
        </div>
      </DialogContent>

      <DialogActions className={classes.idpUsersDialogActions}>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            setSelectedUser([]);
            onClose();
            // setSelectApplication("");
            setFormError({
              firstNameError: false,
              lastNameError: false,
              emailError: false,
            });
            setErrorMessage({
              firstNameValidation: "",
              lastNameValidation: "",
              emailIdValidation: "",
            });
            setError(false);
            setFilteredIdpUsers(basicReducerState.idpUsers);
            setNewValue(initialUserState);
          }}
          disabled={load}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            if (tabName === 0) {
              insertNewUser();
            } else if (tabName === 1) {
              exportUser();
            }
          }}
          style={{ textTransform: "capitalize" }}
          disabled={load || (tabName === 0 && selectedUser?.length === 0)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Users = () => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [load, setLoad] = useState(true);
  const [inactiveUsersSwitch, setInactiveUsersSwitch] = useState(false);
  const [users, setusers] = useState(basicReducerState.users);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    startCount: 0,
    userCount: 100,
    currPage: 1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [openIdpDialog, setOpenIdpDialog] = useState(false);
  const [openUserFileDialog, setOpenUserFileDialog] = useState(false);
  const [selectingFileUserApplications, setSelectingFileUserApplications] =
    useState([]);
  const [search, setSearch] = useState("");
  const [file, setFile] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const history = useHistory();
  // const params = useParams();
  const [params, setParams] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [empId, setEmpId] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const id = Boolean(anchorEl)
    ? `workTip-${empId ? selectedEmp : ""}`
    : undefined;
  const dispatch = useDispatch();

  const deleteUser = (userId) => {
    if (authVerify(userReducerState?.token)) {
      setLoad(true);
      const disableUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/deactivate?id=${userId}`;
      // const disableUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/deactivate?id=${userId}`;
      const disableUserRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      // const disableUserUrl=`/wa_authz/api`;
      // const payload = {
      //   name:"disableUser",
      //   type:"statement",
      //   args:[userId],
      // }
      // const disableUserRequestParam={
      //   method:"POST",
      //   headers:{
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${this.props.token}`,
      // applicationId: process.env.REACT_APP_IWAId,
      //   },
      //   body:JSON.stringify(payload),
      // }
      fetch(disableUserUrl, disableUserRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          dispatch(
            setUsers(
              basicReducerState?.users?.filter(
                (user) => user?.emailId !== userId
              ) || []
            )
          );
          setusers(users?.filter((user) => user?.emailId !== userId) || []);
          setFilteredUsers(
            filteredUsers?.filter((user) => user?.emailId !== userId) || []
          );
          setDeletingUser(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "User deleted successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const changeStatus = (user) => {
    setFilteredUsers(
      filteredUsers?.map((f_user) =>
        f_user?.emailId === user?.emailId
          ? {
              ...f_user,
              status: f_user?.status === "Draft" ? "Active" : "Draft",
            }
          : { ...f_user }
      )
    );
  };
  const updateUser = (user) => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userAndUserRoleMapping`;
      // const updateUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userAndUserRoleMapping`;
      const updateUserPayload = {
        userId: user?.userId,
        userName: user?.userName,
        displayName: user?.displayName,
        firstName: user?.firstName,
        lastName: user?.lastName,
        emailId: user?.emailId,
        status: "Active",
        isDeleted: 0,
        isActive: 1,
        // createdBy: user?.createdBy,
        // createdOn: moment(new Date(user?.createdOn)).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // ),
        // updatedBy: userReducerState?.user?.email,
        // updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        // id: Number(user?.id),
        userRoles: [],
        assignedLicenses: [],
        featuresException: [],
      };
      const updateUserRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateUserPayload),
      };
      fetch(updateUserUrl, updateUserRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          if (data?.statusCode === 201) {
            dispatch(
              setUsers(
                basicReducerState.users?.map((f_user) =>
                  f_user?.emailId === user?.emailId
                    ? {
                        ...f_user,
                        status: "Active",
                        displayName: user?.displayName,
                      }
                    : { ...f_user }
                ) || []
              )
            );

            setFilteredUsers(
              filteredUsers?.map((f_user) =>
                f_user?.emailId === user?.emailId
                  ? {
                      ...f_user,
                      status: "Active",
                      displayName: user?.displayName,
                    }
                  : { ...f_user }
              ) || []
            );

            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.status
                  ? "Display name updated successfully"
                  : "Something went wrong",
              })
            );
          }
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const updateInactiveUser = (user) => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateInactiveUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/updateUser`;
      //  const updateInactiveUserUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/updateUser`;
      const updateInactiveUserPayload = {
        employeeId: user?.employeeId,
        userId: user?.userId,
        userName: user?.userName,
        displayName: user?.displayName,
        firstName: user?.firstName,
        lastName: user?.lastName,
        emailId: user?.emailId,
        idp: user?.idp,
        status: "Active",
        isActive: 1,
        isDeleted: 0,
        // createdBy: user?.createdBy,
        // createdOn: moment(new Date(user?.createdOn)).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // ),
        // id: Number(user?.id),
        // updatedBy: userReducerState?.user?.email,
        // updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const updateInactiveUserRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_WAId,
        },
        body: JSON.stringify(updateInactiveUserPayload),
      };
      fetch(updateInactiveUserUrl, updateInactiveUserRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          if (data?.statusCode === 201) {
            getUsers();
            dispatch(
              setResponseMessage({
                open: true,
                status: data?.status ? "success" : "error",
                message: data?.status
                  ? "User status updated successfully"
                  : "Something went wrong",
              })
            );
          }
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const editUser = (userId) => {
    // history.push(`/users/${userId}`);
    setParams({ userId: userId });
  };
  const getUsers = () => {
    if (authVerify(userReducerState?.token)) {
      getAllUsers(
        userReducerState.token,
        () => {
          setLoad(true);
        },
        (data) => {
          dispatch(setUsers(data?.data || []));
          setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getUsersList = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const getActiveUsersListUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/pagination?startCount=${pageDetails?.startCount}&userCount=${pageDetails?.userCount}&searchValue=${search}`;
      const getActiveUsersListRequestParam = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_WAId,
        },
      };
      fetch(getActiveUsersListUrl, getActiveUsersListRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          if (data?.statusCode === 200) {
            // getUsers();
            console.log("Users", data?.data);
            setFilteredUsers(data?.data?.userWithApplicationNamePojo);
            setTotalRecords(data?.data?.totalUserCount);
          }
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getIdpUsers = () => {
    if (authVerify(userReducerState?.token)) {
      getIdpUsers(
        userReducerState?.token,
        () => {
          // setLoad(true);
        },
        (data) => {
          dispatch(setIdpUsers(data?.data || []));
          // setLoad(false);
        },
        (err) => {
          // setLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const getInActiveUsers = () => {
    if (authVerify(userReducerState?.token)) {
      getAllInactiveUsers(
        userReducerState.token,
        () => {
          setLoad(true);
        },
        (data) => {
          setFilteredUsers(data?.data || []);
          setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const uploadUsersFile = (e) => {
    if (authVerify(userReducerState.token)) {
      if (!file) {
        console.log("no file found");
        return;
      }
      setLoad(true);
      const applicationName = selectingFileUserApplications?.map(
        (application) => application?.name
      );
      const url = `${
        process.env.REACT_APP_IWAServices
      }/api/v1/users/addUsersUsingCsv?applicationName=${applicationName.join(
        ","
      )}`;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: formData,
      };
      fetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setLoad(false);
          setOpenUserFileDialog(false);
          setFile(null);
          setSelectingFileUserApplications([]);

          dispatch(
            setResponseMessage({
              open: true,
              status: "success",
              message: "User file uploaded successfully",
            })
          );
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
          dispatch(
            setResponseMessage({
              open: true,
              status: "error",
              message: "Something went wrong",
            })
          );
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const isDefaultRole = (applicationId) => {
    return basicReducerState?.roles?.filter(
      (role) =>
        Number(role?.applicationId) === Number(applicationId) &&
        Number(role.isDefault) === 1
    )?.length > 0
      ? true
      : false;
  };
  const searchUser = () => {
    setPageDetails({
      startCount: 0,
      userCount: 100,
      currPage: 1,
    });
  };

  // useEffect(() => {
  //   setusers(basicReducerState?.users?.filter((user) => user?.isActive === 1));
  //   if (inactiveUsersSwitch) {
  //     getInActiveUsers();
  //   } else {
  //     if (search?.length > 0) {
  //       setFilteredUsers(
  //         basicReducerState?.users?.filter(
  //           (user) =>
  //             (user?.displayName
  //               ?.toLowerCase()
  //               ?.includes(search.toLowerCase()) ||
  //               user?.userId?.toLowerCase()?.includes(search.toLowerCase()) ||
  //               user?.emailId?.toLowerCase()?.includes(search.toLowerCase())) &&
  //             user?.isActive === 1
  //         )
  //       );
  //     } else {
  //       setFilteredUsers(
  //         basicReducerState?.users?.filter((user) => user?.isActive === 1)
  //       );
  //     }
  //     setLoad(false);
  //   }
  // }, [basicReducerState.users, search]);

  // useEffect(() => {
  //   getUsers();
  //   // getIdpUsers();
  // }, []);

  useEffect(() => {
    getUsersList();
  }, [pageDetails]);

  return (
    <div className={classes.usersContainer}>
      <Loading load={load} />

      <div>
        <IdpUsers
          open={openIdpDialog}
          onClose={() => {
            setOpenIdpDialog(false);
          }}
        />

        <UploadFile
          open={openUserFileDialog}
          onClose={() => {
            setOpenUserFileDialog(false);
            setFile(null);
            setSelectingFileUserApplications([]);
          }}
          onUpload={() => {
            uploadUsersFile();
          }}
          file={file}
          setFile={setFile}
          disableCondition={
            !file || selectingFileUserApplications?.length === 0
          }
          load={load}
        >
          <Autocomplete
            required
            multiple
            size="small"
            style={{ fontSize: 12 }}
            disableCloseOnSelect
            filterSelectedOptions
            value={selectingFileUserApplications}
            onChange={(e, applications) => {
              setSelectingFileUserApplications(applications);
            }}
            options={basicReducerState?.applications?.filter((application) =>
              isDefaultRole(Number(application?.id))
            )}
            getOptionLabel={(option) => option?.name}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />

                <Typography style={{ fontSize: 12 }}>{option?.name}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Applications"
                style={{ fontSize: 12 }}
              />
            )}
          />
        </UploadFile>

        <DeletionMessageBox
          open={deletingUser ? true : false}
          onClose={() => setDeletingUser(null)}
          onDelete={() => {
            deleteUser(deletingUser);
          }}
          load={load}
        />

        <div className={classes.usersHeaderContainer}>
          <Typography className={classes.userHeadeTitle}>Users</Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              control={
                <Switch
                  // style={{ fontSize: "14px",fontWeight:'bold'}}
                  color="primary"
                  checked={inactiveUsersSwitch}
                  onClick={(e) => {
                    setInactiveUsersSwitch(e.target.checked);
                    if (e.target.checked) {
                      getInActiveUsers();
                    } else {
                      setFilteredUsers(basicReducerState?.users || []);
                    }
                  }}
                />
              }
              label="Inactive Users"
            />

            {!inactiveUsersSwitch && (
              <div className={`inputContainer`}>
                <input
                  className={userReducerState.darkMode && "inputDarkMode"}
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />

                <Search style={{ cursor: "pointer" }} onClick={searchUser} />
              </div>
            )}

            {!inactiveUsersSwitch && (
              <Tooltip title="Download template" placement="bottom-start">
                <IconButton
                  size="small"
                  // color="primary"
                  style={{ marginLeft: 4 }}
                  disabled={load}
                  onClick={(e) => {
                    downloadFile({
                      data: userFileHeading,
                      fileName: "cw_users.csv",
                      fileType: "text/csv",
                    });
                  }}
                >
                  <GetApp style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            )}

            {!inactiveUsersSwitch && (
              <Tooltip title="Upload file" placement="bottom-start">
                <IconButton
                  size="small"
                  // color="primary"
                  style={{ marginLeft: 4 }}
                  disabled={load}
                  onClick={() => {
                    setOpenUserFileDialog(true);
                  }}
                >
                  <Publish style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            )}

            {!inactiveUsersSwitch && (
              <Tooltip title="Refresh" placement="bottom-start">
                <IconButton
                  size="small"
                  style={{ marginLeft: 4 }}
                  disabled={load}
                  onClick={() => {
                    getUsersList();
                    getIdpUsers();
                  }}
                >
                  <Refresh style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            )}

            {userFeatures?.includes(features?.ADD_USERS) &&
              !inactiveUsersSwitch && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.usersHeaderAddButton}
                  onClick={() => setOpenIdpDialog(true)}
                  startIcon={<Add />}
                  disabled={load}
                >
                  Add
                </Button>
              )}
          </div>
        </div>

        <Grid
          container
          spacing={2}
          style={{
            height: `calc(100vh - ${appHeaderHeight} - ${userPageHeaderHeight})`,
          }}
        >
          <Grid item xs={params?.userId ? 6 : 12} style={{ height: "100%" }}>
            {load ? null : filteredUsers?.length === 0 ? (
              <NotFound />
            ) : (
              <>
                <TableContainer
                  component={Paper}
                  className={`${classes.usersTableContainer} iagScroll`}
                  style={{ position: "relative" }}
                >
                  <Table
                    size="small"
                    //   className="table-sort table-arrows"
                  >
                    <TableHead className={classes.usersTableHead}>
                      <TableRow>
                        <TableCell
                          // align="center"
                          className={classes.usersTableHeadCell}
                        >
                          Display Name
                        </TableCell>

                        {/* <TableCell
                        align="center"
                        className={classes.usersTableHeadCell}
                      >
                        User Name
                      </TableCell> */}

                        {!inactiveUsersSwitch && (
                          <TableCell className={classes.usersTableHeadCell}>
                            Application
                          </TableCell>
                        )}

                        <TableCell
                          // align="center"
                          className={classes.usersTableHeadCell}
                        >
                          User Id
                        </TableCell>

                        <TableCell
                          // align="center"
                          className={classes.usersTableHeadCell}
                        >
                          Email ID
                        </TableCell>

                        <TableCell
                          // align="center"
                          className={classes.usersTableHeadCell}
                        >
                          Status
                        </TableCell>

                        {((!inactiveUsersSwitch &&
                          userFeatures?.includes(features?.ADD_USERS) &&
                          userFeatures?.includes(features?.REMOVE_USERS)) ||
                          (inactiveUsersSwitch &&
                            userFeatures?.includes(
                              features?.REACTIVATE_USERS
                            ))) && (
                          <TableCell
                            align="center"
                            className={classes.usersTableHeadCell}
                          >
                            Actions
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody className={`${classes.usersTableBody}`}>
                      {filteredUsers?.map((user, index) => {
                        return (
                          <TableRow
                            key={`${user?.emailId}-${index}`}
                            className={`${classes.usersTableBodyRow} ${
                              user?.emailId === params?.userId &&
                              classes.usersTableBodyRowSelected
                            }`}
                            onClick={() => {
                              if (
                                user.status === "Draft" ||
                                user.status === "Inactive"
                              ) {
                                return;
                              }
                              editUser(user?.emailId);
                            }}
                          >
                            <TableCell
                              // align="center"
                              className={classes.usersTableBodyCell}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{ width: "43px", borderRadius: "50%" }}
                                  onMouseLeave={(e) => {
                                    e.stopPropagation();
                                    if (user?.employeeId) {
                                      setAnchorEl(null);
                                    }
                                  }}
                                >
                                  <CustomAvatar
                                    name={user?.displayName}
                                    style={{ marginRight: 6 }}
                                    aria-describedby={`workTip-${user?.emailId}`}
                                    onMouseEnter={(e) => {
                                      e.stopPropagation();
                                      if (
                                        user?.employeeId &&
                                        user?.employeeId !== "null"
                                      ) {
                                        setAnchorEl(e.currentTarget);
                                        setEmpId(user?.employeeId);
                                      } else {
                                        setEmpId(null);
                                      }
                                    }}
                                  />

                                  {user?.employeeId &&
                                    user?.employeeId !== "null" &&
                                    user?.employeeId === empId && (
                                      <WorkTip
                                        id={id}
                                        anchorEl={anchorEl}
                                        onClose={() => {
                                          if (empId && empId !== "null") {
                                            setAnchorEl(null);
                                          }
                                        }}
                                        placement="right-start"
                                        userId={user?.employeeId}
                                        token={userReducerState?.token}
                                      />
                                    )}
                                </div>

                                {user.status === "Draft" ? (
                                  <TextField
                                    value={user?.displayName}
                                    onChange={(e) => {
                                      setFilteredUsers(
                                        filteredUsers?.map((p_user) =>
                                          user?.emailId === p_user?.emailId
                                            ? {
                                                ...p_user,
                                                displayName: e.target.value,
                                              }
                                            : { ...p_user }
                                        )
                                      );
                                    }}
                                    error={
                                      filteredUsers
                                        ?.filter(
                                          (u) => u?.emailId !== user?.emailId
                                        )
                                        ?.find(
                                          (u) =>
                                            u?.displayName === user?.displayName
                                        ) || user?.displayName?.length === 0
                                    }
                                    helperText={
                                      (filteredUsers
                                        ?.filter(
                                          (u) => u?.emailId !== user?.emailId
                                        )
                                        ?.find(
                                          (u) =>
                                            u?.displayName === user?.displayName
                                        ) ||
                                        user?.displayName?.length === 0) &&
                                      "Please enter different display Name"
                                    }
                                  />
                                ) : (
                                  user?.displayName
                                )}
                              </div>
                            </TableCell>

                            {user?.applicationName?.length > 0 &&
                            !inactiveUsersSwitch ? (
                              <TableCell
                                // align="center"
                                className={`${classes.usersTableBodyCell} ${classes.usersTableBodyTextHide}`}
                              >
                                <Tooltip
                                  title={
                                    user?.applicationName?.length > 0
                                      ? user?.applicationName?.join(", ")
                                      : "-"
                                  }
                                  placement="bottom-start"
                                >
                                  <Typography style={{ fontSize: 12 }}>
                                    {user?.applicationName?.length > 0
                                      ? user?.applicationName?.join(", ")
                                      : "-"}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            ) : !inactiveUsersSwitch ? (
                              <TableCell
                                // align="center"
                                className={`${classes.usersTableBodyCell} ${classes.usersTableBodyTextHide}`}
                              >
                                {user?.applicationName?.length > 0
                                  ? user?.applicationName?.join(", ")
                                  : "-"}
                              </TableCell>
                            ) : (
                              <></>
                            )}

                            <TableCell
                              // align="center"
                              className={classes.usersTableBodyCell}
                            >
                              {user?.userId}
                            </TableCell>

                            <TableCell
                              // align="center"
                              className={`${classes.usersTableBodyCell} ${classes.usersTableBodyTextHide}`}
                            >
                              {user?.emailId}
                            </TableCell>

                            <TableCell
                              // align="center"
                              className={classes.usersTableBodyCell}
                            >
                              {user?.status}
                            </TableCell>

                            {((!inactiveUsersSwitch &&
                              userFeatures?.includes(features?.ADD_USERS) &&
                              userFeatures?.includes(features?.REMOVE_USERS)) ||
                              (inactiveUsersSwitch &&
                                userFeatures?.includes(
                                  features?.REACTIVATE_USERS
                                ))) && (
                              <TableCell
                                align="center"
                                className={classes.usersTableBodyCell}
                              >
                                {inactiveUsersSwitch &&
                                  userFeatures?.includes(
                                    features?.REACTIVATE_USERS
                                  ) && (
                                    <Tooltip title="Reactivate User">
                                      <IconButton
                                        aria-label="Reactivate"
                                        color="secondary"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          updateInactiveUser(user);
                                        }}
                                        // disabled={load}
                                      >
                                        <MdResetTv style={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}

                                {!inactiveUsersSwitch &&
                                  (user?.status !== "Draft" ? (
                                    userFeatures?.includes(
                                      features?.ADD_USERS
                                    ) && (
                                      <Tooltip title="Edit">
                                        <IconButton
                                          aria-label="Edit"
                                          color="primary"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            changeStatus(user);
                                          }}
                                          // disabled={load}
                                        >
                                          <Create style={{ fontSize: 16 }} />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  ) : (
                                    <>
                                      {userFeatures?.includes(
                                        features?.REMOVE_USERS
                                      ) && (
                                        <Tooltip title="Check">
                                          <IconButton
                                            aria-label="Check"
                                            color="primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              updateUser(user);
                                            }}
                                            disabled={
                                              filteredUsers
                                                ?.filter(
                                                  (u) =>
                                                    u?.emailId !== user?.emailId
                                                )
                                                ?.find(
                                                  (u) =>
                                                    u?.displayName ===
                                                    user?.displayName
                                                ) ||
                                              user?.displayName?.length === 0
                                            }
                                          >
                                            <Check style={{ fontSize: 16 }} />
                                          </IconButton>
                                        </Tooltip>
                                      )}

                                      <Tooltip title="Discard">
                                        <IconButton
                                          aria-label="Discard"
                                          color="secondary"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            changeStatus(user);
                                            setFilteredUsers(
                                              basicReducerState?.users
                                            );
                                          }}
                                        >
                                          <Close style={{ fontSize: 16 }} />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ))}

                                {user?.status !== "Draft" &&
                                  !inactiveUsersSwitch &&
                                  userFeatures?.includes(
                                    features?.REMOVE_USERS
                                  ) && (
                                    <Tooltip title="Delete">
                                      <IconButton
                                        aria-label="Delete"
                                        color="secondary"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // deleteUser(user?.emailId);
                                          setDeletingUser(user?.emailId);
                                        }}
                                        // disabled={load}
                                      >
                                        <Delete style={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>

                  <Pagination
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "1%",
                      position: "sticky",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: "white",
                    }}
                    count={Math.floor(totalRecords / pageDetails?.userCount)}
                    shape="rounded"
                    onChange={(event, page) => {
                      if (page - 1 == 0) {
                        setPageDetails({
                          ...pageDetails,
                          startCount: 0,
                          currPage: page,
                        });
                      } else {
                        setPageDetails({
                          ...pageDetails,
                          startCount: (page - 1) * pageDetails?.userCount,
                          currPage: page,
                        });
                      }
                    }}
                    page={pageDetails?.currPage || 1}
                  />
                </TableContainer>
              </>
            )}
          </Grid>

          {params?.userId && filteredUsers?.length > 0 && (
            <Grid item xs={params?.userId ? 6 : false}>
              <UserDetail
                params={params}
                setParams={setParams}
                getUsersList={(emailId, displayName) => {
                  dispatch(
                    setUsers(
                      basicReducerState.users?.map((f_user) =>
                        f_user?.emailId === emailId
                          ? {
                              ...f_user,
                              status: "Active",
                              displayName: displayName,
                            }
                          : { ...f_user }
                      ) || []
                    )
                  );

                  setFilteredUsers(
                    filteredUsers?.map((f_user) =>
                      f_user?.emailId === emailId
                        ? {
                            ...f_user,
                            status: "Active",
                            displayName: displayName,
                          }
                        : { ...f_user }
                    ) || []
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Users;
