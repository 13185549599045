import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Delete,
  Add,
  ArrowForwardIos,
  Refresh,
  CheckBoxOutlineBlank,
  CheckBox,
  Publish,
  GetApp,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NotFound from "../NotFound";
import { authVerify } from "../../Utility/auth";
import Loading from "../Loading";
import { findApplicationById } from "../../Utility/basic";
import { getAllGroups } from "../../Action/action";
import {
  setApplications,
  setGroups,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import GroupDetail from "./GroupDetail";
import CustomAvatar from "../CustomAvatar";
import { downloadFile, groupFileHeading } from "../../Utility/file";
import UploadFile from "../UploadFile";
import DeletionMessageBox from "../DeletionMessageBox";
import { features } from "../../Data/data";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  groupPageHeaderHeight,
  sidebarWidth,
} from "../../Data/cssConstant";

const useStyle = makeStyles((theme) => ({
  newGroupDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newGroupDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  groupsContainer: {
    padding: 10,
    width: `calc(100vw - ${sidebarWidth})`,
    height: `calc(100vh - ${appHeaderHeight})`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  groupsHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    zIndex: 99,
    height: groupPageHeaderHeight,
    backgroundColor: theme.palette.background.paper,
  },
  groupHeadeTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  groupsHeaderDropdown: {
    // margin: "2px 0px",
    width: 150,
    marginRight: 6,
  },
  groupsHeaderAddButton: {
    marginLeft: 10,
    // borderRadius: 50,
    textTransform: "capitalize",
  },
  groupsTableContainer: {
    height: "100%",
    // overflow: "overlay",
    width: "100%",
  },
  groupsTableHead: {
    // backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: "#F1F5FE",
  },
  groupsTableHeadCell: {
    // fontWeight: 700,
    // color: theme.palette.background.paper,
    // fontSize: 14,
    whiteSpace: "nowrap",
    fontSize: 9,
    // lineHeight: 1.5,
    fontWeight: "bold",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  groupsTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  groupsTableBodyRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  groupsTableBodyRowSelected: {
    backgroundColor: theme.palette.action.selected,
  },
  groupsTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 10,
    backgroundColor: "white",
    borderLeft: "1px solid #E5E5E5",
    borderRight: "1px solid #E5E5E5",
  },
  groupsTableBodyTextHide: {
    overflow: "hidden",
    maxWidth: 180,
    textOverflow: "ellipsis",
  },
}));

const NewGroup = ({ open, onClose, filteredGroups, setFilteredGroups, selectAplication, setSelectApplication }) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [load, setLoad] = useState(false);
  const initialGroup = {
    name: "",
    applicationId: "",
    users: [],
    roles: [],
    status: "Draft",
  };
  const [newGroup, setNewGroup] = useState(initialGroup);
  const dispatch = useDispatch();

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name || "-";
  };
  const insertNewGroup = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const createGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups`;
      // const createGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups`;
      const createGroupPayload = {
        name: newGroup?.name,
        applicationId: newGroup?.applicationId,
        userIdList:
          newGroup?.users?.map((user) => user?.emailId).join(",") || "",
        roleIdList:
          newGroup?.roles?.map((role) => Number(role?.id)).join(",") || "",
        createdBy: userReducerState?.user?.email,
        createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        updatedBy: userReducerState?.user?.email,
        updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
      const createGroupRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(createGroupPayload),
      };
      console.log(createGroupPayload);
      fetch(createGroupUrl, createGroupRequestParam)
        .then((res) => res.json())
        .then((group_data) => {
          setNewGroup(initialGroup);
          getAllGroups(
            userReducerState.token,
            () => {
              // setLoad(true);
            },
            (data) => {
              dispatch(setGroups(data?.data || []));
              setSelectApplication(-1)
                setFilteredGroups(
                  data?.data?.map((group) => ({
                    ...group,
                    applicationId: group?.applicationId?.split(",") || [],
                  })) || []
                );
              setLoad(false);
              onClose();

              dispatch(
                setResponseMessage({
                  open: true,
                  status: group_data?.status ? "success" : "error",
                  message: group_data?.status
                    ? "Group created successfully"
                    : "Something went wrong",
                })
              );
            },
            (err) => {
              setLoad(false);
            }
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newGroupDialogTitle}>
        New Group
      </DialogTitle>

      <DialogContent style={{ position: "relative" }}>
        <Loading load={load} />

        <TextField
          variant="standard"
          fullWidth
          required
          size="small"
          label="Name"
          value={newGroup?.name}
          onChange={(e) => {
            setNewGroup({ ...newGroup, name: e.target.value });
          }}
          error={basicReducerState?.groups?.find(
            (group) => group?.name === newGroup?.name
            // &&
            // group?.applicationId === newGroup?.applicationId
          )}
          helperText={
            basicReducerState?.groups?.find(
              (group) => group?.name === newGroup?.name
              // &&
              // group?.applicationId === newGroup?.applicationId
            ) && "Group name already exists."
          }
        />

        {/* <FormControl variant="standard" fullWidth size="small" required>
          <InputLabel>Application</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newGroup?.applicationId}
            onChange={(e) => {
              setNewGroup({
                ...newGroup,
                applicationId: e.target.value,
                users: [],
                roles: [],
              });
            }}
          >
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={`${application?.id}-${index}`}
                value={application?.id}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <Autocomplete
          multiple
          size="small"
          disableCloseOnSelect
          filterSelectedOptions
          style={{ fontSize: 12 }}
          value={newGroup?.users}
          onChange={(e, users) => {
            setNewGroup({
              ...newGroup,
              users: users,
            });
          }}
          options={
            basicReducerState?.users
            // ?.filter((user) =>
            //   user?.applicationName?.includes(
            //     getApplicationNameById(newGroup?.applicationId)
            //   )
            // )
          }
          getOptionLabel={(option) => option?.displayName}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />

              {/* <CustomAvatar
                name={option?.displayName}
                style={{
                  marginRight: 6,
                  marginLeft: 8,
                }}
              /> */}

              <Typography style={{ fontSize: 12 }}>
                {option?.displayName}
              </Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Users"
              style={{ fontSize: 12 }}
            />
          )}
        />

        {/* <Autocomplete
          multiple
          size="small"
          disableCloseOnSelect
          filterSelectedOptions
          style={{ fontSize: 12 }}
          value={newGroup?.roles}
          onChange={(e, roles) => {
            setNewGroup({
              ...newGroup,
              roles: roles,
            });
          }}
          options={basicReducerState?.roles?.filter(
            (role) =>
              Number(role?.applicationId) === Number(newGroup?.applicationId)
          )}
          getOptionLabel={(option) => option?.name}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option?.name}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Roles"
              style={{ fontSize: 12 }}
            />
          )}
        /> */}
      </DialogContent>

      <DialogActions className={classes.newGroupDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            setNewGroup(initialGroup);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={insertNewGroup}
          style={{ textTransform: "capitalize" }}
          disabled={
            load ||
            newGroup?.name?.length === 0 ||
            // newGroup?.applicationId?.length === 0 ||
            basicReducerState?.groups?.find(
              (group) => group?.name === newGroup?.name
              // &&
              // group?.applicationId === newGroup?.applicationId
            )
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function Groups() {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [load, setLoad] = useState(true);
  const [selectAplication, setSelectApplication] = useState(-1);
  const [groups, setgroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [openNewGroupDialog, setOpenNewGroupDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [openGroupFileDialog, setOpenGroupFileDialog] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(null);
  const history = useHistory();
  // const params = useParams();
  const [params, setParams] = useState({});
  const dispatch = useDispatch();

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name || "-";
  };
  const deleteGroup = (groupId) => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const disableGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/deactivate?id=${groupId}`;
      const disableGroupRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(disableGroupUrl, disableGroupRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          dispatch(
            setGroups(
              basicReducerState?.groups?.filter(
                (group) => Number(group?.id) !== Number(groupId)
              ) || []
            )
          );
          setgroups(
            groups?.filter((group) => group?.id !== Number(groupId)) || []
          );
          setFilteredGroups(
            filteredGroups?.filter((group) => group?.id !== Number(groupId)) ||
              []
          );
          setDeletingGroup(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "Group deleted successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const editGroup = (groupId) => {
    // history.push(`/groups/${groupId}`);
    setParams({ groupId: groupId });
  };
  const getGroups = () => {
    if (authVerify(userReducerState?.token)) {
      getAllGroups(
        userReducerState.token,
        () => {
          setLoad(true);
        },
        (data) => {
          dispatch(setGroups(data?.data || []));
          setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  useEffect(() => {
    setgroups(
      basicReducerState?.groups?.map((group) => ({
        ...group,
        applicationId: group?.applicationId?.split(",") || [],
      })) || []
    );
    if (selectAplication === -1) {
      setFilteredGroups(
        basicReducerState?.groups?.map((group) => ({
          ...group,
          applicationId: group?.applicationId?.split(",") || [],
        })) || []
      );
    } else {
      setFilteredGroups(
        groups
          ?.filter((group) => group?.applicationId === selectAplication)
          ?.map((group) => ({
            ...group,
            applicationId: group?.applicationId?.split(",") || [],
          })) || []
      );
    }
    setLoad(false);
  }, [basicReducerState?.groups]);

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <div className={classes.groupsContainer}>
      <Loading load={load} />

      <>
        <NewGroup
          open={openNewGroupDialog}
          onClose={() => setOpenNewGroupDialog(false)}
          filteredGroups={filteredGroups}
          setFilteredGroups={setFilteredGroups}
          selectAplication={selectAplication}z
          setSelectApplication={setSelectApplication}
        />

        <UploadFile
          open={openGroupFileDialog}
          onClose={() => {
            setOpenGroupFileDialog(false);
            setFile(null);
          }}
          onUpload={() => {}}
          file={file}
          setFile={setFile}
          disableCondition={!file}
        >
          <Autocomplete
            multiple
            size="small"
            disableCloseOnSelect
            filterSelectedOptions
            style={{ fontSize: 12 }}
            // value={}
            // onChange={(e, users) => {
            // }}
            options={basicReducerState?.applications}
            getOptionLabel={(option) => option?.name}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />

                <Typography style={{ fontSize: 12 }}>{option?.name}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Applications"
                style={{ fontSize: 12 }}
              />
            )}
          />
        </UploadFile>

        <DeletionMessageBox
          open={deletingGroup ? true : false}
          onClose={() => setDeletingGroup(null)}
          onDelete={() => {
            deleteGroup(deletingGroup);
          }}
          load={load}
        />

        <div className={classes.groupsHeaderContainer}>
          <Typography className={classes.groupHeadeTitle}>Groups</Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl
              variant="standard"
              size="small"
              required
              className={classes.groupsHeaderDropdown}
            >
              <InputLabel>Application name</InputLabel>
              <Select
                size="small"
                style={{ fontSize: 12 }}
                value={selectAplication}
                onChange={(e) => {
                  setSelectApplication(e.target.value);
                  if (e.target.value !== -1) {
                    setFilteredGroups(
                      groups?.filter(
                        (group) => Number(group?.applicationId[0]) === e.target.value
                      ) || []
                    );
                  } else {
                    setFilteredGroups(groups);
                  }
                }}
              >
                <MenuItem value={-1} style={{ fontSize: 12 }}>
                  All
                </MenuItem>
                {basicReducerState?.applications?.map((application, index) => (
                  <MenuItem
                    key={`${application?.id}-${index}`}
                    value={application?.id}
                    style={{ fontSize: 12 }}
                  >
                    {application?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <Tooltip title="Download template">
            <IconButton
              size="small"
              // color="primary"
              style={{ marginLeft: 4 }}
              disabled={load}
              onClick={(e) => {
                downloadFile({
                  data: groupFileHeading,
                  fileName: "cw_groups.csv",
                  fileType: "text/csv",
                });
              }}
            >
              <GetApp style={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Upload file">
            <IconButton
              size="small"
              // color="primary"
              style={{ marginLeft: 4 }}
              disabled={load}
              onClick={() => {
                setOpenGroupFileDialog(true);
              }}
            >
              <Publish style={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip> */}

            <Tooltip title="Refresh">
              <IconButton
                size="small"
                style={{ marginLeft: 4 }}
                disabled={load}
                onClick={getGroups}
              >
                <Refresh style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>

            {userFeatures?.includes(features?.ADD_GROUPS) && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.groupsHeaderAddButton}
                onClick={() => setOpenNewGroupDialog(true)}
                startIcon={<Add />}
                disabled={load}
              >
                Add
              </Button>
            )}
          </div>
        </div>

        {filteredGroups?.length === 0 ? (
          load ? null : (
            <NotFound />
          )
        ) : (
          <Grid
            container
            spacing={2}
            style={{
              height: `calc(100vh - ${appHeaderHeight} - ${groupPageHeaderHeight})`,
            }}
          >
            <Grid item xs={params?.groupId ? 6 : 12} style={{ height: "100%" }}>
              <TableContainer
                component={Paper}
                className={`${classes.groupsTableContainer} iagScroll`}
              >
                <Table
                  size="small"
                  //   className="table-sort table-arrows"
                >
                  <TableHead className={classes.groupsTableHead}>
                    <TableRow>
                      <TableCell
                        // align="center"
                        className={classes.groupsTableHeadCell}
                      >
                        Group Name
                      </TableCell>

                      <TableCell
                        // align="center"
                        className={classes.groupsTableHeadCell}
                      >
                        Application
                      </TableCell>

                      {userFeatures?.includes(features?.REMOVE_GROUPS) && (
                        <TableCell
                          align="center"
                          className={classes.groupsTableHeadCell}
                        >
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody className={`${classes.groupsTableBody} iagScroll`}>
                    {filteredGroups?.map((group, index) => {
                      return (
                        <TableRow
                          key={`${group?.id}-${index}`}
                          className={`${classes.groupsTableBodyRow} ${
                            Number(params?.groupId) === Number(group?.id) &&
                            classes.groupsTableBodyRowSelected
                          }`}
                          onClick={() => editGroup(group.id)}
                        >
                          <TableCell
                            // align="center"
                            className={classes.groupsTableBodyCell}
                          >
                            {group?.name}
                          </TableCell>

                          <TableCell
                            // align="center"
                            className={classes.groupsTableBodyCell}
                          >
                            {group?.applicationId
                              ?.map((id) => getApplicationNameById(id))
                              ?.filter((app) => app)
                              ?.join(", ")}
                          </TableCell>

                          {userFeatures?.includes(features?.REMOVE_GROUPS) && (
                            <TableCell
                              align="center"
                              className={classes.groupsTableBodyCell}
                            >
                              {/* <Tooltip title="Edit">
                              <IconButton
                                aria-label="Edit"
                                color="primary"
                                disabled={load}
                                onClick={() => editGroup(group.id)}
                              >
                                <ArrowForwardIos style={{ fontSize: 16 }} />
                              </IconButton>
                            </Tooltip> */}
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="Delete"
                                  color="secondary"
                                  disabled={load}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // deleteGroup(group.id);
                                    setDeletingGroup(group?.id);
                                  }}
                                >
                                  <Delete style={{ fontSize: 16 }} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {params?.groupId && (
              <Grid item xs={params?.groupId ? 6 : false}>
                <GroupDetail params={params} setParams={setParams} />
              </Grid>
            )}
          </Grid>
        )}
      </>
    </div>
  );
}

export default Groups;
