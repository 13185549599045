import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  IconButton,
  FormControl,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  Divider,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillPlugFill } from "react-icons/bs";
import { HiStatusOffline, HiStatusOnline } from "react-icons/hi";
import { FaLightbulb } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  Add,
  Refresh,
  MoreVert,
  Delete,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { features, roles, systemImages } from "../../Data/data";
import { Autocomplete } from "@material-ui/lab";
import { getAllApplications } from "../../Action/action";
import {
  setApplications,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import Loading from "../Loading";
import NotFound from "../NotFound";
import NewApplication from "./NewApplication";
import NewApi from "./NewApi";
import { applicationFileHeading, downloadFile } from "../../Utility/file";
import UploadFile from "../UploadFile";
import { authVerify } from "../../Utility/auth";
import moment from "moment";
import NewItem from "./NewItem";
import ApplicationDetail from "./ApplicationDetail";
import CustomCard from "./CustomCard";
import DeletionMessageBox from "../DeletionMessageBox";
import { toastMessage } from "../../Utility/config";
import SystemDetails from "./SystemDetails";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  applicationFooterButton,
  applicationPageHeaderHeight,
  sidebarWidth,
  appHeaderHeight,
} from "../../Data/cssConstant";
import { sorting } from "../../Utility/basic";
import { CSVLink } from "react-csv";

const useStyle = makeStyles((theme) => ({
  systemContainer: {
    border: `0.5px solid ${theme.palette.divider}`,
    borderRadius: 8,
    padding: 10,
    position: "relative",
    "&:hover": {
      "& $systemOwnerEmailEditButton": {
        display: "flex",
      },
    },
  },
  systemInfoContainer: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: 4,
  },
  systemImage: {
    width: 38,
    height: 32,
    objectFit: "contain",
  },
  systemInfoContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingLeft: 8,
  },
  systemName: {
    fontSize: 14,
    fontWeight: 500,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  systemUniqueId: {
    fontSize: 14,
    textTransform: "uppercase",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "90%",
  },
  systemBulbIcon: {
    fontSize: 20,
    color: theme.palette.text.secondary,
  },
  systemBulbIconOnline: {
    color: theme.palette.success.main,
  },
  systemBulbIconOffline: {
    color: theme.palette.error.main,
  },
  systemOwnerEmailLabel: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    paddingTop: 6,
  },
  systemOwnerEmailEditButton: {
    display: "none",
    // margin: "-4px",
    padding: 0,
    fontSize: 10,
  },
  systemOwnerEmailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  systemOwnerEmailText: {
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "80%",
  },
  systemOwnerExtraEmailCount: {
    fontSize: 14,
    cursor: "pointer",
  },

  editSystemDetailsDialogContent: {
    padding: "0px 10px 0px 10px",
    position: "relative",
  },
  editSystemDetailsDialogTitle: {
    padding: 8,
    borderBottom: "1px solid #d9d9d9",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
  },
  editSystemDetailsDialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  editSystemDetailsDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },
  editSystemDetailsSystemCheckbox: {
    "&::span": {
      fontSize: 6,
    },
  },

  systemsReportDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
    backgroundColor: "#FAFCFF",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
    "& h2": {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  systemsReportDialogContent: {
    padding: "16px 32px",
  },
  systemsReportDialogContentLabel: {
    fontSize: 14,
  },
  systemsReportDialogContentValue: {
    fontSize: 16,
    textAlign: "end",
  },
  systemsReportDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
    boxShadow: "0px -4px 9px #D8D8D8",
    paddingRight: 20,
  },

  systemsContainer: {
    width: `calc(100vw - ${sidebarWidth})`,
    height: `calc(100vh - ${appHeaderHeight})`,
    position: "relative",
  },
  systemsHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    boxShadow: "0px 2px 10px rgba(192, 192, 192, 0.25)",
    padding: "24px 16px 4px 16px",
    height: applicationPageHeaderHeight,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  systemsHeader: {
    fontSize: 24,
    fontWeight: "bold",
  },
  systemsList: {
    marginTop: 0,
    paddingLeft: 8,
    height: `calc(100vh - ${appHeaderHeight} - ${applicationPageHeaderHeight} - ${applicationFooterButton})`,
    width: "100%",
  },
  bottomContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "0px -4px 9px #D8D8D8",
    backgroundColor: theme.palette.background.default,
    padding: "6px 10px",
    zIndex: 99,
    marginTop: 14,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: applicationFooterButton,
  },
}));

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#F1F5FE",
    color: theme.palette.primary.main,
    maxWidth: 285,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 16,
  },
  arrow: {
    backgroundColor: "#F1F5FE",
  },
}))(Tooltip);

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grab", marginRight: 8 }}>::</span>
));

export const System = ({
  system,
  onDelete,
  onClick,
  selected,
  load,
  onUpdate,
  connection,
}) => {
  // console.log(system?.name, connection);
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const userRoles = userReducerState?.entitiesAndActivities?.roles;

  return (
    <Paper className={classes.systemContainer}>
      <div className={classes.systemInfoContainer}>
        <DragHandle />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {system?.imageType && system?.applicationImage && (
            <img
              className={classes.systemImage}
              alt={system?.systemName}
              src={
                system?.imageType && system?.applicationImage
                  ? `data:${system?.imageType};base64,${system?.applicationImage}`
                  : ``
              }
            />
          )}

          <div className={classes.systemInfoContent}>
            <Typography
              className={classes.systemName}
              onClick={onClick}
            >{`${system?.name} (id: ${system?.id})`}</Typography>

            {system?.uniqueIds?.length > 0 ? (
              <Tooltip
                title={system?.uniqueIds?.join(", ") || "-"}
                placement="bottom-start"
              >
                <Typography className={classes.systemUniqueId}>
                  {system?.uniqueIds?.join(", ") || "-"}
                </Typography>
              </Tooltip>
            ) : (
              <Typography className={classes.systemUniqueId}>
                {system?.uniqueIds?.join(", ") || "-"}
              </Typography>
            )}
          </div>
        </div>
      </div>

      {/* <FaLightbulb
          className={`${classes.systemBulbIcon} ${
            connection === true
              ? classes.systemBulbIconOnline
              : connection === false
              ? classes.systemBulbIconOffline
              : ""
          }`}
        />
      </div> */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className={classes.systemOwnerEmailLabel}>
          Owner(s) Email Id
        </Typography>

        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            size="small"
            color="primary"
            onClick={onUpdate}
            disabled={load}
            className={classes.systemOwnerEmailEditButton}
          >
            <MdEdit style={{ fontSize: 18 }} />
          </IconButton>

          {system?.applicationType?.toLowerCase() === "cw" && (
            <IconButton
              size="small"
              color="secondary"
              onClick={onDelete}
              disabled={load}
              className={classes.systemOwnerEmailEditButton}
            >
              <Delete style={{ fontSize: 18 }} />
            </IconButton>
          )}
        </div> */}
      </div>

      <div className={classes.systemOwnerEmailContainer}>
        <Typography className={classes.systemOwnerEmailText}>
          {system?.applicationOwnerEmails?.[0] || "-"}
        </Typography>

        {system?.applicationOwnerEmails?.length - 1 > 0 && (
          <HtmlTooltip
            title={system?.applicationOwnerEmails
              ?.slice(1)
              ?.map((email, index) => (
                <Typography
                  key={index}
                  color="primary"
                  style={{ fontSize: 12 }}
                >
                  {email}
                </Typography>
              ))}
          >
            <Typography className={classes.systemOwnerExtraEmailCount}>
              +{system?.applicationOwnerEmails?.length - 1}
            </Typography>
          </HtmlTooltip>
        )}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography style={{ fontSize: 12 }}>Total Users: </Typography>

        <Typography style={{ fontSize: 12 }}>
          {system?.userCount || 0}
          {/* {Math.floor(Math.random() * (20 - 10) + 10)} */}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "1%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {((system?.applicationOwnerEmails?.includes(
            userReducerState?.user?.email
          ) &&
            userRoles?.includes(roles?.SYSTEM_OWNER)) ||
            userRoles?.includes(roles?.SUPER_ADMIN)) && (
            <IconButton
              size="small"
              color="primary"
              onClick={onUpdate}
              disabled={load}
              className={classes.systemOwnerEmailEditButton}
            >
              <MdEdit style={{ fontSize: 18 }} />
            </IconButton>
          )}

          {system?.applicationType?.toLowerCase() === "cw" &&
            userFeatures?.includes(features?.REMOVE_SYSTEMS) && (
              <IconButton
                size="small"
                color="secondary"
                onClick={onDelete}
                disabled={load}
                className={classes.systemOwnerEmailEditButton}
              >
                <Delete style={{ fontSize: 18 }} />
              </IconButton>
            )}
        </div>

        {connection?.[system?.name] === true ? (
          <HiStatusOnline
            className={`${classes.systemBulbIcon} ${classes.systemBulbIconOnline}`}
          />
        ) : (
          <HiStatusOffline
            className={`${classes.systemBulbIcon} ${
              connection?.[system?.name] === false &&
              classes.systemBulbIconOffline
            }`}
          />
        )}
      </div>
    </Paper>
  );
};

const SystemsReport = ({ open, onClose, connections }) => {
  const classes = useStyle();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.systemsReportDialogTitle}>
        System Check Report
      </DialogTitle>

      <DialogContent className={classes.systemsReportDialogContent}>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentLabel}>
              Technical User
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentValue}>
              IWA Admin
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentLabel}>
              Total Systems Checked
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentValue}>
              {Object?.keys(connections)?.length || 0}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentLabel}>
              Systems Online
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentValue}>
              {Object?.values(connections)?.filter(
                (connection) => connection === true
              )?.length || 0}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentLabel}>
              Systems Offline
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentValue}>
              {Object?.values(connections)?.filter(
                (connection) => connection === false
              )?.length || 0}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentLabel}>
              Overall System Health
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.systemsReportDialogContentValue}>
              {`${(
                (Object?.values(connections)?.filter(
                  (connection) => connection === true
                )?.length /
                  Object.keys(connections)?.length) *
                100
              )?.toFixed(2)}%`}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.systemsReportDialogActions}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={onClose}
          style={{ textTransform: "capitalize" }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditSystemDetails = ({ open, onClose }) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [operation, setOperation] = useState("add");
  const [ownersMailIds, setOwnersMailIds] = useState([]);
  const [selectedSystems, setSelectedSystems] = useState([]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle className={classes.editSystemDetailsDialogTitle}>
        <div className={classes.editSystemDetailsDialogHeader}>
          <Typography style={{ fontSize: 16 }}>Edit Details</Typography>

          <IconButton
            size="small"
            onClick={() => {
              setOperation("add");
              setOwnersMailIds([]);
              setSelectedSystems([]);
              onClose();
            }}
          >
            <AiOutlineClose />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className={classes.editSystemDetailsDialogContent}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "bold",
            marginTop: 6,
          }}
        >
          Owner's mail ID
        </Typography>

        <FormControl
          component="fieldset"
          style={{ marginTop: 0, width: "100%" }}
        >
          <RadioGroup
            aria-label=""
            name=""
            value={operation}
            onChange={(e) => setOperation(e.target.value)}
          >
            <FormControlLabel
              value="add"
              control={<Radio color="primary" />}
              label="Add"
              style={{ flex: 1 }}
            />
            <FormControlLabel
              value="delete"
              control={<Radio color="primary" />}
              label="Delete"
              style={{ flex: 1 }}
            />
          </RadioGroup>
        </FormControl>

        <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          disableCloseOnSelect
          filterSelectedOptions
          value={ownersMailIds}
          onChange={(e, users) => {
            setOwnersMailIds(users);
          }}
          options={basicReducerState?.users}
          getOptionLabel={(option) => option.userName}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>
                {option.userName}
              </Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Owners"
              style={{ fontSize: 12 }}
            />
          )}
        />

        <Divider style={{ margin: "10px 0px 10px 0px" }} />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  selectedSystems?.length ===
                  basicReducerState?.applications?.length
                }
                onChange={(e) =>
                  setSelectedSystems(
                    selectedSystems?.length !==
                      basicReducerState?.applications?.length
                      ? basicReducerState?.applications?.map(
                          (system) => system?.name
                        )
                      : []
                  )
                }
                name="selectSystem"
                color="primary"
              />
            }
            label="Select System"
            size="small"
            className={classes.editSystemDetailsSystemCheckbox}
          />

          <Typography style={{ fontSize: 14, color: "#818181" }}>
            {`${selectedSystems?.length || 0}/${
              basicReducerState?.applications?.length || 0
            } selected`}
          </Typography>
        </div>

        <div style={{ maxHeight: "35vh" }} className="iagScroll">
          {basicReducerState?.applications?.map((system) => (
            // system?.systemType?.toLowerCase() !== "cw" &&
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSystems?.includes(system?.systemName)}
                    onChange={(e) => {
                      selectedSystems?.includes(system?.systemName)
                        ? setSelectedSystems(
                            selectedSystems?.filter(
                              (s_system) => s_system !== system?.systemName
                            )
                          )
                        : setSelectedSystems([
                            ...selectedSystems,
                            system?.systemName,
                          ]);
                    }}
                    name={system?.systemName}
                    color="primary"
                  />
                }
                label={system?.systemName}
                size="small"
                className={classes.editSystemDetailsSystemCheckbox}
              />

              <IconButton size="small" color="primary">
                <BsInfoCircle style={{ fontSize: 14 }} />
              </IconButton>
            </div>
          ))}
        </div>

        <Divider style={{ margin: "4px 0px" }} />
      </DialogContent>

      <DialogActions className={classes.editSystemDetailsDialogActions}>
        {operation === "add" ? (
          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ textTransform: "capitalize" }}
          >
            Add
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ textTransform: "capitalize" }}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const Systems = () => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const dispatch = useDispatch();
  const [params, setParams] = useState({});

  const [load, setLoad] = useState(false);
  const [applications, setapplications] = useState([]);
  const [cwApplication, setCWApplication] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const [systemReportDialog, setSystemReportDialog] = useState(false);
  const [openEditSystemsDetail, setOpenEditSystemsDetail] = useState(false);

  const [updatingApplication, setUpdatingApplication] = useState(null);

  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const [applicationFile, setApplicationFile] = useState(null);
  const [addNewApplicationDialog, setAddNewApplicationDialog] = useState(false);
  const [openApplicationFileDialog, setOpenApplicationFileDialog] =
    useState(false);
  const [deletingApplication, setDeletingApplication] = useState(null);

  const initialConnectionState = basicReducerState?.applications
    ?.filter(
      (application) => application?.applicationType?.toLowerCase() !== "cw"
    )
    ?.map((application) => undefined);
  const [connections, setConnections] = useState(initialConnectionState);

  const getApplicationNameById = (appId) => {
    const applicationName = basicReducerState?.applications?.find(
      (application) => Number(application?.id) === Number(appId)
    );
    return applicationName?.name || null;
  };
  const getApplications = () => {
    if (authVerify(userReducerState?.token)) {
      getAllApplications(
        userReducerState.token,
        () => {
          setLoad(true);
        },
        (data) => {
          dispatch(setApplications(data?.data || []));
          setLoad(false);
        },
        (err) => {
          setLoad(false);
        }
      );
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const handleDeleteApplication = (applicationId) => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const disableApplicationUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/deactivate?id=${applicationId}`;
      // const disableApplicationUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/deactivate?id=${applicationId}`;
      const disableApplicationRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      fetch(disableApplicationUrl, disableApplicationRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          dispatch(
            setApplications(
              basicReducerState?.applications?.filter(
                (application) => application?.id !== Number(applicationId)
              ) || []
            )
          );
          setDeletingApplication(null);

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? toastMessage?.APPLICATION_DELETED
                : toastMessage?.SOMETHING_WENT_WRONG,
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const uploadApplicationsFile = () => {
    if (authVerify(userReducerState.token)) {
      if (!applicationFile) {
        console.log("no file found");
        return;
      }
      setLoad(true);
      const url = `${process.env.REACT_APP_IWAServices}/api/v1/applications/addApplicationsUsingCsv`;
      // const url = `${process.env.REACT_APP_IWAServices}/api/v1/applications/addApplicationsUsingCsv`;
      let formData = new FormData();
      formData.append("file", applicationFile);
      formData.append("name", applicationFile.name);
      const requestParam = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: formData,
      };
      fetch(url, requestParam)
        .then((res) => {
          console.log(res);
        })
        .then((data) => {
          setLoad(false);
          setOpenApplicationFileDialog(false);
          setApplicationFile(null);
          getApplications();

          dispatch(
            setResponseMessage({
              open: true,
              status: "success",
              message: "Application file uploaded successfully",
            })
          );
        })
        .catch((err) => {
          setLoad(false);
          dispatch(
            setResponseMessage({
              open: true,
              status: "error",
              message: "Something went wrong",
            })
          );
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const testConnection = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const applicationsName = basicReducerState?.applications
        ?.filter(
          (application) => application?.applicationType?.toLowerCase() !== "cw"
        )
        ?.map((application) => application?.name);
      const testConUrl = `${process.env.REACT_APP_IWAServices}/api/v1/AccessAndRevoke/testConnection`;
      // const testConUrl = `${process.env.REACT_APP_IWAServices}/api/v1/AccessAndRevoke/testConnection`;
      const testConRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify([...applicationsName]),
      };
      fetch(testConUrl, testConRequestParam)
        .then((res) => res.json())
        .then((data) => {
          setLoad(false);
          setConnections(data?.data);
          setSystemReportDialog(true);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (authVerify(userReducerState?.token)) {
      var newapplications = [...applications];
      var x = applications[oldIndex];
      newapplications.splice(oldIndex, 1);
      newapplications.splice(newIndex, 0, x);
      const dragAndDropUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/systemOrder`;
      // const dragAndDropUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/systemOrder`;
      const dragAndDropPayload = newapplications?.map((app) => Number(app?.id));
      const dragAndDropRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState?.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(dragAndDropPayload),
      };
      // console.log(dragAndDropPayload);
      fetch(dragAndDropUrl, dragAndDropRequestParam)
        .then((res) => res.json())
        .then((data) => {
          if (data?.status) {
            setapplications([...newapplications]);
            dispatch(setApplications([...newapplications]));
          }

          dispatch(
            setResponseMessage({
              open: true,
              status: data?.status ? "success" : "error",
              message: data?.status
                ? "System Order changed successfully"
                : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          dispatch(
            setResponseMessage({
              open: true,
              status: "error",
              message: "Something went wrong",
            })
          );
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const isCWSystem = (appId) => {
    return (
      basicReducerState?.applications
        ?.find((app) => app?.id === appId)
        ?.applicationType?.toLowerCase() === "cw"
    );
  };

  useEffect(() => {
    setapplications(basicReducerState?.applications);
  }, [basicReducerState?.applications]);

  useEffect(() => {
    getApplications();
  }, []);

  const SortableItem = SortableElement(({ application, index }) => (
    <Grid key={application?.id} item xs={12} sm={6} md={4} lg={3}>
      <System
        key={`${application?.id}-${index}`}
        system={application}
        onDelete={(e) => {
          e.stopPropagation();
          setDeletingApplication(application?.id);
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (application?.id) {
            setSelectedApplication(application?.id);
          }
        }}
        selected={
          params?.applicationId &&
          application?.id === Number(params?.applicationId)
        }
        load={load}
        onUpdate={(e) => {
          e.stopPropagation();
          setIsUpdateItem(true);
          setAddNewApplicationDialog(true);
          setUpdatingApplication({
            ...application,
            applications:
              application?.applications
                ?.map((mappedAppId) =>
                  getApplicationNameById(
                    Number(mappedAppId),
                    basicReducerState?.applications
                  )
                )
                ?.filter((applicationName) => applicationName) || [],
          });
        }}
        connection={connections}
      />
    </Grid>
  ));
  const SortableList = SortableContainer(({ applications }) => {
    return (
      <Grid
        container
        spacing={2}
        className={`${classes.systemsList} iagScroll`}
      >
        {/* 
          ?.filter(
            (application) =>
              application?.applicationType?.toLowerCase() !== "cw"
          ) */}
        {/* {console.log(applications?.sort(sorting("name",1)))} */}
        {applications?.map((application, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            application={application}
          />
        ))}
      </Grid>
    );
  });

  return (
    <div className={`${classes.systemsContainer}`}>
      <Loading load={load} />

      <NewApplication
        open={addNewApplicationDialog}
        onClose={() => {
          setAddNewApplicationDialog(false);
          setIsUpdateItem(false);
        }}
        title={`${isUpdateItem ? "Update" : "New"} Application`}
        update={isUpdateItem}
        updatingApplication={updatingApplication}
      />

      <UploadFile
        open={openApplicationFileDialog}
        onClose={() => {
          setOpenApplicationFileDialog(false);
          setApplicationFile(null);
        }}
        onUpload={() => {
          uploadApplicationsFile();
        }}
        file={applicationFile}
        setFile={setApplicationFile}
        disableCondition={!applicationFile}
        load={load}
      />

      <DeletionMessageBox
        open={deletingApplication ? true : false}
        onClose={() => setDeletingApplication(null)}
        onDelete={() => {
          handleDeleteApplication(deletingApplication);
        }}
        load={load}
      />

      <SystemsReport
        open={systemReportDialog}
        onClose={() => {
          setSystemReportDialog(false);
        }}
        connections={connections}
      />

      {selectedApplication ? (
        isCWSystem(selectedApplication) ? (
          <ApplicationDetail
            selectedApplication={selectedApplication}
            setSelectedApplication={setSelectedApplication}
          />
        ) : (
          <SystemDetails
            selectedApplication={selectedApplication}
            setSelectedApplication={setSelectedApplication}
          />
        )
      ) : (
        <>
          <div className={classes.systemsHeaderContainer}>
            <Typography className={classes.systemsHeader}>
              Application Master Data
            </Typography>

            <div style={{ display: "flex", alignItem: "center" }}>
              {/* <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={cwApplication}
                onClick={(e) => {
                  setCWApplication(e.target.checked);
                }}
              />
            }
            label="Cherryworks Apps"
          /> */}

              <IconButton
                size="small"
                onClick={() => {
                  getApplications();
                }}
              >
                <Refresh style={{ fontSize: 20 }} />
              </IconButton>

              {userFeatures?.includes(features?.ADD_SYSTEMS) && (
                <IconButton
                  size="small"
                  aria-controls="menu"
                  onClick={(e) => {
                    setAnchorElMenu(e.currentTarget);
                  }}
                >
                  <MoreVert style={{ fontSize: 20 }} />
                </IconButton>
              )}

              {userFeatures?.includes(features?.ADD_SYSTEMS) && (
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  //   className={classes.applicationHeadeAddButton}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setAddNewApplicationDialog(true);
                  }}
                  startIcon={<Add />}
                  disabled={load}
                >
                  Add New System
                </Button>
              )}
            </div>

            <Menu
              id="menu"
              anchorEl={anchorElMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ width: 230 }}
              open={Boolean(anchorElMenu)}
              onClose={() => {
                setAnchorElMenu(null);
              }}
            >
              <MenuItem
                aria-controls="subMenu"
                onClick={(e) => {
                  setAnchorElMenu(null);
                }}
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "space-between",
                }}
              >
                <CSVLink data={[applicationFileHeading]}>
                  <Typography style={{ fontSize: 14, marginRight: 20 }}>
                    Download template
                  </Typography>
                </CSVLink>
              </MenuItem>

              <MenuItem
                aria-controls="subMenu"
                onClick={(e) => {
                  setOpenApplicationFileDialog(true);
                  setAnchorElMenu(null);
                }}
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ fontSize: 14, marginRight: 20 }}>
                  Upload template
                </Typography>
              </MenuItem>
            </Menu>
          </div>

          <SortableList
            applications={applications}
            onSortEnd={onSortEnd}
            useDragHandle
            axis="xy"
          />
        </>
      )}

      {!selectedApplication && (
        <div className={classes.bottomContainer}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              textTransform: "capitalize",
              fontSize: 16,
              margin: 0,
              padding: 4,
            }}
            onClick={() => {
              testConnection();
            }}
          >
            Test Connection
          </Button>
        </div>
      )}
    </div>
  );
};

export default Systems;
