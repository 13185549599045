export const completeEntities = {
  USERMODULE: "UserModule",
  ROLEMODULE: "RoleModule",
  KEYCLOAKMODULE: "KeycloakModule",
  APPLICATIONMODULE: "ApplicationModule",
  LICENSEMODULE: "LicenseModule",
};

export const completeFeatures = {
  ADDUSER: "AddUser",
  DELETEUSER: "DeleteUser",
  UPDATEUSERDETAIL: "UpdateUserDetail",
  CREATEROLE: "CreateRole",
  DELETEROLE: "DeleteRole",
  UPDATEROLEDETAIL: "UpdateRoleDetail",
  CREATEREALM: "CreateRealm",
  UPDATEREALM: "UpdateRealm",
  DELETEREALM: "DeleteRealm",
  CREATELICENSE: "CreateLicense",
  CREATEAPPLICATION: "CreateApplication",
  DELETEAPPLICATION: "DeleteApplication",
  CREATEENTITY: "CreateEntity",
  DELETEENTITY: "DeleteEntity",
  CREATEACTIVITY: "CreateActivity",
  DELETEACTIVITY: "DeleteActivity",
};

export const toastMessage = {
  SOMETHING_WENT_WRONG: "Something went wrong",

  USERS_ADDED: "Users added successfully",
  USER_DELETED: "User deleted successfully",
  USER_DISPLAY_NAME_UPDATED: "Display name updated successfully",
  USER_FILE_UPLOADED: "User file uploaded successfully",

  USER_ASSOCIATED_GROUP_REMOVED:
    "Associated group to user removed successfully",
  USER_ASSIGNED_ROLE_REMOVED: "Assigned role to user removed successfully",
  USER_DEFAULT_FEATURE_REMOVED: "Featue of user removed successfully",
  USER_ADDITIONAL_FEATURE_REMOVED:
    "Additional feature of user removed successfully",
  USER_ADDITIONAL_FEATURE_ASSIGNED:
    "Additional feature of user assigned successfully",
  USER_DETAILS_UPDATED: "User details updated successfully",

  ROLE_CREATED: "Role created successfully",
  ROLE_DELETED: "Role deleted successfully",
  ROLE_DEFAULT_UPDATED: "Default role updated successfully",
  ROLE_FILE_UPLOADED: "Role file uploaded successfully",

  ROLE_ASSOCIATED_ROLE_REMOVED: "Associated role removed successfully",
  ROLE_ASSIGNED_USER_REMOVED: "Assigned user to role removed successfully",
  ROLE_ASSIGNED_FEATURE_REMOVED:
    "Assigned feature to role removed successfully",
  ROLE_ASSIGNED_API_REMOVED: "Assigned api to role removed successfully",
  ROLE_DETAILS_UPDATED: "Role details updated successfully",

  LICENSE_CREATED: "License created successfully",
  LICENSE_UPDATED: "License updated successfully",

  GROUP_CREATED: "Group created successfully",
  GROUP_DELETED: "Group deleted successfully",

  GROUP_ASSOCIATED_USER: "User associated to group removed successfully",
  GROUP_ASSIGNED_ROLE: "Role assigned to group removed successfully",
  GROUP_DETAILS_UPDATED: "Group details updated successfully",

  APPLICATION_CREATED: "Application created successfully",
  APPLICATION_DELETED: "Application deleted successfully",
  APPLICATION_UPDATED: "Application updated successfully",
  APPLICATION_FILE_UPLOADED: "Application file uploaded successfully",

  MODULE_CREATED: "Module created successfully",
  MODULE_DELETED: "Module deleted successfully",
  MODULE_UPDATED: "Module updated successfully",
  MODULE_FILE_UPLOADED: "Module file uploaded successfully",

  API_CREATED: "Api created successfully",
  API_DELETED: "Api deleted successfully",
  API_UPDATED: "Api updated successfully",
  API_FILE_UPLOADED: "Api file uploaded successfully",

  FEATURE_CREATED: "Feature created successfully",
  FEATURE_DELETED: "Feature deleted successfully",
  FEATURE_UPDATED: "Feature updated successfully",
  FEATURE_FILE_UPLOADED: "Feature file uploaded successfully",

  PAYLOAD_CREATED: "Payload created successfully",
  PAYLOAD_DELETED: "Payload deleted successfully",
  PAYLOAD_UPDATED: "Payload updated successfully",
  PAYLOAD_FILE_UPLOADED: "Payload file uploaded successfully",
};

export const NOT_HAVE_LICENSE =
  "You don't have license. Please contact to System Administrator.";
export const NOT_HAVE_PERMISSION =
  "You don't have access to this application. Please contact to System Administratior.";
export const MAX_LOGIN_LIMIT = "Maximum login exceeded.";

export const APPLICATION_NAME_EXISTS = "Application name already exists";
export const MODULE_NAME_EXISTS = "Module name already exists";
export const API_NAME_EXISTS = "Api name already exists";
export const FEATURE_NAME_EXISTS = "Feature name already exists";
export const PAYLOAD_NAME_EXISTS = "Payload name already exists";
export const ROLE_NAME_EXISTS = "Role name already exists";
export const GROUP_NAME_EXISTS = "Group name already exists";

export const MODULES_TAB_NAME = "Modules";
export const APIS_TAB_NAME = "Apis";

export const apiType = [
  "GET",
  "POST",
  "PUT",
  "PATCH",
  "DELETE",
  "COPY",
  "HEAD",
  "OPTIONS",
  "LINK",
  "UNLINK",
  "PURGE",
  "LOCK",
  "UNLOCK",
  "PROPFIND",
  "VIEW",
];

export const applicationIds = {
  SAP_BTP: 67,
};
