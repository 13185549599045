import { authVerify } from "../Utility/auth";
import store from "../Redux/store";

export const getAllRequests = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    const getAllRequestsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests/usersEmail`;
    // const getAllRequestsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/requests/usersEmail`;
    const getAllRequestsRequestParam = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fInitial();
    fetch(getAllRequestsUrl, getAllRequestsRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    console.log("token not valid");
    // window.location.reload(0);
  }
};

export const getAllUsers = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    const getAllUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users`;
    // const getAllUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users`;
    const getAllUsersRequestParam = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fInitial();
    fetch(getAllUsersUrl, getAllUsersRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllInactiveUsers = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    const getAllInactiveUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/readAllDeactivatedUser`;
    // const getAllInactiveUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/readAllDeactivatedUser`;
    const getAllInactiveUsersRequestParam = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fInitial();
    fetch(getAllInactiveUsersUrl, getAllInactiveUsersRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getIdpUsers = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getIdpUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/SAP-IAS/getUsers`;
    // const getIdpUsersUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/SAP-IAS/getUsers`;
    const getIdpUsersRequestParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getIdpUsersUrl, getIdpUsersRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllRoles = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllRolesUrl = `${process.env.REACT_APP_IWAServices}/api/v2/roles`;
    // const getAllRolesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles`;
    const getAllRolesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getAllRolesUrl, getAllRolesRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllApplications = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllApplicationsUrl = `${process.env.REACT_APP_IWAServices}/api/v2/applications`;
    // const getAllApplicationsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications`;
    const getAllApplicationsRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getAllApplicationsUrl, getAllApplicationsRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllEntities = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllEntitiesUrl = `${process.env.REACT_APP_IWAServices}/api/v2/entities`;
    // const getAllEntitiesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/entities`;
    const getAllEntitiesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getAllEntitiesUrl, getAllEntitiesRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllActivities = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllActivitiesUrl = `${process.env.REACT_APP_IWAServices}/api/v2/activities`;
    // const getAllActivitiesUrl = `${process.env.REACT_APP_IWAServices}/api/v1/activities`;
    const getAllActivitiesRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getAllActivitiesUrl, getAllActivitiesRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllApis = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllApisUrl = `${process.env.REACT_APP_IWAServices}/api/v2/apiPayload`;
    // const getAllApisUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload`;
    const getAllApisRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getAllApisUrl, getAllApisRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllPayloads = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getAllPayloadsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/payload`;
    // const getAllPayloadsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/apiPayload/payload`;
    const getAllPayloadsRequestParams = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getAllPayloadsUrl, getAllPayloadsRequestParams)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllGroups = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    // const getGroupsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups`;
    const getGroupsUrl = `${process.env.REACT_APP_IWAServices}/api/v2/groups`;
    const getGroupsRequestParam = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getGroupsUrl, getGroupsRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllRoleTemplates = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getGroupsUrl = `${process.env.REACT_APP_IWAServices}/api/v1/roles/roleTemplates`;
    const getGroupsRequestParam = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        applicationId: process.env.REACT_APP_IWAId,
      },
    };
    fetch(getGroupsUrl, getGroupsRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    // window.location.reload(0);
  }
};

export const getAllLicenses = (token, fInitial, fSuccess, fError) => {
  if (authVerify(token)) {
    fInitial();
    const getLicensesUrl = `${process.env.REACT_APP_IWAServices}/api/v2/license`;
    const getLicensesRequestParam = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(getLicensesUrl, getLicensesRequestParam)
      .then((res) => res.json())
      .then((data) => {
        fSuccess(data);
      })
      .catch((err) => {
        fError(err);
      });
  } else {
    window.location.reload(0);
  }
};
