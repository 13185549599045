import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
// import { allApplications, allRoles, selectedUserDetail } from "../../Data/data";
import {
  Add,
  Delete,
  Remove,
  Close,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@material-ui/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { authVerify } from "../../Utility/auth";
import Loading from "../Loading";
import {
  setUsers,
  setResponseMessage,
} from "../../Redux/Reducers/basicReducer";
import {
  findApplicationById,
  findUserById,
  // findActivityById,
  // findEntityById,
  findRoleById,
  showFeatureNumber,
} from "../../Utility/basic";
import { Autocomplete } from "@material-ui/lab";
import DeletionMessageBox from "../DeletionMessageBox";
import { features, roles } from "../../Data/data";
import { setIsSessionExpired } from "../../Redux/Reducers/userReducer";
import {
  appHeaderHeight,
  buttonHeight,
  crossIconContainerHeight,
  userDetailPageCss,
  userPageHeaderHeight,
} from "../../Data/cssConstant";

const useStyle = makeStyles((theme) => ({
  userInfoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    height: `calc(100vh - ${appHeaderHeight} - ${userPageHeaderHeight} - ${crossIconContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${userDetailPageCss?.footerHeight} - 18px)`,
  },
  userInfoItemContainer: {
    margin: 4,
    alignItems: "center",
  },
  userInfoContainerLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  userInfoContainerText: {
    fontSize: 12,
  },

  newUserAssociatedGroupDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newUserAssociatedGroupDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  userAssociatedGroupsTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${userPageHeaderHeight} - ${crossIconContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${buttonHeight} - ${userDetailPageCss?.footerHeight} - 26px)`,
    // overflow: "overlay",
    width: "100%",
  },
  userAssociatedGroupsTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  userAssociatedGroupsTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  userAssociatedGroupsTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  userAssociatedGroupsTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  userAssociatedGroupsTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  userAssociatedGroupsBottomAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },

  newUserAssignedRoleDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newUserAssignedRoleDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  userAssignedRolesTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${userPageHeaderHeight} - ${crossIconContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${buttonHeight} - ${userDetailPageCss?.footerHeight} - 26px)`,
    // overflow: "overlay",
    width: "100%",
  },
  userAssignedRolesTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  userAssignedRolesTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  userAssignedRolesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  userAssignedRolesTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  userAssignedRolesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  userAssignedRolesBottomAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },

  newUserAssignedLicenseDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newUserAssignedLicenseDialogContentMsg: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
  },
  newUserAssignedLicenseDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  userAssignedLicensesTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${userPageHeaderHeight} - ${crossIconContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${buttonHeight} - ${userDetailPageCss?.footerHeight} - 26px)`,
    // overflow: "overlay",
    width: "100%",
  },
  userAssignedLicensesTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  userAssignedLicensesTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  userAssignedLicensesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  userAssignedLicensesTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  userAssignedLicensesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  userAssignedLicensesBottomAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },

  newUserAdditionalFeatureDialogTitle: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d9d9d9",
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  newUserAdditionalFeatureDialogActions: {
    height: "3rem",
    borderTop: "1px solid #d9d9d9",
  },

  userAdditionalFeaturesTableContainer: {
    height: `calc(100vh - ${appHeaderHeight} - ${userPageHeaderHeight} - ${crossIconContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${userDetailPageCss?.footerHeight} - 20px)`,
    // overflow: "overlay",
    width: "100%",
  },
  userAdditionalFeaturesTableHead: {
    backgroundColor: theme.palette.text.primary,
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  userAdditionalFeaturesTableHeadCell: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
    fontSize: 14,
  },
  userAdditionalFeaturesTableBody: {
    height: "100%",
    // overflow: "overlay",
  },
  userAdditionalFeaturesTableBodyRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  userAdditionalFeaturesTableBodyCell: {
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  userAdditionalFeaturesBottomAddButton: {
    margin: "4px 10px",
    textTransform: "capitalize",
    height: buttonHeight,
  },

  userDetailContainer: {
    flexDirection: "column",
    height: "100%",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.palette.background.paper,
    margin: 0,
    padding: 0,
    // overflow: "overlay",
    position: "relative",
  },
  userDetailCrossContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 10,
    paddingTop: 10,
    height: crossIconContainerHeight,
  },
  userDetailHeaderContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    height: userDetailPageCss?.tabsContainerHeight,
  },
  userDetailHeaderItem: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    cursor: "pointer",
    width: 150,
    fontSize: 14,
    marginLeft: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userDetailHeaderItemSelected: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  userDetailFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "0px 0px 9px #D8D8D8",
    padding: "8px 16px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 999,
    height: userDetailPageCss?.footerHeight,
  },
  userDetailFooterButton: {
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const UserInfo = ({ userDetail, setUserDetail }) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });

  return (
    <div className={classes.userInfoContainer}>
      <Grid container className={classes.userInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.userInfoContainerLabel}>
            Name
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.userInfoContainerText}>
            {userDetail?.firstName} {userDetail?.lastName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.userInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.userInfoContainerLabel}>
            User Id
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.userInfoContainerText}>
            {userDetail?.userId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.userInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.userInfoContainerLabel}>
            Email Id
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.userInfoContainerText}>
            {userDetail?.emailId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.userInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.userInfoContainerLabel}>
            Username
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classes.userInfoContainerText}>
            {userDetail?.userName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.userInfoItemContainer}>
        <Grid item xs={4}>
          <Typography className={classes.userInfoContainerLabel}>
            Display Name
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <div className={`inputContainer`}>
            <input
              className={`${classes.userInfoContainerText} ${userReducerState.darkMode && "inputDarkMode"
                }`}
              value={userDetail?.displayName}
              disabled={
                userFeatures?.includes(features?.ADD_USER_DETAILS) &&
                  userReducerState?.entitiesAndActivities?.roles?.includes(
                    roles?.SUPER_ADMIN
                  )
                  ? false
                  : true
              }
              onChange={(e) => {
                setUserDetail({ ...userDetail, displayName: e.target.value });
              }}
            />
          </div>

          {(basicReducerState?.users
            ?.filter((u) => u?.id !== userDetail?.id)
            ?.find((u) => u?.displayName === userDetail?.displayName) ||
            userDetail?.displayName?.length === 0) && (
              <p style={{ color: "red", fontSize: 12 }}>
                Please enter different display Name
              </p>
            )}
        </Grid>
      </Grid>
    </div>
  );
};

const NewUserAssociatedGroup = ({
  open,
  onClose,
  userDetail,
  setUserDetail,
  params,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [newAssociatedGroups, setNewAssociatedGroups] = useState([]);
  const [selectApplication, setSelectApplication] = useState("");

  const presentGroup = (groupId) => {
    return userDetail?.associatedGroups?.filter(
      (group) => group?.id === groupId
    )?.length > 0
      ? true
      : false;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newUserAssociatedGroupDialogTitle}>
        New Associate Group
      </DialogTitle>

      <DialogContent>
        {/* <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel>Application name</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={selectApplication}
            onChange={(e) => {
              setSelectApplication(e.target.value);
              setNewAssociatedGroups([]);
            }}
          >
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={`${application?.id}-${index}`}
                value={application?.id}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          disableCloseOnSelect
          filterSelectedOptions
          value={newAssociatedGroups}
          onChange={(e, groups) => {
            setNewAssociatedGroups(groups);
          }}
          options={basicReducerState?.groups?.filter(
            (group) => !presentGroup(group?.id)
            //  &&
            // Number(group?.applicationId) === Number(selectApplication)
          )}
          getOptionLabel={(option) => option?.name}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>{option?.name}</Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Group"
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.newUserAssociatedGroupDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            setNewAssociatedGroups([]);
            setSelectApplication("");
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setUserDetail({
              ...userDetail,
              associatedGroups: [
                ...newAssociatedGroups?.map((group) => ({
                  ...group,
                  status: "Draft",
                })),
                ...userDetail?.associatedGroups,
              ],
            });
            onClose();
            setNewAssociatedGroups([]);
            setSelectApplication("");
          }}
          style={{ textTransform: "capitalize" }}
          disabled={
            newAssociatedGroups?.length === 0
            // || selectApplication?.length === 0
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserAssociatedGroups = ({
  userDetail,
  setUserDetail,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [openAssociatedGroupDialog, setOpenAssociatedGroupDialog] =
    useState(false);
  const [deletingAssociatedGroup, setDeletingAssociatedGroup] = useState(null);
  // const params = useParams();

  const getApplicationameById = (applicationId) => {
    const application = findApplicationById(
      applicationId,
      basicReducerState.applications
    );
    return application?.name;
  };
  const deleteAssociatedGroup = (associatedGroup) => {
    if (associatedGroup?.status === "Draft") {
      setUserDetail({
        ...userDetail,
        associatedGroups: userDetail?.associatedGroups?.filter(
          (group) => group?.id !== associatedGroup?.id
        ),
      });

      setDeletingAssociatedGroup(null);
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableUserAssociatedGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/deleteUserFromGroup/deactivate`;
        // const disableUserAssociatedGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/deleteUserFromGroup/deactivate`;
        const disableUserAssociatedGroupPayload = {
          // updatedBy: userReducerState?.user?.email,
          // updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          userEmail: params?.userId,
          groupId: [Number(associatedGroup?.id)],
        };
        const disableUserAssociatedGroupRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(disableUserAssociatedGroupPayload),
        };
        fetch(
          disableUserAssociatedGroupUrl,
          disableUserAssociatedGroupRequestParam
        )
          .then((res) => res.json())
          .then((groups) => {
            setLoad(false);
            setUserDetail({
              ...userDetail,
              associatedGroups:
                userDetail?.associatedGroups?.filter(
                  (group) => group?.id !== associatedGroup?.id
                ) || [],
            });
            setDeletingAssociatedGroup(null);

            dispatch(
              setResponseMessage({
                open: true,
                status: groups?.status ? "success" : "error",
                message: groups?.status
                  ? "Associated Group to user deleted successfully"
                  : "Something went wrong",
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };

  return (
    <>
      <NewUserAssociatedGroup
        open={openAssociatedGroupDialog}
        onClose={() => setOpenAssociatedGroupDialog(false)}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        params={params}
      />

      <DeletionMessageBox
        open={deletingAssociatedGroup ? true : false}
        onClose={() => setDeletingAssociatedGroup(null)}
        onDelete={() => {
          deleteAssociatedGroup(deletingAssociatedGroup);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.userAssociatedGroupsTableContainer} iagScroll`}
      >
        <Table
          size="small"
        //   className="table-sort table-arrows"
        >
          <TableHead className={classes.userAssociatedGroupsTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.userAssociatedGroupsTableHeadCell}
              >
                Group
              </TableCell>

              {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
                <TableCell
                  align="center"
                  className={classes.userAssociatedGroupsTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.userAssignedRolesTableBody} iagScroll`}
          >
            {userDetail?.associatedGroups?.map((associatedGroup, index) => {
              return (
                <TableRow
                  key={`${associatedGroup?.id}-${index}`}
                  className={classes.userAssociatedGroupsTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.userAssociatedGroupsTableBodyCell}
                  >
                    {associatedGroup?.name}
                  </TableCell>

                  {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
                    <TableCell
                      align="center"
                      className={classes.userAssociatedGroupsTableBodyCell}
                    >
                      <Tooltip
                        title={
                          associatedGroup?.status === "Draft"
                            ? "Remove"
                            : "Delete"
                        }
                      >
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteAssociatedGroup(associatedGroup);
                            setDeletingAssociatedGroup(associatedGroup);
                          }}
                          disabled={load}
                        >
                          {associatedGroup?.status === "Draft" ? (
                            <Remove style={{ fontSize: 16 }} />
                          ) : (
                            <Delete style={{ fontSize: 16 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.userAssociatedGroupsBottomAddButton}
          onClick={() => setOpenAssociatedGroupDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NewUserAssignedRole = ({
  open,
  onClose,
  userDetail,
  setUserDetail,
  params,
}) => {
  const classes = useStyle();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const [applicationId, setApplicationId] = useState(null);
  const initialNewAssignedRole = {
    userEmail: params?.userId,
    roleId: "",
    status: "Draft",
    isActive: 1,
    isDeleted: 0,
    // applicationId: "",
    // associationType: "ROLE",
    createdBy: userReducerState?.user?.email,
    createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    // endDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    // startDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    updatedBy: userReducerState?.user?.email,
    updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isEdited: false,
    isGroupRole: 0,
    groupRole: "",
  };
  const [newAssignedRoles, setNewAssignedRoles] = useState([]);
  // const [newAssignedRole, setNewAssignedRole] = useState(
  //   initialNewAssignedRole
  // );
  const assignedRolesId =
    userDetail?.assignedRoles?.map((assignedRole) => Number(assignedRole?.roleId)) ||
    [];

  console.log(assignedRolesId);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newUserAssignedRoleDialogTitle}>
        New Assigned Role
      </DialogTitle>

      <DialogContent>
        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel>Application name</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={applicationId}
            onChange={(e) => setApplicationId(e.target.value)}
          >
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={`${application?.id}-${index}`}
                value={application?.id}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel>Role name</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAssignedRole?.associationId}
            onChange={(e) =>
              setNewAssignedRole({
                ...newAssignedRole,
                associationId: e.target.value,
              })
            }
          >
            {basicReducerState?.roles.map(
              (role, index) =>
                role?.applicationId === newAssignedRole?.applicationId &&
                !assignedRolesId.includes(role?.id) && (
                  <MenuItem
                    key={`${role?.id}-${index}`}
                    value={role?.id}
                    style={{ fontSize: 12 }}
                  >
                    {role?.name}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl> */}

        {applicationId && (
          <Autocomplete
            multiple
            size="small"
            style={{ fontSize: 12 }}
            disableCloseOnSelect
            filterSelectedOptions
            value={newAssignedRoles}
            onChange={(e, roles) => {
              setNewAssignedRoles(roles);
            }}
            options={basicReducerState?.roles?.filter(
              (role) =>
                role?.applicationId === applicationId &&
                !assignedRolesId.includes(Number(role?.id))
            )}
            getOptionLabel={(option) => option?.name}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                  checked={selected}
                />
                <Typography style={{ fontSize: 12 }}>{option?.name}</Typography>
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                style={{ fontSize: 12 }}
                label="Roles"
              />
            )}
          />
        )}
      </DialogContent>

      <DialogActions className={classes.newUserAssignedRoleDialogActions}>
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            setApplicationId(null);
            // setNewAssignedRole(initialNewAssignedRole);
            setNewAssignedRoles([]);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setUserDetail({
              ...userDetail,
              assignedRoles: [
                ...newAssignedRoles?.map((role) => ({
                  ...initialNewAssignedRole,
                  applicationId: Number(applicationId),
                  roleId: Number(role?.id),
                  associationId: Number(role?.id),
                })),
                ...userDetail?.assignedRoles,
              ],
            });
            onClose();
            setApplicationId(null);
            // setNewAssignedRole(initialNewAssignedRole);
            setNewAssignedRoles([]);
          }}
          style={{ textTransform: "capitalize" }}
          // disabled={
          //   newAssignedRole?.applicationId?.length === 0 ||
          //   newAssignedRole?.associationId?.length === 0 ||
          //   userDetail?.assignedRoles?.filter(
          //     (assignedRole) =>
          //       assignedRole?.associationId ===
          //         newAssignedRole?.associationId &&
          //       assignedRole?.applicationId === newAssignedRole?.applicationId
          //   )?.length > 0
          // }
          disabled={newAssignedRoles?.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserAssignedRoles = ({
  userDetail,
  setUserDetail,
  load,
  setLoad,
  params,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const [openAssignRoleDialog, setOpenAssignRoleDialog] = useState(false);
  const [deletingAssignedRole, setDeletingAssignedRole] = useState(null);
  // const params = useParams();
  const dispatch = useDispatch();

  const getApplicationameById = (applicationId) => {
    const application = findApplicationById(
      applicationId,
      basicReducerState.applications
    );
    return application?.name;
  };
  const deleteAssignedRole = (assignedRole) => {
    if (assignedRole?.status === "Draft") {
      setUserDetail({
        ...userDetail,
        assignedRoles: userDetail?.assignedRoles?.filter(
          (role) =>
            !(
              role?.applicationId === assignedRole?.applicationId &&
              role?.associationId === assignedRole?.associationId
            )
        ),
      });
      setDeletingAssignedRole(null);
    } else {
      if (authVerify(userReducerState.token)) {
        setLoad(true);
        const disableUserRoleMappingUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userRoleMapping`;
        // const disableUserRoleMappingUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userRoleMapping`;
        const disableUserRoleMappingPayload = {
          userEmail: assignedRole?.userId,
          roleId: Number(assignedRole?.associationId),
          status: "Inactive",
          isDeleted: 1,
          isActive: 0,
          createdBy: assignedRole?.createdBy,
          createdOn: assignedRole?.createdOn,
          updatedBy: userReducerState?.user?.email,
          updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          isGroupRole: assignedRole?.isGroupRole,
          groupRole: assignedRole?.groupRole,
          // associationType: assignedRole?.associationType,
          // endDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          // startDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
        const disableUserRoleMappingRequestParam = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userReducerState.token}`,
            applicationId: process.env.REACT_APP_IWAId,
          },
          body: JSON.stringify(disableUserRoleMappingPayload),
        };
        fetch(disableUserRoleMappingUrl, disableUserRoleMappingRequestParam)
          .then((res) => res.json())
          .then((users) => {
            setLoad(false);
            setUserDetail({
              ...userDetail,
              assignedRoles:
                userDetail?.assignedRoles?.filter(
                  (role) =>
                    !(
                      role?.applicationId === assignedRole?.applicationId &&
                      role?.associationId === assignedRole?.associationId
                    )
                ) || [],
            });

            var applicationNameMap = {};
            userDetail?.assignedRoles?.map((role) => {
              if (
                !(
                  role?.applicationId === assignedRole?.applicationId &&
                  role?.associationId === assignedRole?.associationId
                )
              ) {
                const application_name = getApplicationameById(
                  role.applicationId
                );
                applicationNameMap[application_name] = 1;
              }
              return null;
            });
            dispatch(
              setUsers(
                basicReducerState?.users?.map((user) =>
                  user?.emailId === params?.userId
                    ? {
                      ...user,
                      applicationName: Object.keys(applicationNameMap),
                    }
                    : user
                ) || []
              )
            );
            setDeletingAssignedRole(null);

            dispatch(
              setResponseMessage({
                open: true,
                status: users?.status ? "success" : "error",
                message: users?.status
                  ? "Mapped role to user deleted successfully"
                  : "Something went wrong",
              })
            );
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        dispatch(setIsSessionExpired(true));
      }
    }
  };

  return (
    <>
      <NewUserAssignedRole
        open={openAssignRoleDialog}
        onClose={() => setOpenAssignRoleDialog(false)}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        params={params}
      />

      <DeletionMessageBox
        open={deletingAssignedRole ? true : false}
        onClose={() => setDeletingAssignedRole(null)}
        onDelete={() => {
          deleteAssignedRole(deletingAssignedRole);
        }}
        load={load}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.userAssignedRolesTableContainer} iagScroll`}
      >
        <Table
          size="small"
        //   className="table-sort table-arrows"
        >
          <TableHead className={classes.userAssignedRolesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.userAssignedRolesTableHeadCell}
              >
                Application
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedRolesTableHeadCell}
              >
                Role
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedRolesTableHeadCell}
              >
                Status
              </TableCell>

              {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
                <TableCell
                  align="center"
                  className={classes.userAssignedRolesTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.userAssignedRolesTableBody} iagScroll`}
          >
            {userDetail?.assignedRoles?.map((assignedRole, index) => {
              return (
                <TableRow
                  key={`${assignedRole?.id}-${index}`}
                  className={classes.userAssignedRolesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableBodyCell}
                  >
                    {assignedRole?.status === "Draft" ? (
                      <Select
                        size="small"
                        style={{ fontSize: 12 }}
                        value={assignedRole?.applicationId}
                        readOnly
                      >
                        {basicReducerState?.applications?.map(
                          (application, index) => (
                            <MenuItem
                              key={`${application?.id}-${index}`}
                              value={application?.id}
                              style={{ fontSize: 12 }}
                            >
                              {application?.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    ) : (
                      assignedRole?.applicationName
                    )}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableBodyCell}
                  >
                    {assignedRole?.status === "Draft" ? (
                      <Select
                        size="small"
                        style={{ fontSize: 12 }}
                        value={assignedRole?.associationId}
                        readOnly
                      >
                        {basicReducerState?.roles.map((role, index) => (
                          <MenuItem
                            key={`${role?.id}-${index}`}
                            value={role?.id}
                            style={{ fontSize: 12 }}
                          >
                            {role?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      assignedRole?.roleName
                    )}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedRolesTableBodyCell}
                  >
                    {assignedRole?.status}
                  </TableCell>

                  {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
                    <TableCell
                      align="center"
                      className={classes.userAssignedRolesTableBodyCell}
                    >
                      <Tooltip
                        title={
                          assignedRole?.status === "Draft" ? "Remove" : "Delete"
                        }
                      >
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteAssignedRole(assignedRole);
                            setDeletingAssignedRole(assignedRole);
                          }}
                          disabled={load}
                        >
                          {assignedRole?.status === "Draft" ? (
                            <Remove style={{ fontSize: 16 }} />
                          ) : (
                            <Delete style={{ fontSize: 16 }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.userAssignedRolesBottomAddButton}
          onClick={() => setOpenAssignRoleDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NewUserAssignedLicense = ({
  open,
  onClose,
  userDetail,
  setUserDetail,
  params,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  // const params = useParams();

  const getUserEmailById = (userId) => {
    const user = findUserById(userId, basicReducerState?.users);
    return user?.emailId || "";
  };

  const initialNewAssignedLicense = {
    userEmail: params?.userId || "",
    // userName: getUserEmailById(params?.userId),
    applicationName: "",
    licenseNumber: "",
    status: "Draft",
  };
  const [newAssignedLicense, setNewAssignedLicense] = useState(
    initialNewAssignedLicense
  );
  const [licenseType, setLicenseType] = useState("");
  const [availableLicenses, setAvailableLicenses] = useState([]);
  const [msg, setMsg] = useState("Please select the license");
  const assignedLicensesApplicationList =
    userDetail?.assignedLicenses?.map((license) =>
      license?.applicationName?.toLowerCase()
    ) || [];

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newUserAssignedLicenseDialogTitle}>
        New Assigned License
      </DialogTitle>

      <DialogContent>
        <Typography className={classes.newUserAssignedLicenseDialogContentMsg}>
          {msg}
        </Typography>

        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel>Application name</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAssignedLicense?.applicationName}
            onChange={(e) => {
              setNewAssignedLicense({
                ...newAssignedLicense,
                applicationName: e.target.value,
              });
            }}
          >
            {basicReducerState?.applications?.map((application, index) =>
              assignedLicensesApplicationList?.includes(
                application?.name?.toLowerCase()
              ) ? null : (
                <MenuItem
                  key={`${application?.id}-${index}`}
                  value={application?.name}
                  style={{ fontSize: 12 }}
                >
                  {application?.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>

        {newAssignedLicense?.applicationName?.length > 0 && (
          <>
            <FormControl variant="standard" size="small" fullWidth required>
              <InputLabel id="licenseType">License Type</InputLabel>
              <Select
                labelId="licenseType"
                size="small"
                label="Type"
                style={{ fontSize: 12 }}
                value={licenseType}
                onChange={(e) => {
                  setLicenseType(e.target.value);
                  setNewAssignedLicense({
                    ...newAssignedLicense,
                    licenseNumber: "",
                  });
                  const licenses = basicReducerState?.licenses?.filter(
                    (license) =>
                      // license?.status !== "Assigned" &&
                      license?.applicationName ===
                      newAssignedLicense?.applicationName &&
                      license?.licenseType === e.target.value
                  );
                  if (licenses?.length === 0) {
                    setMsg("No licenses available of this application");
                    setAvailableLicenses([]);
                  } else {
                    setMsg("License is available");
                    setAvailableLicenses(licenses);
                  }
                }}
              >
                <MenuItem value="Full Application" style={{ fontSize: 12 }}>
                  Full Application
                </MenuItem>
                <MenuItem value="Limited Feature" style={{ fontSize: 12 }}>
                  Limited Feature
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="standard" size="small" fullWidth required>
              <InputLabel id="licenseType">License</InputLabel>
              <Select
                labelId="licenseType"
                size="small"
                label="Type"
                style={{ fontSize: 12 }}
                value={newAssignedLicense?.licenseNumber}
                onChange={(e) =>
                  setNewAssignedLicense({
                    ...newAssignedLicense,
                    licenseNumber: e.target.value,
                  })
                }
              >
                {availableLicenses?.map((license) => (
                  <MenuItem
                    value={license?.licenseNumber}
                    style={{ fontSize: 12 }}
                  >
                    {`${license?.licenseNumber} ${showFeatureNumber(license)}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <Autocomplete
              multiple
              size="small"
              style={{ fontSize: 12 }}
          disableCloseOnSelect
              filterSelectedOptions
               value={newLicense?.activities}
              onChange={(e, activities) => {
                setNewLicense({
                  ...newLicense,
                  activities: activities,
                });
              }}
              options={basicReducerState?.activities?.filter((activity) =>
                isApplicationActivity(activity, newLicense?.applicationName)
              )}
              getOptionLabel={(option) => {
                return `${option.name} (${getEntityNameById(
                  option?.entityId
                )})`;
              }}
              renderOption={(option, {selected}) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
                <Typography style={{ fontSize: 12 }}>{`${
                  option.name
                } (${getEntityNameById(option?.entityId)})`}</Typography>
              </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Associate Features"
                  style={{ fontSize: 12 }}
                />
              )}
            /> */}
          </>
        )}
      </DialogContent>

      <DialogActions
        className={classes.newUserAssignedLicenseDialogActions}
        style={{ height: "3rem", borderTop: "1px solid #d9d9d9" }}
      >
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            setNewAssignedLicense(initialNewAssignedLicense);
            setLicenseType("");
            setAvailableLicenses([]);
            setMsg("Please select the license");
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            // console.log(newAssignedLicense);
            setUserDetail({
              ...userDetail,
              assignedLicenses: [
                newAssignedLicense,
                ...userDetail?.assignedLicenses,
              ],
            });
            onClose();
            setNewAssignedLicense(initialNewAssignedLicense);
            setMsg("Please select the license");
          }}
          style={{ textTransform: "capitalize" }}
          disabled={
            newAssignedLicense?.applicationName?.length === 0 ||
            newAssignedLicense?.licenseNumber?.length === 0 ||
            userDetail?.assignedLicenses?.filter(
              (assignedLicense) =>
                assignedLicense?.applicationName ===
                newAssignedLicense?.applicationName
            )?.length > 0 ||
            basicReducerState?.licenses?.filter(
              (license) =>
                //license?.status !== "Assigned" &&
                license?.applicationName === newAssignedLicense?.applicationName
            )?.length === 0
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserAssignedLicenses = ({ userDetail, setUserDetail, load, params }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const userReducerState = useSelector((state) => state.userReducer);
  const [openAssignedLicenseDialog, setOpenAssignedLicenseDialog] =
    useState(false);
  const [deletingAssignedLicense, setDeletingAssignedLicense] = useState(null);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });

  return (
    <>
      <NewUserAssignedLicense
        open={openAssignedLicenseDialog}
        onClose={() => {
          setOpenAssignedLicenseDialog(false);
        }}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        params={params}
      />

      {/* <DeletionMessageBox
        open={deletingAssignedLicense ? true : false}
        onClose={() => setDeletingAssignedLicense(null)}
        onDelete={() => {
          // deleteAssignedLicense(deletingAssignedLicense);
        }}
        load={load}
      /> */}

      <TableContainer
        // component={Paper}
        className={`${classes.userAssignedLicensesTableContainer} iagScroll`}
      >
        <Table
          size="small"
        //   className="table-sort table-arrows"
        >
          <TableHead className={classes.userAssignedLicensesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                Application
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                License
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                Assigned at
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                Assigned till
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAssignedLicensesTableHeadCell}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.userAssignedLicensesTableBody} iagScroll`}
          >
            {userDetail?.assignedLicenses?.map((assignedLicense, index) => {
              return (
                <TableRow
                  key={`${assignedLicense?.id}-${index}`}
                  className={classes.userAssignedLicensesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.applicationName}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.licenseNumber}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.startDate}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.endDate}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAssignedLicensesTableBodyCell}
                  >
                    {assignedLicense?.status}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {userFeatures?.includes(features?.MAP_LICENSE) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.userAssignedLicensesBottomAddButton}
          onClick={() => setOpenAssignedLicenseDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

const NewUserAdditioanlFeature = ({
  open,
  onClose,
  userDetail,
  setUserDetail,
  params,
}) => {
  const classes = useStyle();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  const [selecteApplication, setSelecteApplication] = useState(null);
  const [newAdditionalFeatures, setNewAdditionalFeatures] = useState([]);
  // const [newAdditionalFeature, setNewAdditionalFeature] = useState(null);

  const getEmailById = (userId) => {
    const user = findUserById(userId, basicReducerState?.users);
    return user?.emailId || "";
  };

  const handleAddNewAdditionalFeature = () => {
    setUserDetail({
      ...userDetail,
      exceptionalActivities: [
        ...newAdditionalFeatures?.map((newAdditionalFeature) => ({
          activityId: Number(newAdditionalFeature?.activityId),
          emailId: params?.userId,
          type: "Access",
          status: "Draft",
          isActive: 1,
          isDeleted: 0,
          entityId: Number(newAdditionalFeature?.entityId),
          applicationId: Number(newAdditionalFeature?.applicationId),
          applicationName: newAdditionalFeature?.applicationName,
          entityName: newAdditionalFeature?.entityName,
          activityName: newAdditionalFeature?.activityName,
          userId: params?.userId,
          // createdBy: userReducerState?.user?.email,
          // createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          // updatedBy: userReducerState?.user?.email,
          // updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        })),
        ...userDetail?.exceptionalActivities,
      ],
      unassignedActivities:
        userDetail?.unassignedActivities.filter(
          (activity) =>
            !newAdditionalFeatures?.find(
              (activity) => activity?.id === activity.activityId
            )
        ) || [],
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle className={classes.newUserAdditionalFeatureDialogTitle}>
        New Additional Feature
      </DialogTitle>

      <DialogContent>
        <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel>Application name</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={selecteApplication}
            onChange={(e) => {
              setSelecteApplication(e.target.value);
            }}
          >
            {basicReducerState?.applications?.map((application, index) => (
              <MenuItem
                key={`${application}-${index}`}
                value={application?.name}
                style={{ fontSize: 12 }}
              >
                {application?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <FormControl variant="standard" size="small" fullWidth required>
          <InputLabel>Feature name</InputLabel>
          <Select
            size="small"
            style={{ fontSize: 12 }}
            value={newAdditionalFeature}
            onChange={(e) => {
              setNewAdditionalFeature(e.target.value);
            }}
          >
            {userDetail?.unassignedActivities?.map(
              (unassignedActivity, index) =>
                unassignedActivity?.applicationName === selecteApplication && (
                  <MenuItem
                    key={`${unassignedActivity?.id}-${index}`}
                    value={unassignedActivity}
                    style={{ fontSize: 12 }}
                  >
                    {`${unassignedActivity?.activityName} (${unassignedActivity?.entityName}) - ${unassignedActivity?.applicationName}`}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl> */}

        <Autocomplete
          multiple
          size="small"
          style={{ fontSize: 12 }}
          disableCloseOnSelect
          filterSelectedOptions
          value={newAdditionalFeatures}
          onChange={(e, activities) => {
            setNewAdditionalFeatures(activities);
          }}
          options={userDetail?.unassignedActivities?.filter(
            (unassignedActivity) =>
              unassignedActivity?.applicationName === selecteApplication
          )}
          // classes={{
          //   popper: {
          //     border: "1px solid rgba(27,31,35,.15)",
          //     boxShadow: "0 3px 12px rgba(27,31,35,.15)",
          //     borderRadius: 3,
          //     width: 300,
          //     zIndex: 1,
          //     fontSize: 13,
          //     color: "#586069",
          //     backgroundColor: "#f6f8fa",
          //   },
          // }}
          groupBy={(option) => option?.entityName}
          getOptionLabel={(option) =>
            `${option?.activityName} (${option?.entityName}) - ${option?.applicationName}`
          }
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox color="primary" fontSize="small" />}
                checked={selected}
              />
              <Typography style={{ fontSize: 12 }}>
                {`${option?.activityName} (${option?.entityName}) - ${option?.applicationName}`}
              </Typography>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              style={{ fontSize: 12 }}
              label="Features"
            />
          )}
        />
      </DialogContent>

      <DialogActions
        className={classes.newUserAdditionalFeatureDialogActions}
        style={{ height: "3rem", borderTop: "1px solid #d9d9d9" }}
      >
        <Button
          key={"CANCEL"}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            onClose();
            // setNewAdditionalFeature(null);
            setNewAdditionalFeatures([]);
            setSelecteApplication(null);
          }}
        >
          Cancel
        </Button>

        <Button
          key={"ADD"}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            handleAddNewAdditionalFeature();
            onClose();
            // setNewAdditionalFeature(null);
            setNewAdditionalFeatures([]);
            setSelecteApplication(null);
          }}
          style={{ textTransform: "capitalize" }}
          disabled={
            newAdditionalFeatures?.length === 0 || selecteApplication === null
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserDefaultFeatures = ({
  userDetail,
  setUserDetail,
  revokeFeature,
  load,
  setLoad,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });

  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name || "-";
  };

  return (
    <>
      <Loading load={load} />

      <TableContainer
        // component={Paper}
        className={`${classes.userAdditionalFeaturesTableContainer} iagScroll`}
      >
        <Table
          size="small"
        //   className="table-sort table-arrows"
        >
          <TableHead className={classes.userAdditionalFeaturesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.userAdditionalFeaturesTableHeadCell}
              >
                Modules
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAdditionalFeaturesTableHeadCell}
              >
                Feature
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAdditionalFeaturesTableHeadCell}
              >
                Role
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAdditionalFeaturesTableHeadCell}
              >
                Application
              </TableCell>

              {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
                <TableCell
                  align="center"
                  className={classes.userAdditionalFeaturesTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.userAdditionalFeaturesTableBody} iagScroll`}
          >
            {userDetail?.assignedActivities?.map((assignedActivity, index) => {
              return (
                <TableRow
                  key={`${assignedActivity?.id}-${index}`}
                  className={classes.userAdditionalFeaturesTableBodyRow}
                >
                  <TableCell
                    // align="center"
                    className={classes.userAdditionalFeaturesTableBodyCell}
                  >
                    {assignedActivity?.entityName}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAdditionalFeaturesTableBodyCell}
                  >
                    {assignedActivity?.activityName}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAdditionalFeaturesTableBodyCell}
                  >
                    {assignedActivity?.roleName}
                  </TableCell>

                  <TableCell
                    // align="center"
                    className={classes.userAdditionalFeaturesTableBodyCell}
                  >
                    {getApplicationNameById(assignedActivity?.applicationId)}
                  </TableCell>

                  {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
                    <TableCell
                      align="center"
                      className={classes.userAdditionalFeaturesTableBodyCell}
                    >
                      <Tooltip title={"Revoke"}>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            revokeFeature(assignedActivity, () => {
                              setUserDetail({
                                ...userDetail,
                                exceptionalActivities: [
                                  {
                                    activityId: assignedActivity?.activityId,
                                    activityName:
                                      assignedActivity?.activityName,
                                    applicationId:
                                      assignedActivity?.applicationId,
                                    entityId: assignedActivity?.entityId,
                                    entityName: assignedActivity?.entityName,
                                    type: "Revoke",
                                  },
                                  ...userDetail.exceptionalActivities,
                                ],
                                assignedActivities:
                                  userDetail?.assignedActivities?.filter(
                                    (activity) =>
                                      Number(activity?.activityId) !==
                                      Number(assignedActivity?.activityId)
                                  ),
                              });

                              dispatch(
                                setResponseMessage({
                                  open: true,
                                  status: "success",
                                  message: "Feature removed successfully",
                                })
                              );
                            });
                          }}
                          disabled={load}
                        >
                          <Delete style={{ fontSize: 16 }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const UserAdditionalFeatures = ({
  userDetail,
  setUserDetail,
  load,
  setLoad,
  params,
}) => {
  const dispatch = useDispatch();
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const classes = useStyle();
  const [openAdditionalFeatureDialog, setOpenAdditionalFeatureDialog] =
    useState(false);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });

  const getEmailById = (userId) => {
    const user = findUserById(userId, basicReducerState?.users);
    return user?.emailId || "";
  };
  const getApplicationNameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name || "-";
  };
  const removeAdditionalFeature = (assignedActivity) => {
    setUserDetail({
      ...userDetail,
      exceptionalActivities:
        userDetail?.exceptionalActivities?.filter(
          (activity) => activity.activityId !== assignedActivity.activityId
        ) || [],
      unassignedActivities: [
        ...userDetail?.unassignedActivities,
        assignedActivity,
      ],
    });
  };
  const revokeAdditionalFeature = (assignedActivity) => {
    const updateAdditionalFeaturePayload = {
      activityId: Number(assignedActivity?.activityId),
      emailId: params?.userId,
      type: "Revoke",
      status: "Draft",
      isActive: 1,
      isDeleted: 0,
      createdBy: userReducerState?.user?.email,
      createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      updatedBy: userReducerState?.user?.email,
      updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      entityId: Number(assignedActivity?.entityId),
      applicationId: Number(assignedActivity?.applicationId),
    };
    // console.log(updateAdditionalFeaturePayload);
    updateAdditionalFeature(updateAdditionalFeaturePayload, () => {
      setUserDetail({
        ...userDetail,
        unassignedActivities: [
          {
            activityId: Number(assignedActivity?.activityId),
            activityName: assignedActivity?.activityName,
            applicationId: Number(assignedActivity?.applicationId),
            applicationName: getApplicationNameById(
              Number(assignedActivity?.applicationId)
            ),
            entityId: Number(assignedActivity?.entityId),
            entityName: assignedActivity?.entityName,
          },
          ...userDetail.unassignedActivities,
        ],
        exceptionalActivities:
          userDetail.exceptionalActivities.filter(
            (activity) =>
              Number(activity?.activityId) !==
              Number(assignedActivity?.activityId)
          ) || [],
      });

      dispatch(
        setResponseMessage({
          open: true,
          status: "success",
          message: "Additional feature removed successfully",
        })
      );
    });
  };
  const accessAdditionalFeature = (assignedActivity) => {
    const updateAdditionalFeaturePayload = {
      activityId: Number(assignedActivity?.activityId),
      emailId: params?.userId,
      type: "Access",
      status: "Draft",
      isActive: 1,
      isDeleted: 0,
      createdBy: userReducerState?.user?.email,
      createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      updatedBy: userReducerState?.user?.email,
      updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      entityId: Number(assignedActivity?.entityId),
      applicationId: Number(assignedActivity?.applicationId),
    };
    // console.log(updateAdditionalFeaturePayload);
    updateAdditionalFeature(updateAdditionalFeaturePayload, () => {
      setUserDetail({
        ...userDetail,
        assignedActivities: [
          {
            activityId: Number(assignedActivity?.activityId),
            activityName: assignedActivity?.activityName,
            applicationId: Number(assignedActivity?.applicationId),
            entityId: Number(assignedActivity?.entityId),
            entityName: assignedActivity?.entityName,
          },
          ...userDetail.assignedActivities,
        ],
        exceptionalActivities:
          userDetail.exceptionalActivities.filter(
            (activity) =>
              Number(activity?.activityId) !==
              Number(assignedActivity?.activityId)
          ) || [],
      });

      dispatch(
        setResponseMessage({
          open: true,
          status: "success",
          message: "Additional features added successfully",
        })
      );
    });
  };
  const updateAdditionalFeature = (
    updateAdditionalFeaturePayload,
    fSuccess
  ) => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateAdditionalFeatureUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userFeatureException/deactivate?userEmail=${params?.userId}&activityId=${updateAdditionalFeaturePayload?.activityId}`;
      // const updateAdditionalFeatureUrl = `${
      //   process.env.REACT_APP_IWAServices
      // }/api/v1/users/userFeatureException/deactivate?userId=${
      //   params?.userId
      // }&activityId=${updateAdditionalFeaturePayload?.activityId}`;
      const updateAdditionalFeatureRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        // body: JSON.stringify(updateAdditionalFeaturePayload),
      };
      fetch(updateAdditionalFeatureUrl, updateAdditionalFeatureRequestParam)
        .then((res) => res.json())
        .then((data) => {
          // setUserDetail({
          //   ...userDetail,
          //   exceptionalActivities:
          //     userDetail?.exceptionalActivities?.map((activity) =>
          //       activity?.activityId ===
          //       updateAdditionalFeaturePayload?.activityId
          //         ? { ...activity, type: updateAdditionalFeaturePayload?.type }
          //         : activity
          //     ) || [],
          // });
          fSuccess();
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <>
      <Loading load={load} />

      <NewUserAdditioanlFeature
        open={openAdditionalFeatureDialog}
        onClose={() => {
          setOpenAdditionalFeatureDialog(false);
        }}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        params={params}
      />

      <TableContainer
        // component={Paper}
        className={`${classes.userAdditionalFeaturesTableContainer} iagScroll`}
        style={{
          height: `calc(100vh - ${appHeaderHeight} - ${userPageHeaderHeight} - ${crossIconContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${userDetailPageCss?.tabsContainerHeight} - ${buttonHeight} - ${userDetailPageCss?.footerHeight} - 26px)`,
        }}
      >
        <Table
          size="small"
        //   className="table-sort table-arrows"
        >
          <TableHead className={classes.userAdditionalFeaturesTableHead}>
            <TableRow>
              <TableCell
                // align="center"
                className={classes.userAdditionalFeaturesTableHeadCell}
              >
                Modules
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAdditionalFeaturesTableHeadCell}
              >
                Feature
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAdditionalFeaturesTableHeadCell}
              >
                Application
              </TableCell>

              <TableCell
                // align="center"
                className={classes.userAdditionalFeaturesTableHeadCell}
              >
                Type
              </TableCell>

              {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
                <TableCell
                  align="center"
                  className={classes.userAdditionalFeaturesTableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody
            className={`${classes.userAdditionalFeaturesTableBody} iagScroll`}
          >
            {userDetail?.exceptionalActivities?.map(
              (assignedActivity, index) => {
                return (
                  <TableRow
                    key={`${assignedActivity?.id}-${index}`}
                    className={classes.userAdditionalFeaturesTableBodyRow}
                  >
                    <TableCell
                      // align="center"
                      className={classes.userAdditionalFeaturesTableBodyCell}
                    >
                      {assignedActivity?.entityName}
                    </TableCell>

                    <TableCell
                      // align="center"
                      className={classes.userAdditionalFeaturesTableBodyCell}
                    >
                      {assignedActivity?.activityName}
                    </TableCell>

                    <TableCell
                      // align="center"
                      className={classes.userAdditionalFeaturesTableBodyCell}
                    >
                      {getApplicationNameById(assignedActivity?.applicationId)}
                    </TableCell>

                    <TableCell
                      // align="center"
                      className={classes.userAdditionalFeaturesTableBodyCell}
                    >
                      {assignedActivity?.type || ""}
                    </TableCell>

                    {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
                      <TableCell
                        align="center"
                        className={classes.userAdditionalFeaturesTableBodyCell}
                      >
                        {assignedActivity?.status === "Draft" ? (
                          <Tooltip title={assignedActivity?.status}>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeAdditionalFeature(assignedActivity);
                              }}
                              disabled={load}
                            >
                              <Remove style={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        ) : assignedActivity?.type === "Revoke" ? (
                          <Tooltip title={"Access"}>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                accessAdditionalFeature(assignedActivity);
                              }}
                              disabled={load}
                            >
                              <Add style={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title={"Revoke"}>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                revokeAdditionalFeature(assignedActivity);
                              }}
                              disabled={load}
                            >
                              <Delete style={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {userFeatures?.includes(features?.ADD_USER_DETAILS) && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.userAdditionalFeaturesBottomAddButton}
          onClick={() => setOpenAdditionalFeatureDialog(true)}
          startIcon={<Add />}
          disabled={load}
        >
          Add
        </Button>
      )}
    </>
  );
};

const FeaturesContent = ({
  userDetail,
  setUserDetail,
  load,
  setLoad,
  params,
}) => {
  const dispatch = useDispatch();
  const userReducerState = useSelector((state) => state.userReducer);
  const basicReducerState = useSelector((state) => state.basicReducer);
  const classes = useStyle();
  // const params = useParams();
  const [featuresTab, setFeaturesTab] = useState("Default Features");
  // const [load, setLoad] = useState(false);

  const getEmailById = (userId) => {
    const user = findUserById(userId, basicReducerState?.users);
    return user?.emailId || "";
  };
  // const getApplicationNameById = (applicationId) => {
  //   const application = findApplicationById(
  //     Number(applicationId),
  //     basicReducerState.applications
  //   );
  //   return application?.name || "-";
  // };
  // const getEntityNameById = (entityId) => {
  //   const entity = findEntityById(
  //     Number(entityId),
  //     basicReducerState?.entities
  //   );
  //   return entity?.name || "";
  // };
  // const getActivityNameById = (activityId) => {
  //   const activity = findActivityById(
  //     Number(activityId),
  //     basicReducerState?.activities
  //   );
  //   return activity?.name || "";
  // };
  const revokeFeature = (assignedActivity, fSuccess) => {
    const updateAdditionalFeaturePayload = {
      activityId: Number(assignedActivity?.activityId),
      emailId: params?.userId,
      type: "Revoke",
      status: "Draft",
      isActive: 1,
      isDeleted: 0,
      createdBy: userReducerState?.user?.email,
      createdOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      updatedBy: userReducerState?.user?.email,
      updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      applicationId: assignedActivity?.applicationId,
      entityId: assignedActivity?.entityId,
      userId: params?.userId,
    };
    updateAdditionalFeature(updateAdditionalFeaturePayload, fSuccess);
  };
  const updateAdditionalFeature = (
    updateAdditionalFeaturePayload,
    fSuccess
  ) => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateAdditionalFeatureUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userFeatureException`;
      // const updateAdditionalFeatureUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userFeatureException`;
      const updateAdditionalFeatureRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateAdditionalFeaturePayload),
      };
      fetch(updateAdditionalFeatureUrl, updateAdditionalFeatureRequestParam)
        .then((res) => res.json())
        .then((data) => {
          fSuccess();
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <>
      <div className={classes.userDetailHeaderContainer}>
        <Typography
          className={`${classes.userDetailHeaderItem} ${featuresTab === "Default Features" &&
            classes.userDetailHeaderItemSelected
            }`}
          onClick={() => setFeaturesTab("Default Features")}
        >
          Default Features
        </Typography>

        <Typography
          className={`${classes.userDetailHeaderItem} ${featuresTab === "Exceptional Features" &&
            classes.userDetailHeaderItemSelected
            }`}
          onClick={() => setFeaturesTab("Exceptional Features")}
        >
          Feature Exceptions
        </Typography>
      </div>

      {featuresTab === "Default Features" && (
        <UserDefaultFeatures
          userDetail={userDetail}
          setUserDetail={setUserDetail}
          revokeFeature={revokeFeature}
          load={load}
          setLoad={setLoad}
          params={params}
        />
      )}

      {featuresTab === "Exceptional Features" && (
        <UserAdditionalFeatures
          userDetail={userDetail}
          setUserDetail={setUserDetail}
          load={load}
          setLoad={setLoad}
          params={params}
        />
      )}
    </>
  );
};

function UserDetail({ params, setParams, getUsersList }) {
  const basicReducerState = useSelector((state) => state.basicReducer);
  const userReducerState = useSelector((state) => state.userReducer);
  var userFeatures = [];
  Object?.values(
    userReducerState?.entitiesAndActivities?.entitiesAndActivities?.[0]
  )?.map((data) => {
    data?.map((d) => {
      userFeatures.push(d);
      return null;
    });
    return null;
  });
  const classes = useStyle();
  const [selectedUserDetailContentType, setSelectedUserDetailContentType] =
    useState("Basic Info");
  const [load, setLoad] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const history = useHistory();
  // const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.userId) {
      console.log(params?.userId);
      getUserInfoById();
    }
  }, [params?.userId]);

  const removeRepeatedValue = (valueList) => {
    var valueMap = {};
    valueList?.map((value) => {
      valueMap[value] = 1;
      return null;
    });
    // console.log("valueMap", valueMap);
    return Object.keys(valueMap);
  };
  const getApplicationameById = (applicationId) => {
    const application = findApplicationById(
      Number(applicationId),
      basicReducerState.applications
    );
    return application?.name;
  };
  const getUserInfoById = () => {
    if (authVerify(userReducerState.token)) {
      const getUserByIdUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userEmail?userEmail=${params?.userId}`;
      // const getUserByIdUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userEmail?userEmail=${params?.userId}`;
      const getUserByIdRequestParams = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      const getUserAssociatedGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/getUserGroup?userEmail=${params?.userId}`;
      // const getUserAssociatedGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/getUserGroup?userEmail=${params?.userId}`;
      const getUserAssociatedGroupRequestParams = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      const getUserRoleMappingUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userRoleMapping/byUserEmail?userEmail=${params?.userId}`;
      // const getUserRoleMappingUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userRoleMapping/byUserEmail?userEmail=${params?.userId}`;
      const getUserRoleMappingRequestParam = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      const userId = basicReducerState.users.find(
        (user) => user.emailId === params?.userId
      )?.userId;
      const getMappedLicenseByUsernameUrl = `${process.env.REACT_APP_IWAServices}/api/v1/license/mappedUserDetails?userEmail=${params?.userId}`;
      // const getMappedLicenseByUsernameUrl = `${process.env.REACT_APP_IWAServices}/api/v1/license/mappedUserDetails?userEmail=${params?.userId}`;
      const getMappedLicenseByUsernameRequestParam = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userReducerState.token}`,
          "Content-Type": "application/json",
          applicationId: process.env.REACT_APP_IWAId,
        },
      };
      if (params?.userId && userId) {
        setLoad(true);
        setUserDetail({});
        Promise.all([
          fetch(getUserByIdUrl, getUserByIdRequestParams).then((res) =>
            res.json()
          ),
          fetch(
            getUserAssociatedGroupUrl,
            getUserAssociatedGroupRequestParams
          ).then((res) => res.json()),
          fetch(getUserRoleMappingUrl, getUserRoleMappingRequestParam).then(
            (res) => res.json()
          ),
          fetch(
            getMappedLicenseByUsernameUrl,
            getMappedLicenseByUsernameRequestParam
          ).then((res) => res.json()),
        ])
          .then(
            ([
              userDetail,
              userAssociatedGroupDetail,
              userAssignedRolesDetail,
              userAssignedLicensesDetail,
            ]) => {
              const postMappedAdditionFeatureUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/assignedAndUnassigneFeatures`;
              // const postMappedAdditionFeatureUrl = `${process.env.REACT_APP_IWAServices}/api/v1/applications/assignedAndUnassigneFeatures`;
              var roleIdList = [];
              userAssociatedGroupDetail?.data?.map((group) => {
                const roleIds = group?.roleIdList?.split(",");
                roleIds?.map((id) => {
                  roleIdList.push(id);
                  return null;
                });
                return null;
              });
              userAssignedRolesDetail?.data?.map((role) => {
                const roleDet = findRoleById(
                  role?.roleId,
                  basicReducerState?.roles
                );
                if (roleDet?.isComposite === 1) {
                  const associateRolesId = role?.associateRoles?.split(",");
                  associateRolesId.map((id) => {
                    roleIdList.push(id);
                    return null;
                  });
                } else {
                  roleIdList.push(role?.roleId);
                }
                return null;
              });
              const postMappedAdditionFeaturePayload = {
                userEmail: params?.userId,
                applicationId:
                  removeRepeatedValue(
                    userAssignedRolesDetail?.data?.map(
                      (role) => role?.applicationId
                    ) || []
                  )?.map((appId) => Number(appId)) || [],
                roleId:
                  removeRepeatedValue(roleIdList)?.map((rId) => Number(rId)) ||
                  [],
              };
              const postMappedAdditionFeatureRequestParam = {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${userReducerState.token}`,
                  "Content-Type": "application/json",
                  applicationId: process.env.REACT_APP_IWAId,
                },
                body: JSON.stringify(postMappedAdditionFeaturePayload),
              };
              // console.log(postMappedAdditionFeaturePayload);
              fetch(
                postMappedAdditionFeatureUrl,
                postMappedAdditionFeatureRequestParam
              )
                .then((res) => res.json())
                .then((userAdditionalFeatures) => {
                  setLoad(false);
                  setUserDetail({
                    ...userDetail.data,
                    assignedRoles: userAssignedRolesDetail?.data || [],
                    assignedLicenses: userAssignedLicensesDetail?.data || [],
                    assignedActivities: userAdditionalFeatures?.data?.[0] || [],
                    unassignedActivities:
                      userAdditionalFeatures?.data?.[1] || [],
                    exceptionalActivities:
                      userAdditionalFeatures?.data?.[2] || [],
                    associatedGroups: userAssociatedGroupDetail?.data || [],
                  });
                  var applicationNameMap = {};
                  userAssignedRolesDetail?.data?.map((role) => {
                    const application_name = getApplicationameById(
                      role.applicationId
                    );
                    applicationNameMap[application_name] = 1;
                    return null;
                  });
                  dispatch(
                    setUsers(
                      basicReducerState?.users?.map((user) =>
                        user?.emailId === params?.userId
                          ? {
                            ...user,
                            displayName: userDetail?.data?.displayName,
                            applicationName:
                              Object?.keys(applicationNameMap || {}) || [],
                          }
                          : user
                      ) || []
                    )
                  );
                })
                .catch((err) => {
                  setLoad(false);
                });
            }
          )
          .catch((err) => {
            setLoad(false);
            setUserDetail({});
          });
      }
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };
  const updateUserInfo = () => {
    if (authVerify(userReducerState.token)) {
      setLoad(true);
      const updateUserInfoUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userAndUserRoleMapping`;
      // const updateUserInfoUrl = `${process.env.REACT_APP_IWAServices}/api/v1/users/userAndUserRoleMapping`;
      const updateUserInfoPayload = {
        // id: userDetail?.id,
        userId: userDetail?.userId,
        userName: userDetail?.userName,
        displayName: userDetail?.displayName,
        firstName: userDetail?.firstName,
        lastName: userDetail?.lastName,
        emailId: userDetail?.emailId,
        status: "Active",
        isActive: 1,
        isDeleted: 0,
        // createdBy: userReducerState?.user?.email,
        // createdOn: moment(new Date(userDetail?.createdOn)).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // ),
        // updatedBy: userReducerState?.user?.email,
        // updatedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        userRoles:
          userDetail?.assignedRoles?.filter(
            (assignRole) => assignRole?.status === "Draft"
          ) || [],
        assignedLicenses:
          userDetail?.assignedLicenses?.filter(
            (assignedLicense) => assignedLicense?.status === "Draft"
          ) || [],
        featuresException:
          userDetail?.exceptionalActivities?.filter(
            (assignedActivity) => assignedActivity?.status === "Draft"
          ) || [],
      };
      const updateUserInfoRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateUserInfoPayload),
      };
      const updateUserGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/addUserToGroups`;
      // const updateUserGroupUrl = `${process.env.REACT_APP_IWAServices}/api/v1/groups/addUserToGroups`;
      const updateUserGroupPayload = {
        userEmail: params?.userId,
        groupId:
          userDetail?.associatedGroups
            ?.filter((group) => group?.status === "Draft")
            ?.map((group) => Number(group?.id)) || [],
      };
      const updateUserGroupRequestParam = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userReducerState.token}`,
          applicationId: process.env.REACT_APP_IWAId,
        },
        body: JSON.stringify(updateUserGroupPayload),
      };
      // console.log(updateUserInfoPayload);
      Promise.all([
        fetch(updateUserInfoUrl, updateUserInfoRequestParam).then((res) =>
          res.json()
        ),
        fetch(updateUserGroupUrl, updateUserGroupRequestParam).then((res) =>
          res.json()
        ),
      ])
        .then(([data, associateGroupData]) => {
          setLoad(false);
          getUserInfoById();
          getUsersList(userDetail?.emailId, userDetail?.displayName);

          dispatch(
            setResponseMessage({
              open: true,
              status:
                data?.status && associateGroupData?.status
                  ? "success"
                  : "error",
              message:
                data?.status && associateGroupData?.status
                  ? "User details updated successfully"
                  : "Something went wrong",
            })
          );
        })
        .catch((err) => {
          setLoad(false);
        });
    } else {
      dispatch(setIsSessionExpired(true));
    }
  };

  return (
    <Paper className={`${classes.userDetailContainer} iagScroll`}>
      <Loading load={load} />

      <>
        <div className={classes.userDetailCrossContainer}>
          <Close
            style={{ fontSize: 16, cursor: "pointer" }}
            onClick={() => {
              // history.push(`/users`);
              setParams({});
            }}
          />
        </div>

        <div className={classes.userDetailHeaderContainer}>
          <Typography
            className={`${classes.userDetailHeaderItem} ${selectedUserDetailContentType === "Basic Info" &&
              classes.userDetailHeaderItemSelected
              }`}
            onClick={() => setSelectedUserDetailContentType("Basic Info")}
          >
            Basic Info
          </Typography>

          <Typography
            className={`${classes.userDetailHeaderItem} ${selectedUserDetailContentType === "Associated Groups" &&
              classes.userDetailHeaderItemSelected
              }`}
            onClick={() =>
              setSelectedUserDetailContentType("Associated Groups")
            }
          >
            Associated Groups
          </Typography>

          <Typography
            className={`${classes.userDetailHeaderItem} ${selectedUserDetailContentType === "Assigned Roles" &&
              classes.userDetailHeaderItemSelected
              }`}
            onClick={() => setSelectedUserDetailContentType("Assigned Roles")}
          >
            Assigned Roles
          </Typography>

          <Typography
            className={`${classes.userDetailHeaderItem} ${selectedUserDetailContentType === "Assigned Licenses" &&
              classes.userDetailHeaderItemSelected
              }`}
            onClick={() =>
              setSelectedUserDetailContentType("Assigned Licenses")
            }
          >
            Assigned Licenses
          </Typography>

          <Typography
            className={`${classes.userDetailHeaderItem} ${selectedUserDetailContentType === "Features" &&
              classes.userDetailHeaderItemSelected
              }`}
            onClick={() => setSelectedUserDetailContentType("Features")}
          >
            Features
          </Typography>
        </div>

        {selectedUserDetailContentType === "Basic Info" && (
          <UserInfo
            userDetail={userDetail}
            setUserDetail={setUserDetail}
            params={params}
          />
        )}

        {selectedUserDetailContentType === "Associated Groups" && (
          <UserAssociatedGroups
            userDetail={userDetail}
            setUserDetail={setUserDetail}
            load={load}
            setLoad={setLoad}
            params={params}
          />
        )}

        {selectedUserDetailContentType === "Assigned Roles" && (
          <UserAssignedRoles
            userDetail={userDetail}
            setUserDetail={setUserDetail}
            load={load}
            setLoad={setLoad}
            params={params}
          />
        )}

        {selectedUserDetailContentType === "Assigned Licenses" && (
          <UserAssignedLicenses
            userDetail={userDetail}
            setUserDetail={setUserDetail}
            load={load}
            params={params}
          />
        )}

        {selectedUserDetailContentType === "Features" && (
          <FeaturesContent
            userDetail={userDetail}
            setUserDetail={setUserDetail}
            load={load}
            setLoad={setLoad}
            params={params}
          />
        )}

        {(userFeatures?.includes(features?.ADD_USER_DETAILS) ||
          userFeatures?.includes(features?.MAP_LICENSE)) && (
            <div className={classes.userDetailFooter}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.userDetailFooterButton}
                onClick={updateUserInfo}
                disabled={
                  load ||
                  basicReducerState?.users
                    ?.filter((u) => u?.emailId !== userDetail?.emailId)
                    ?.find((u) => u?.displayName === userDetail?.displayName) ||
                  userDetail?.displayName?.length === 0
                }
              >
                Submit
              </Button>
            </div>
          )}
      </>
    </Paper>
  );
}

export default UserDetail;
